<template>
  <div>
    <div class="rel-grid-container">
      <div ></div>
      <div>
        <div id="log-box">
          <span class="form-box-name">{{$t('logs.chapter_text')}}</span>
          <b-form-select required v-model="orgId" :options="organizations" value-field="id" text-field="name" class="mb-3 input-vmargins" />
        </div>
        <b-table small bordered :items="logs" :fields="fields">
          <template v-slot:cell(time_occurred)="data">
               {{$formatDate(new Date(data.item.time_occurred), 'all')}}
          </template>
          <template v-slot:cell(action)="data">
               {{rev_actions[data.item.action]}}
             </template>
        </b-table>
      </div>
      <div class="submit-box-2">
        <b-button v-if="logNum>0" class="action-button previous-button" v-on:click="previousPage"></b-button>
        <b-button v-if="logs.length==50" class="action-button" v-on:click="nextPage" variant="clear-blue"><div class="next-button"></div><span>{{$t('global.button_next')}}</span></b-button>
      </div>
      <div class="div-space"></div>
    </div>
  </div>
</template>
<style scoped>
#log-box{
  display:grid; grid-template-columns:auto auto; width: 400px;margin:auto;
}
</style>
<script>
    export default {
    name: "Logs",
    watch: {
      'orgId': {
        handler: function () {
          this.logNum = 0;
          this.getLogs();
        },
        deep: true
      },
      '$i18n.locale': {
        handler: function () {
           this.fields[0].label = this.$t('logs.time_header');
            this.fields[1].label = this.$t('logs.name_header');
            this.fields[2].label =  this.$t('logs.action_header');
            this.rev_actions= [this.$t('logs.rev_action0'), this.$t('logs.rev_action1'), this.$t('logs.rev_action2'), this.$t('logs.rev_action3'), this.$t('logs.rev_action4')];
        },
        deep: true
      }
    },
      data() {
        return {
          fields: [
             { key: 'time_occurred',
              label: this.$t('logs.time_header'),
              sortable: true,
              thStyle: { "background-color": process.env.VUE_APP_TABLE_HEADER, "color": "#ffffff", "width": "200px", "text-align": "center" },
              tdClass: "actions-centre"
            },
             { key: 'name',
              label: this.$t('logs.name_header'),
              sortable: true,
              thStyle: { "background-color":  process.env.VUE_APP_TABLE_HEADER,"color": "#ffffff"}
            },
             { key: 'action',
              label: this.$t('logs.action_header'),
              sortable: true,
              thStyle: { "background-color":  process.env.VUE_APP_TABLE_HEADER,"color": "#ffffff" }
            }
          ],
          logs: [],
          logNum: 0,
          orgId: -1,
          organizations: [],
          rev_actions: [this.$t('logs.rev_action0'), this.$t('logs.rev_action1'), this.$t('logs.rev_action2'), this.$t('logs.rev_action3'), this.$t('logs.rev_action4')]
        }
      },
    methods: {
      nextPage() {
        this.logNum += 50;
        this.getLogs();
      },
      previousPage() {
        this.logNum = Math.max(0, this.logNum - 50);
        this.getLogs();
      },
      getLogs() {
        if (this.orgId >= 0) {
          this.$http.post("/api/organization/get/logs", { orgId: this.orgId, logNum: this.logNum }).then((response) => {
            if (response.data.success) {
              this.logs = response.data.logs;
            }
          }).catch(() => {
          });
        } else {
          this.logs = [];
        }
      },
      getOrganizations() {
        this.$http.post("/api/organization/get/children", {}).then((response) => {
          this.organizations = response.data.children;
          if (this.organizations.length > 0) {
            this.logNum = 0;
            this.orgId = 0;
            this.organizations.unshift({ id: 0, name: this.$t('logs.all_chapters') })
          } else {
            this.organizations.unshift({ id: -1, name: this.$t('logs.no_chapters') })
          }
          
        }).catch(() => {
        });
      }
    },
    destroyed() {
      this.removeClass(document.getElementById("menu-userlog"), "active-menu");
      this.removeClass(document.getElementById("menu-admin"), "active-menu");
    },
    mounted() {
      this.addClass(document.getElementById("menu-userlog"), "active-menu");
      this.addClass(document.getElementById("menu-admin"), "active-menu");
      this.getOrganizations();
    }
    }
</script>
