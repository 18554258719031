<template>
  <div id="main-box">
    <div id="top-box">
      <div id="home-logo" class="fit-content header-height">
        <div v-on:click="toggleFullScreen" id="fullscreen-overlay"><div class="fit-content">{{$t('global.fullscreen')}}</div></div>
      </div>
      <span id="organization-name">{{permissions.organization.name}}</span>
      <div  id="language-dropdown-wrapper">
        <b-dropdown v-if="languageOptions.length>0" id='language-dropdown' size="lg"  variant="link" toggle-class="text-decoration-none" no-caret>
         <template v-slot:button-content >
            <div class="language-flag">
                <img :src="getImgUrl('flags/'+selectedLanguage.flag+'.png')" v-bind:alt="selectedLanguage.flag+' flag'">
            </div>
          </template>
          <b-dropdown-item-button v-for="language in languageOptions" :key="language" v-on:click="selectLanguage(language)"><img :src="getImgUrl('flags/'+language.flag+'.png')" v-bind:alt="language.flag+' flag'"></b-dropdown-item-button>
        </b-dropdown>
      </div>
      <div id="portal-top-info">
        <span id="logged-in">{{$t('global.logged_in')}}:</span>
        <span id="portal-user-name">{{permissions.user.name}}</span>
      </div>
     <div id="portal-exit-button" class="clickable"  v-on:click="logout"><b-img :src="getImgUrl('nav-Close-Gray.png')" /></div>
    </div>

    <div :style="{display: 'grid', 'grid-template-columns': $mq == 'mobile'? '': '2fr 1fr'}">
      <div id="portal-meeting-info-wrapper">
        <div></div>
        <div class="form-box" id="portal-meeting-info">
          <div id="info-organization-name">{{permissions.organization.name}}</div>
          <div id="info-meeting-date">{{$formatDate(next_meeting_date)}}</div>
          <div id="info=meeting-start">{{meet_start}}</div>
        </div>
        <div></div>
      </div>
      <div id="admin-zone" :style="{'border-left': $mq == 'mobile'? ' none' : '1px #aaa solid'}">
        <div></div>
        <div id="admin-portal-image" class="portal-option">
          <img v-on:click="goToAdmin" id="admin-image" :src="adminImage.image" @mouseover="adminImage.image = adminImage.lit" @mouseout="adminImage.image = adminImage.unlit" />
          <div v-on:click="goToAdmin">{{$t('portal.meeting_admin')}}</div>
        </div>
        <div class="portal-test-box">
          <div v-on:click="playSound()" id="portal-sound-button"></div>
          <div v-on:click="playSound()" class="portal-test-file">
            <span>{{audioText}}</span>
            </div>
          
        </div>
        <div class="portal-test-box">
          <div v-on:click="testPowerPoint()" id="portal-file-button"></div>
          <span v-on:click="testPowerPoint()" class="portal-test-file">{{$t("portal.test_file")}}</span>
        </div>
        <div></div>
      </div>
      
    </div>
    <div id="login-portal" :style="{'display': $mq == 'mobile'? 'block':'grid','grid-template-columns': 'repeat('+((permissions.user.name=='admin1' || permissions.user.name=='Raffi Oshagan'? 5 : 4))+',1fr)'}">
      <div id="options-header">{{$t('portal.select_controller')}}:</div>
      <div :class="{'portal-option': true, 'top-space': $mq == 'mobile'}">
        <img v-on:click="goToController" :src="controllerImage.image" @mouseover="controllerImage.image = controllerImage.lit" @mouseout="controllerImage.image = controllerImage.unlit" />
        <div v-on:click="goToController">{{$t('portal.meeting_controller')}}</div>
      </div>
      <div :class="{'portal-option': true, 'top-space': $mq == 'mobile'}">
        <img v-on:click="goToPresenter" :src="presenterImage.image" @mouseover="presenterImage.image = presenterImage.lit" @mouseout="presenterImage.image = presenterImage.unlit" />
        <div v-on:click="goToPresenter">{{$t('portal.meeting_presenter')}}</div>
      </div>
      <div v-if="$mq != 'mobile'" :class="{'portal-option': true, 'top-space': $mq == 'mobile'}">
        <img v-on:click="goToAttendance" :src="attendanceImage.image" @mouseover="attendanceImage.image = attendanceImage.lit" @mouseout="attendanceImage.image = attendanceImage.unlit" />
        <div v-on:click="goToAttendance">{{$t('portal.visitor_host')}}</div>
      </div>
      <div v-if="$mq != 'mobile'" :class="{'portal-option': true, 'top-space': $mq == 'mobile'}">
        <img v-on:click="goToDemo" :src="demoImage.image" @mouseover="demoImage.image = demoImage.lit" @mouseout="demoImage.image = demoImage.unlit" />
        <div v-on:click="goToDemo">{{$t('portal.test_meeting')}}</div>
      </div>
      <div :class="{'portal-option': true, 'top-space': $mq == 'mobile'}" v-if="permissions.user.name=='admin1' || permissions.user.name=='Raffi Oshagan'">
        <img v-on:click="goToVisitor" :src="visitorImage.image" @mouseover="visitorImage.image = visitorImage.lit" @mouseout="visitorImage.image = visitorImage.unlit" />
        <div v-on:click="goToVisitor">{{$t('portal.visitor_tablet')}}</div>
      </div>
    </div>
    <b-modal centered id="slide-modal-2" v-model="showAnnouncementModal" :title="$t('portal.announcements_title')">
        <div id="announcement-table-wrapper">
          <b-table thead-class="hidden_header" small bordered :items="announcements" :fields="fields">
            <template v-slot:cell(day)="data">
                <div id="announcement-day">
                    <span>{{data.item.day.slice(0,3)}}</span>
                    <span>{{data.item.day.slice(3)}}</span>
                </div>

            </template>
            <template v-slot:cell(description)="data">
                <b>{{data.item.title}}</b>
                <pre class="pre-format">{{data.item.message}}</pre> 
            </template>
        </b-table>
        </div>
        <div slot="modal-footer" class="submit-box-2">
          <b-button class="action-button" v-on:click="showAnnouncementModal = false;" variant="clear-blue"><div class="save-button"></div><span>{{$t('global.button_ok')}}</span></b-button>
        </div>
      </b-modal>
  </div>
</template>
<style scoped>
#announcement-day{
  display: grid;width:50px;
}
#announcement-table-wrapper{
  max-height:500px; overflow-y: auto; overflow-x: hidden;
}
#admin-portal-image{
  height: 133px;margin-bottom: 50px;
}
#admin-image{
  height:109px;
}
#info-meeting-date{
  font-weight:bold; font-size:30px;color:#333;
}
#info-organization-name{
  font-weight:bold; font-size:30px;color:#641935;
}
#portal-user-name{
  color:#000;
}
#logged-in{
  color: #aaa;font-size: 10px;margin-top:6px;line-height:10px;
}
.language-flag{
  width:48px;
}
#language-dropdown{
  margin: auto;max-width: fit-content;display: block;
}
#language-dropdown-wrapper{
  width: 100%;max-width: 300px;
}
#organization-name{
  color:#641935;font-size:20px;line-height: 44px;padding-left: 10px;
}
.header-height{
  height: 41px;;
}

#portal-exit{
  background-color: rgba(0,0,0,0);border: none;margin-right:0px;margin-top:6px;margin-bottom:6px;margin-left: auto;
}

#top-box{
  display:grid;grid-template-columns: 126px max-content auto minmax(max-content,15%) max-content;border-bottom: 1px solid rgb(0, 174, 239);background-color: #f8f9fa!important;
}
#portal-meeting-info{
  width: fit-content;width:50%;margin:auto;background-color: #fafafa;grid-template-columns: 1fr; text-align: center;grid-template-rows: repeat(4,max-content);height: fit-content;
}
#portal-meeting-info-wrapper{
  overflow:auto;padding: 5%;display:grid; grid-template-rows: auto max-content auto;
}
.portal-test-box{
  display: grid; grid-template-columns: 60px auto;    width: fit-content; margin: auto;
}
.portal-test-file{
  cursor: pointer; color: #003c80;line-height: 50px;margin-left: 10px;
}
#options-header{
  text-align: center; grid-column: 1/6;line-height: 25px;font-size: 20px;
}
#main-box{
  display: grid; grid-template-rows: 50px auto 25%; height: 100%;
}

.top-space{
  margin-top:30px;
}
#portal-sound-button{
  width: 54px;
  height: 50px;
 background-image: url("../assets/Audio-icon.png");
  background-size: 100% 100%;
  cursor: pointer;
}
#portal-file-button{
  width: 54px;
  height: 50px;
 background-image: url("../assets/File-icon.png");
  background-size: 100% 100%;
  cursor: pointer;
}
#portal-controller-button {
  background-image: url("../assets/Device-VisitorRegistration.png");
  background-size: 100% 100%;
}

#login-portal {
  background-repeat: no-repeat;
  display: grid;
  grid-auto-flow: column;
  column-gap: 20px;
  grid-template-rows: 30px auto ;
  padding: 1%;
  row-gap: 10px;
  border-top: 2px solid rgb(0, 174, 239);
}

.portal-option {
  height: 100%;
  display: grid;
  grid-template-rows: auto min-content;
}

.portal-option>img {
  width: auto;
  margin: auto;
  cursor: pointer;
}

.portal-option>div {
  min-width: 80%;
  max-width: 100%;
  margin: auto;
  font-weight: bold;
  color: #003c80;
  font-size: 16px;
  cursor: pointer;
  text-align: center;
 line-height: 30px;
}
#admin-zone{
  font-size: 16px;margin:3%; overflow: auto; display: grid; grid-template-rows: auto max-content max-content auto;
}


.hidden_header {
  display: none;
}
.small-width{
  width:50px;
  text-align: center;
}

</style>
<script>

import router from "../router"
import cImage from '@/assets/Device-Controller.png'
import cImageLit from '@/assets/Device-Controller-Lit.png'
import adImage from '@/assets/Device-Admin.png'
import adImageLit from '@/assets/Device-Admin-Lit.png'
import pImage from '@/assets/Device-Presenter.png'
import pImageLit from '@/assets/Device-Presenter-Lit.png'
import aImage from '@/assets/Device-Attendance.png'
import aImageLit from '@/assets/Device-Attendance-Lit.png'
import vImage from '@/assets/Device-VisitorRegistration.png'
import vImageLit from '@/assets/Device-VisitorRegistration-Lit.png'
import dImage from '@/assets/Device-Test.png'
import dImageLit from '@/assets/Device-Test-Lit.png'
import sound1 from "../assets/sounds/ding-sound.mp3"
export default {
  name: "Portal",
  watch: {
    '$i18n.locale': { 
      handler: function () {
          if (!this.audio_object.paused && !this.audio_object.paused) {
            this.audioText= this.$t('portal.playing_chime');
          } else {
            this.audioText= this.$t('portal.test_audio');
          }
          this.languageOptions = [];
          for(var i=0; i<this.totalLanguages.length; i++){
            if(this.totalLanguages[i].abbrev!=this.$i18n.locale){
              this.languageOptions.push(this.totalLanguages[i]);
            }else{
              this.selectedLanguage = this.totalLanguages[i];
            }
          }
          document.title = this.$t('global.document_title') +" "+ this.permissions.organization.name;
      },
      deep: true
    }
    
  },
  data() {
    return {
     selectedLanguage: {abbrev: 'en', flag: 'gb'},
      totalLanguages: [],
      languageOptions: [],
      selected: 'en',
      speakers: [],
      next_meeting_date: new Date(),
      next_meeting: "",
      orgid: 0,
      meet_start: "",
      sections: [],
      files: [],
      controllerImage: {image: cImage, lit: cImageLit, unlit: cImage},
      adminImage: {image: adImage, lit: adImageLit, unlit: adImage},
      presenterImage: {image: pImage, lit: pImageLit, unlit: pImage},
      attendanceImage: {image: aImage, lit: aImageLit, unlit: aImage},
      visitorImage: {image: vImage, lit: vImageLit, unlit: vImage},
      demoImage: {image: dImage, lit: dImageLit, unlit: dImage},
      audioText: this.$t('portal.test_audio'),
      audio_object: null,
      announcements: [],
      showAnnouncementModal: false,
      fields: [
            { key: 'day',
              label: 'Date',
              thStyle: { "background-color": process.env.VUE_APP_TABLE_HEADER, "color": "#ffffff" },
              tdClass: 'small-width'
            },
            { key: 'description',
              label: 'Title',
              thStyle: { "background-color": process.env.VUE_APP_TABLE_HEADER, "color": "#ffffff" }
            },
            
          ],
    }
  },
  methods: {
    selectLanguage(language){
        this.selectedLanguage = language;
        this.loadLanguageAsync(language.abbrev);
      },
     getLanguages(){
        this.$http.post("/api/organization/languages", {}).then((response) => {
          if (response.data.success === true) {
            this.totalLanguages = response.data.languages;
            this.languageOptions = [];
            for(var i=0; i<this.totalLanguages.length; i++){
              if(this.totalLanguages[i].abbrev!=this.$i18n.locale){
                this.languageOptions.push(this.totalLanguages[i]);
              }else{
                this.selectedLanguage = this.totalLanguages[i];
              }
            }
          }
        }).catch((errors) => {
          console.log(errors)
        });
      },
    goToController() {
      router.push('/controller/view');
    },
    goToPresenter() {
      router.push('/meeting');
    },
    goToAttendance() {
      router.push('controller/attendance');
    },
    goToVisitor() {
      router.push('/visitor');
    },
    goToAdmin() {
      router.push('/admin/dashboard');
    },
    goToDemo() {
      router.push('/demo');
    },
    editEntry(id) {
      if (this.permissions.access) {
        router.push("/admin/schedule/" + id);
      }
    },
   
    playSound() {
      if (this.audio_object) {
        if (!this.audio_object.paused && !this.audio_object.paused) {
          clearTimeout(this.audio_timeout);
          this.audio_object.pause();
          this.audioText= this.$t('portal.test_audio');
        } else {
          this.audio_object.play();
          this.audioText= this.$t('portal.playing_chime');
        }

      }
    },
    testPowerPoint(){
      window.open("https://garoca-my.sharepoint.com/:p:/g/personal/meetinghub_garo_ca/EdVJ0WVk22ZIsKgK01iLvP4Bl_P1I4awWWh_SoFjAHgT8A?action=embedview");
    },
    getDates() {
      this.$http.post("/api/organization/dates", {}).then((response) => {
        if (response.data.success === true) {
          this.next_meeting = response.data.next_formatted;
          this.orgid = response.data.id;
          this.getSpeakers(this.next_meeting);
          this.next_meeting_date = this.next_meeting.length > 0 ? new Date("" + this.next_meeting.split("-").join("/") + " 00:01") : "";
        }
      }).catch(() => { });
      this.$http.post("/api/organization/get/starttime", {}).then((response) => {
        if (response.data.success === true) {
          this.meet_start = response.data.meet_start;
        }
      }).catch(() => { });
    },
    getSpeakers(d) {
      this.$http.post("/api/organization/speakers/day", { date: d, org: this.orgid, getImages: true }).then((response) => {
        if (response.data.success) {
          for (var i = 0; i < response.data.speakers.length; i++) {
            response.data.speakers[i].hasBio = response.data.speakers[i].bio && response.data.speakers[i].bio.length > 10;
            response.data.speakers[i].hasUrl = response.data.speakers[i].type == 0 || (response.data.speakers[i].type > 0 && response.data.speakers[i].url && response.data.speakers[i].url.length > 0);
          }
          this.speakers = response.data.speakers;
        } else {
          this.speakers = [];
        }

      }).catch((errors) => { console.log(errors); });
    },
    getMeetingNotes() {
      this.$http.post("/api/portal/meeting/notes", {}).then((response) => {
        if (response.data.success) {
          var info = response.data.info;
          this.sections = info.sections;
          var section_map = {};
          var slide_map = {};
          for (var i = 0; i < info.sections.length; i++) {
            section_map[info.sections[i].id] = info.sections[i];
            section_map[info.sections[i].id].files = [];
          }
          for (i = 0; i < info.slides.length; i++) {
            slide_map[info.slides[i].id] = info.slides[i];
            slide_map[info.slides[i].id].files = [];
          }
          for (i = 0; i < info.slideFiles.length; i++) {
            if (slide_map[info.slideFiles[i].slide_id]) {
              slide_map[info.slideFiles[i].slide_id].files.push(info.slideFiles[i]);
            }
          }
          for (i = 0; i < info.sectionFiles.length; i++) {
            if (section_map[info.sectionFiles[i].section_id]) {
              section_map[info.sectionFiles[i].section_id].files.push(info.sectionFiles[i]);
            }
          }
          for (var g in section_map) {
            if (section_map[g].files.length > 0) {
              this.files.push({ name: section_map[g].name, files: section_map[g].files });
            }
          }
          for (g in slide_map) {
            if (slide_map[g].files.length > 0) {
              this.files.push({ name: slide_map[g].name, files: slide_map[g].files });
            }
          }
        } else {
          this.files = [];
          this.sections = [];
        }

      }).catch((errors) => { console.log(errors); });
    },
    logout() {
      this.signout();
    },
    getAnnouncements() {
        this.$http.post("/api/organization/announcements/parent", {}).then((response) => {
          if (response.data.success) {
            this.announcements = response.data.announcements;
            for (var i = 0; i < this.announcements.length; i++) {
              this.announcements[i].day = new Date("" + this.announcements[i].day + " 00:01").toLocaleDateString("en-US", { hour12: true, month: 'short', day: 'numeric' });
            }
            if(this.announcements.length>0){
              this.showAnnouncementModal = true;
            }
          }
        }).catch(() => {
        });
      },
    auth() {
      var self = this;
      this.authenticate(function(){
            document.title = self.$t('global.document_title') +" "+ self.permissions.organization.name;
        });
    },
    
  },
  mounted() {
    if(!this.permissions.shownAnnouncements){
      this.permissions.shownAnnouncements = true;
      this.getAnnouncements();
    }
    this.audio_object = document.createElement('audio');
    this.audio_object.src = sound1;
    var self = this;
    this.audio_object.addEventListener("ended", function () {
      self.audio_timeout = setTimeout(function () {
        self.audio_object.play();
      }, 500);
    });
    this.getMeetingNotes();
    this.auth();
    this.getDates();
    this.getLanguages();
  }
}
</script>
