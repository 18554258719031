<template>
  <div class="full-height">
    <div id="attendance-body" v-if="!showExpired && doneLoading">
      <Tip :tipNumber="29"></Tip>
      <div >
        <div class="fit-width" v-if="linked" id="attendance-header">
          <img src="../assets/logo-MeetingHub-2.png" />
          <div id="header-seperator"></div>
          <div id="header-details">
            <span class="burgandy-text">{{organization_name}}</span>
            <span class="black-text">{{$t('attendance.meeting_attendance')}}</span>
          </div>
        </div>
        <div v-if="this.$route.params.hash" id="meeting-date">
          <div id="meeting-date-div" class="fit-width" >
            <span class="form-box-name" id="meeting-date-text">{{$t('attendance.meeting_date')}}</span>
            <b>{{current_date.length>0 ? $formatDate(new Date(current_date.split("-").join("/") + " 00:01")) : ''}}</b>
          </div>
        </div>
        <div v-if="!this.$route.params.hash" id="attendance-buttons">
          <b-button v-show="!this.$route.params.hash && this.previous_date" id="previous-button" v-on:click="previousAttendance" class="action-button" variant="clear-blue">
            <div class="back-button"></div><span>{{$t('global.button_back')}}</span>
          </b-button>
          <div id="attendance-date"><b>{{current_date.length>0 ? $formatDate(new Date(current_date.split("-").join("/") + " 00:01")) : ''}}</b></div>
          <b-button id="next-button" v-show="!this.$route.params.hash && this.next_date" v-on:click="nextAttendance" class="action-button" variant="clear-blue">
            <div class="next-button"></div><span>{{$t('global.button_next')}}</span>
          </b-button> 
        </div>
        <br />
       <div class="attendance-title-header">
              <div class="attendance-title-text">{{$t('controller.visitor_table')}}</div>
          </div>
        <b-table v-show="visitorsubs.length>0" class="table-hover-new attendance-table" :class="{'clickable': permissions.admin && isCorporateConnections}" v-on:row-clicked="editVisitor" small bordered :items="visitorsubs" :fields="fields">
          <template v-slot:cell(name)="data">
              <b>{{data.item.name}}</b>{{ data.item.company && data.item.company.length>0? " > "+ data.item.company: ""}}
          </template>
          <template v-slot:cell(type)="data">
            <b>{{ data.item.type==1? $t("controller.substitute_text") : (data.item.type==3? (isCorporateConnections? $t("controller.guest_text") : $t("controller.visitor_text")) :(data.item.type==5? $t("controller.regional_text") : $t("controller.observer_text")))}}</b>
          </template>
           <template v-slot:cell(status)="data">
            <b>{{ data.item.present==0?$t("controller.registered_visitor"): $t("controller.present_visitor")}}</b>
          </template>
          <template v-slot:cell(visits)="data">
              {{data.item.visits}}
          </template>
          <template v-slot:cell(linked_member)="data">
              <span class="grey-text">{{data.item.member_id>0 && data.item.type != 5? ((data.item.type==1? $t('controller.subbing_for') : $t('controller.invited_by'))) : ""}}</span> {{data.item.member_id>0? data.item.linked_member : ""}}
          </template>
        </b-table>
        <div id="member-title-header" class="attendance-title-header">
              <div class="attendance-title-text">{{$t('controller.member_table')}}</div>
         </div>
        <b-table v-show="attendance_members.length>0" class="table-hover-new clickable attendance-table" striped small bordered :items="attendance_members" :fields="fields2">
          <template v-slot:cell(name)="data">
              <b>{{data.item.name}}</b>
          </template>
          <template v-slot:cell(status)="data">
            {{data.item.type_text +" @ "+ (data.item.arrival != null? $formatDate(new Date(data.item.arrival), 'time_text') : "")}}
          </template>
        </b-table>
      </div>
    </div>
    <div v-if="showExpired && doneLoading" id="expired-link-box">
      <div></div>
      <div id="expired-link-wrapper">
          <b-alert variant="warning" :show="showExpired" id="expired-link-alert">
              {{$t('attendance.link_expired')}}
          </b-alert>
      </div>
      <div></div>
    </div>
    <b-button v-show="!this.$route.params.hash" id="export-button"  v-on:click="showExportModal=true;" class="action-button" variant="clear-blue">
        <div class="import-button"></div><span>{{$t('global.button_export')}}</span>
      </b-button>
    
      <b-modal static="true" centered v-model="showVisitorAttendance" :no-close-on-backdrop="true" :no-close-on-esc="true" :hide-header-close="true">
          <form v-on:submit.prevent="saveVisitor">
              <div id="attendance-type-box" class="fit-width">
                <b-form-radio v-model="currentVisitor.type" value="3">
                    {{isCorporateConnections? $t("controller.guest_text") : $t('controller.visitor_text')}}
                </b-form-radio>
                <b-form-radio v-if="!isCorporateConnections"  v-model="currentVisitor.type" value="4">
                    {{$t('controller.observer_text')}}
                </b-form-radio>
                <b-form-radio v-if="!isCorporateConnections" v-model="currentVisitor.type" value="1">
                    {{$t('controller.sub_text')}}
                </b-form-radio>
                <b-form-radio v-model="currentVisitor.type" value="5">
                    {{$t('controller.regional_text')}}
                </b-form-radio>
              </div>
              <div v-if="currentVisitor && currentVisitor.type != 5 && currentVisitor.type != 0" class="attendance-edit-box" >
                <span class="form-box-name">{{(currentVisitor.type==0? "" : (currentVisitor.type==1? $t('controller.substitute_text') + " " : (currentVisitor.type==3? $t('controller.visitor_text')  + " ": $t('controller.observer_text') + " ")))+$t('controller.name_text')}}</span><b-form-input :disabled="currentVisitor.type==0" v-model="currentVisitor.name" type="text" pattern="[^<>]*" :title="$t('global.pattern_title')" class="form-box-input attendance-edit-input" maxlength="100"  required></b-form-input>
                <span class="form-box-name">{{$t('controller.company_text')}}</span><b-form-input :disabled="currentVisitor.type==0" v-model="currentVisitor.company" type="text" pattern="[^<>]*" :title="$t('global.pattern_title')" class="form-box-input attendance-edit-input" maxlength="150"></b-form-input>
                <span class="form-box-name">{{$t('controller.profession_text')}}</span><b-form-input :disabled="currentVisitor.type==0" v-model="currentVisitor.profession" type="text" class="form-box-input attendance-edit-input" maxlength="150"></b-form-input>
                <span class="form-box-name">{{(currentVisitor.type==0? $t('controller.member_text') : (currentVisitor.type==1? $t('controller.subbing_for') + " " :  $t('controller.invited_by') ))}}</span><b-form-select v-if="members2.filter(v=>v.id==currentVisitor.member_id || currentVisitor.member_id==0).length>0" :disabled="currentVisitor.type==0" v-model="currentVisitor.member_id" :options="members2" value-field="id" text-field="name" class="form-box-input attendance-edit-input" /><b-form-input v-if="members2.filter(v=>v.id==currentVisitor.member_id || currentVisitor.member_id==0).length==0" :disabled="true" v-model="currentVisitor.linked_member" type="text" pattern="[^<>]*" :title="$t('global.pattern_title')" class="form-box-input attendance-edit-input"></b-form-input>
                <span class="form-box-name">{{$t('controller.phone_text')}}</span><b-form-input :disabled="currentVisitor.type==0" v-model="currentVisitor.phone" type="tel" class="form-box-input attendance-edit-input"></b-form-input>
                <span class="form-box-name" v-if="isCorporateConnections">{{$t('controller.mobile_phone')}}</span><b-form-input v-if="isCorporateConnections" :disabled="currentVisitor.type==0" v-model="currentVisitor.mobile_phone" type="tel" class="form-box-input attendance-edit-input"></b-form-input>
                <span class="form-box-name">{{$t('controller.email_text')}}</span><b-form-input :disabled="currentVisitor.type==0" v-model="currentVisitor.email" type="email" class="form-box-input attendance-edit-input" maxlength="150"></b-form-input>
                <!--<span id="preferred-contact" class="form-box-name" v-if="isCorporateConnections">{{$t('member.preferred_contact')}}</span>
                <b-form-select v-if="isCorporateConnections" class="form-box-input attendance-edit-input"  v-model="currentVisitor.preferred_contact" :options="contactMethods"/>-->
                <span class="form-box-name">{{$t('controller.website_text')}}</span><b-form-input :disabled="currentVisitor.type==0" v-model="currentVisitor.website" type="text" class="form-box-input attendance-edit-input" maxlength="150"></b-form-input>
                <span class="form-box-name" v-if="isCorporateConnections">{{$t('controller.linkedin_url')}}</span><b-form-input v-if="isCorporateConnections" :disabled="currentVisitor.type==0" v-model="currentVisitor.linkedin_url" type="text" class="form-box-input attendance-edit-input"></b-form-input>
                <span class="form-box-name">{{$t('controller.attendance_status')}}</span>
                <div class="attendance-present-box">
                  <b-form-radio v-model="currentVisitor.present" :value="1" class="auto-margin">
                    {{$t('controller.present_text')}}
                  </b-form-radio>
                  <b-form-radio v-model="currentVisitor.present" :value="0" class="auto-margin">
                    {{$t('controller.registered_visitor')}}
                  </b-form-radio>
                </div>
              </div>
              <div v-if="currentVisitor && currentVisitor.type == 5" id="regional-contact-box" class="attendance-edit-box" >
                <span class="form-box-name">{{$t('controller.regional_contact')}}</span><b-form-select :disabled="currentVisitor.type==0" v-model="currentVisitor.member_id" :options="contacts" value-field="id" text-field="name" class="form-box-input attendance-edit-input" />
                <span class="form-box-name">{{$t('controller.attendance_status')}}</span>
                <div class="attendance-present-box">
                    <b-form-radio v-model="currentVisitor.present" value="1" class="auto-margin">
                    {{$t('controller.present_text')}}
                  </b-form-radio>
                  <b-form-radio v-model="currentVisitor.present" value="0" class="auto-margin">
                    {{$t('controller.registered_visitor')}}
                  </b-form-radio>
                  </div>
              </div>
          
              <div v-if="isCorporateConnections && currentVisitor.type != 5" class="attendance-edit-box">
                <span class="form-box-name">{{$t('controller.photo_header')}}</span>
                <ImageSelector ref="imageSelector" :imageObject="currentVisitor" :ratio="isCorporateConnections? 1 : 0.6157" :maxWidth="665" :maxHeight="1080" :circular="true"/>
              </div>
          </form>
          <div v-if="showVisitorError">
              <b-alert variant="danger"
                        dismissible
                        :show="showVisitorError"
                        @dismissed="showVisitorError=false">
                  {{visitorErrorText}}
              </b-alert>
          </div>
          <b-alert variant="danger" dismissible :show="showMemberCreateAlert"  @dismissed="showAlert=false">
            {{memberCreateAlertText}}
          </b-alert>
          <b-alert variant="success" dismissible :show="showMemberCreateSuccess"  @dismissed="showAlert=false">
            {{memberCreateAlertText}}
          </b-alert>
          <b-button id="member-create-button" v-if="(isCorporateConnections && currentVisitor && currentVisitor.id>0 && currentVisitor.type != 5 && currentVisitor.email)" class="action-button" v-on:click="createMember(currentVisitor)" variant="clear-blue"><div class="save-button"></div><span>{{$t('controller.create_member')}}</span></b-button>
          <div slot="modal-footer" class="submit-box-2">
            <b-button class="action-button" v-on:click="showVisitorAttendance = false;" variant="clear-blue"><div class="cancel-button"></div><span>{{$t('global.button_cancel')}}</span></b-button>
              <b-button v-if="currentVisitor && currentVisitor.id>0" class="action-button" v-on:click="deleteVisitor" variant="clear-blue"><div class="delete-button"></div><span>{{$t('global.button_delete')}}</span></b-button>
              <b-button :disabled="currentVisitor.type==0" class="action-button" v-on:click="saveVisitor" variant="clear-blue"><div class="save-button"></div><span>{{$t('global.button_save')}}</span></b-button>
          </div>
      </b-modal>
      <b-modal centered :title="$t('attendance.export_title')" v-model="showExportModal" v-on:hide="showSuccess=false; showAlert=false;">
        <span class="form-box-title">{{$t('attendance.export_settings')}}</span>
        <div id="export-form-box" class="form-box">
            <DatePicker class="attendance-date-picker" v-model="exportdata.day1" />
            <DatePicker v-if="exportdata.day1!=null" class="attendance-date-picker" v-model="exportdata.day2" />
            <b-button class="action-button auto-margin" v-if="exportdata.day1!=null && exportdata.day2!=null && exportdata.day1<=exportdata.day2 && !showSuccess" v-on:click="getAttendanceExport" variant="clear-blue"><div class="import-button"></div><span>{{$t('global.button_export')}}</span></b-button>
            <br/>
            <b-alert variant="success" dismissible :show="showSuccess"  @dismissed="showSuccess=false">
                {{$t('attendance.export_success')}}
              </b-alert>
              <b-alert variant="warning" dismissible :show="showAlert"  @dismissed="showAlert=false">
                {{$t('attendance.export_failure')}}
              </b-alert>
            <div v-if="exportFile.filename.length>0 && showSuccess" class="fit-width auto-margin"> 
              <b-button class="action-button" v-on:click="downloadAttendanceExport" variant="clear-blue" ><div class="import-button"></div><span>{{$t('attendance.download_export')}}</span></b-button>
              <a id="export-file" :download="exportFile.filename" :href="exportFile.content" hidden></a>
            </div>
        </div>
        <div slot="modal-footer" class="submit-box-2">
          <b-button class="action-button" v-on:click="showExportModal=false" variant="clear-blue"><div class="save-button"></div><span>{{$t('global.button_ok')}}</span></b-button>
          
        </div>
      </b-modal>
  </div>
</template>
<style scoped>
#member-title-header{
  margin-top:40px;
}
#member-create-button{
  margin:auto;margin-top: 30px;
}
#export-form-box{
  grid-template-columns: auto;
}
.attendance-date-picker{
  margin: 5px 0px;
}
#regional-contact-box{
  margin-top:20px;
}
#preferred-contact{
  grid-column:1;
}
.attendance-present-box{
  display:grid; grid-template-columns: auto auto;
}
.attendance-edit-input{
  max-width: 300px;
}
.attendance-edit-box{
  display:grid;grid-template-columns: 40% 60%;
}
#attendance-type-box{
  margin:auto; display:grid; grid-template-columns: max-content max-content max-content max-content; color: #999;font-weight: bold;column-gap: 20px;
}
#export-button{
  margin: auto;margin-top: 30px;
}
.attendance-table{
  border:none;
}
#expired-link-alert{
  width: 100%;
}
#expired-link-wrapper{
  max-width: 600px;margin:auto;width: 100%;text-align:center;
}
#expired-link-box{
  display:grid;grid-template-rows: auto max-content auto;height:100%;
}
.attendance-title-text{
  font-size:20px;
}
.attendance-title-header{
  display:grid; grid-template-columns: auto max-content max-content;width:100%;background: #ccc; padding: 5px;height: 50px;line-height: 40px;    grid-gap: 50px;
}
#meeting-date-text{
  margin: 0px 5px;
}
#previous-button{
  margin:auto;margin-left:0px;
}
#next-button{
  margin:auto;margin-right:0px;
}
#attendance-date{
  grid-column:2/3; font-size: 24px; text-align:center;
}
#attendance-body{
  width: 800px; margin: auto; margin-top:40px;
}
#header-details{
  font-size: 24px;display: grid;
}
#header-seperator{
  border-left: 2px solid black; height: 100%;
}
#visitor-title{
  text-align:center;font-weight: bold;
}
#nonlinked-date{
  color: #47afc6;
    font-weight: bold;
    text-align: center;
    grid-column: 2;
}

#prev-button{
  width:93px;margin: 0px;
}
#next-button{
  width:93px; margin-right: 0px;margin-left: auto;
}
#attendance-buttons{
  display:grid;grid-template-columns: 30% 40% 30%;width: 800px;margin: 20px auto;
}
#meeting-date-div{
  display: grid;grid-template-columns: max-content max-content;margin: auto;
}
#meeting-date{
  background-color: #eeeeee;padding: 10px; margin: 20px 0px;
  
}
  #attendance-header{
    display: grid;grid-template-columns: max-content max-content max-content;grid-gap: 20px;margin: auto;
  }
</style>
<script>
    import DatePicker from './common/DatePicker.vue';
    export default {
    name: "Attendance",
    components: {
      DatePicker
    },
    watch: {
      '$i18n.locale': {
        handler: function () {
         this.fields[0].label = this.$t('controller.name_company_header');
          this.fields[1].label = this.$t('controller.status_header');
          this.fields[2].label = this.$t('controller.visit_number');
          this.fields2[0].label = this.$t('controller.name_header');
          this.fields2[1].label = this.$t('controller.status_header');


          this.members[0].name =  this.$t('controller.none_option');
          this.members2[0].name =  this.$t('controller.none_option');
        }
      }
    },
      data() {
        return {
          showMemberCreateAlert: false,
      memberCreateAlertText: "",
      showMemberCreateSuccess: false,
          contactMethods: [{value:0, text: this.$t("member.sms_mobile")},{value:1, text: this.$t("member.email_link")},{value:2, text: this.$t("member.sms_email")}],
          selectedVisitor: -1,
          currentVisitor: { member_id: 0, type: 0, photo_verification: 0, photo: null, photo_small: null, linkedin_url: "", preferred_contact: 0},
          showVisitorAttendance: false,
          showAlert: false,
          showSuccess: false,
          showExportModal: false,
          exportdata: {day1: null, day2: null},
          exportFile: {filename: '', content: ''},
          doneLoading: false,
          showExpired: false,
          alertText: "",
          organization_name: "",
          attendance_members: [],
          fields: [
            { key: 'name',
              label: this.$t('controller.name_company_header'),
              thStyle: { "background-color":  "#ccc","color": "#666", "border":"none","font-weight": "300"}
            },
            { key: 'type',
              label: this.$t('controller.type_header'),
              thStyle: { "background-color":  "#ccc","color": "#666", "text-align":"center", "border":"none" ,"font-weight": "300"},
              tdClass: "actions-centre"
            },
            { key: 'status',
              label: this.$t('controller.status_header'),
              thStyle: { "background-color":  "#ccc","color": "#666", "text-align":"center", "border":"none" ,"font-weight": "300"},
              tdClass: "actions-centre"
            },
              { key: 'visits',
                label: this.$t('controller.visit_number'),
                thStyle: { "background-color":  "#ccc","color": "#666", "text-align":"center", "border":"none","font-weight": "300"},
                tdClass: "actions-centre"
              },
              { key: 'linked_member',
                label: "",
                thStyle: { "background-color": "#ccc","color": "#666", "border":"none","font-weight": "300" }
              }
          ],
          fields2: [
            { key: 'name',
              label: this.$t('controller.name_header'),
              thStyle: { "background-color":  "#ccc","color": "#666", "border":"none","font-weight": "300" }
            },
            { key: 'status',
              label: this.$t('controller.status_header'),
              thStyle: { "background-color":  "#ccc","color": "#666", "text-align":"center", "border":"none","font-weight": "300" }
            }
          ],
          linked: false,
          org: 0,
          current_date: '',
          dates: [],
          date_index: 0,
          visitorsubs: [],
          next_meeting: '',
          next_date: null,
          previous_date: null,
          members: [],
          members2: []
        }
      },
    methods: {
      createMember(visitor){
        this.showMemberCreateAlert = false;
        this.showMemberCreateSuccess = false;
       
        this.$http.post("/api/visitor/get/photo", {id: visitor.id}).then((response) => {
          if (response.data.success) {
            visitor.photo = response.data.photo;
          }
          var member = {  id: 0,access: 0, roles: [], city: "", presence: 0, job_title: "", linkedin_url: visitor.linkedin_url, sponsoring_member_id: 0, preferred_contact: visitor.preferred_contact, introduction: "",pref_lang: visitor.pref_lang,
            run_permission: 0, has_pass: 0, category: visitor.profession, company: visitor.company, email: visitor.email, photo: visitor.photo, photo_small: visitor.photo_small, retype:"", website: visitor.website,
            disabled: 0, password: "", name: visitor.name, sub_role: "", role_description: "", phone: visitor.phone, mobile_phone: visitor.mobile_phone, logo: null, contact_type: "", contact_types: [],ask: "",tagline:"", member_since: ""
          };
          this.$http.post("/api/member/update", member).then((response) => {
              if (response.data.error) {
                if (response.data.error.errno == 1) {
                  var role_conflicts = response.data.error.role_conflicts;
                  this.showMemberCreateAlert = true;
                  this.memberCreateAlertText = this.$t('member.role_error') + ": ";
                  for (var i = 0; i < role_conflicts.length; i++) {
                    this.memberCreateAlertText += role_conflicts[i].name + ", ";
                  }
                  this.memberCreateAlertText = this.memberCreateAlertText.slice(0, this.memberCreateAlertText.length - 2);
                } else if(response.data.error.errno == 1062){
                  this.showMemberCreateAlert = true;
                  this.memberCreateAlertText = this.$t('member.email_error') +": " +response.data.error.conflict.org+" > "+response.data.error.conflict.name;
                }
              }
              else if (response.data.success === true) {
                this.showMemberCreateSuccess = true;
                this.memberCreateAlertText = this.$t('controller.member_created')
              }
            }).catch((errors) => {
              this.$bvToast.show('serverError');
            });
        }).catch((e) => { console.log(e)});
      },
       editVisitor(visitor) {
         if(this.permissions.admin && this.isCorporateConnections){
           this.selectedVisitor = this.visitorsubs.indexOf(visitor);
          this.currentVisitor = JSON.parse(JSON.stringify(visitor));
          this.showVisitorAttendance = true;
         }
      },
      saveVisitor() {
        this.showVisitorError = false;
        if(this.currentVisitor.type == 5){
          if(this.currentVisitor.member_id==0){
            this.visitorErrorText = this.$t('controller.visitor_error3');
            this.showVisitorError = true;
            return;
          }
          this.showVisitorAttendance = false;
          if (this.currentVisitor.member_id > 0) {
            for (var i = 0; i < this.contacts.length; i++) {
              if (this.contacts[i].id == this.currentVisitor.member_id) {
                this.currentVisitor.name = this.contacts[i].only_name;
                this.currentVisitor.company = this.contacts[i].company;
                break;
              }
            }
          }
        }else{
          if(this.currentVisitor.name.length==0){
            this.visitorErrorText = this.$t('controller.visitor_error1');
            this.showVisitorError = true;
            return;
          }
          this.showVisitorAttendance = false;
          if (this.currentVisitor.member_id > 0) {
            for (var j = 0; j < this.members2.length; j++) {
              if (this.members2[j].id == this.currentVisitor.member_id) {
                this.currentVisitor.linked_member = this.members2[j].name;
                break;
              }
            }
          } else {
            this.currentVisitor.member_id = 0;
            this.currentVisitor.linked_member= "";
          }
        }
        
        if (!(this.currentVisitor.id && this.currentVisitor.id > 0)) {
          this.visitorsubs.push(this.currentVisitor);
          this.visitorsubs.sort((a, b) => (a.name> b.name) ? 1 : -1);
          this.selectedVisitor = this.visitorsubs.indexOf(this.currentVisitor);
        }
        this.updateAttendance(this.currentVisitor);
      },
      updateAttendance(item) {
        item.subscribed = 0;
        this.$http.post("/api/organization/attendance/update", item).then((response) => {
          if (response.data.success === true) {
            item.id = response.data.inserted_id;
            item.arrival = response.data.arrival;
            if(!(item.type == 2 || item.type == -1)){
                item.visits = response.data.visits;
                if(this.selectedVisitor>=0){
                  this.$set( this.visitorsubs, this.selectedVisitor, item);
                }
            }
          }
        }).catch((errors) => {console.log(errors);});
      },
      deleteVisitor() {
        var index = this.selectedVisitor;
        this.showVisitorAttendance = false;
        if (index > -1) {
          this.visitorsubs.splice(index, 1);
          this.deleteAttendance(this.currentVisitor.id);
        }
      },
      deleteAttendance(id){
          this.$http.post("/api/organization/attendance/delete", {id:id}).then(() => {
        
        }).catch(() => { });
      },
      getAttendance(date) {
        this.current_date = date;
        this.visitorsubs = [];
        this.$http.post("/api/organization/attendance/day", { date: this.current_date, displayAll: true }).then((response) => {
          if(response.data.success){
            this.processAttendance(response);
          }
          
        }).catch((errors) => {console.log(errors);});
      },
      getAttendanceByHash(hash){
        this.showExpired = false;
        this.visitorsubs = [];
        this.$http.post("/api/organization/attendance/day/byhash", { hash: hash, displayAll: true }).then((response) => {
          if(response.data.success){
            this.current_date = response.data.date;
            this.processAttendance(response);
          }else{
            this.showExpired = true;
          }
          this.doneLoading = true;
        }).catch((errors) => {console.log(errors);});
      },
      processAttendance(response){
        var tempmembers = [];
          this.next_date = response.data.next_date;
          this.previous_date = response.data.previous_date;
          if (response.data.members) {
            tempmembers = response.data.members;
            for (var i = 0; i < tempmembers.length; i++) {
              var t = tempmembers[i].type;
              tempmembers[i].type_text = (t == 2 ? this.$t('controller.late_text') : this.$t('controller.absent_text'));
            }
          }
          this.attendance_members = tempmembers;
          this.attendance_members.sort((a, b) => (a.name> b.name) ? 1 : -1);
          if (response.data.visitorsubs) {
            this.visitorsubs = response.data.visitorsubs;
            this.visitorsubs.sort((a, b) => (a.name> b.name) ? 1 : -1);
          }
      },
      downloadAttendanceExport(){
        document.getElementById("export-file").click();
        this.showSuccess = false;
        this.showAlert = false;
      },
      getAttendanceExport(){
        this.$http.post("/api/organization/attendance/export", this.exportdata).then((response) => {
          if (response.data.success === true) {
            this.exportFile = response.data.file;
            this.showSuccess = true;
          }else{
            this.showAlert = true;
          }
        }).catch(() => { });
      },
      getCurrentDate() {
        this.$http.post("/api/organization/get/nextmeeting", {}).then((response) => {
          if (response.data.success === true) {
            this.doneLoading = true;
            this.next_meeting = response.data.day;
            this.exportdata.day1 = new Date(response.data.day);
            this.exportdata.day2 = new Date(response.data.day);
            this.getAttendance(response.data.day);
          }else{
            this.getLastDate();
          }
        }).catch(() => { this.doneLoading = true;});
      },
      getLastDate(){
        this.$http.post("/api/organization/get/lastmeeting", {}).then((response) => {
          if (response.data.success === true) {
            this.doneLoading = true;
            this.next_meeting = response.data.day;
            this.exportdata.day1 = response.data.day;
            this.exportdata.day2 = response.data.day;
            this.getAttendance(response.data.day);
          }
        }).catch(() => { this.doneLoading = true;});
      },

      getOrganizationName() {
        this.$http.post("/api/organization/get/name", { id: this.org }).then((response) => {
          if (response.data.success) {
            this.organization_name = response.data.name;
          }
        }).catch(() => { });
      },
      nextAttendance() {
          /*var d = new Date("" + this.current_date.split("-").join("/") + " 00:01");
          d.setDate(d.getDate() + 7);
          this.current_date = d.toISOString().substr(0,10);*/
          this.current_date = this.next_date;
           this.exportdata.day1 = this.current_date;
          this.exportdata.day2 = this.current_date;
          this.getAttendance(this.current_date);
        
      },
      previousAttendance() {
       /* var d = new Date("" + this.current_date.split("-").join("/") + " 00:01");
        d.setDate(d.getDate() - 7);
        this.current_date = d.toISOString().substr(0,10);*/
        this.current_date = this.previous_date;
        this.exportdata.day1 = this.current_date;
        this.exportdata.day2 = this.current_date;
        this.getAttendance(this.current_date);
      },
      getMembers() {
        this.$http.post("/api/attendance/members", {}).then((response) => {
            this.members2 = response.data.members.slice();
            this.members2.unshift({ id: 0, name: this.$t('controller.none_option') });
            this.members = response.data.members.slice();
            this.members.unshift({ id: 0, name: this.$t('controller.none_option') });
        }).catch(() => { });
      },
    },
    destroyed() {
     this.removeClass(document.getElementById("menu-attendance"), "active-menu");
      this.removeClass(document.getElementById("menu-meeting"), "active-menu");
    },
    mounted() {
      this.addClass(document.getElementById("menu-attendance"), "active-menu");
      this.addClass(document.getElementById("menu-meeting"), "active-menu");
      if(this.$route.params.hash){
        this.getAttendanceByHash(this.$route.params.hash);
      }else{
         this.getCurrentDate();
      } 
      this.getOrganizationName();
      this.getMembers();
    }
  }
</script>
