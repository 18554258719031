<template>
    <!-- An element that contains the timer group -->
    <!-- This element is created to act as child for the parent grid element and place the timer group in the center (for alignment of timer) -->
    <div class="timer-container">
        <!-- Custom element to wrap the timer icon and the tooltip (to group them) -->
        <div class="timer-wrapper">
            <!-- Custom element as a tooltip -->
            <div v-if="isTimerBeeping" class="timer-tooltip" :data-position="tooltipPosition" :data-size="size">
                {{ tooltipText }}
            </div>
            <!-- Timer button -->
            <b-img :class="{ beeping: isTimerBeeping }" class="clickable image-colour-shift timer-button"
                :data-size="size" :src="getImgUrl('icon-Timer-Blue.png')" v-on:click="handleTimerClick" />
        </div>
    </div>
</template>

<style scoped lang="scss">
// styles for timer group container
.timer-container {
    display: grid;
    place-items: center;
    min-height: 120px;

    &:has(.timer-button[data-size="small"]) {
        min-height: 0px;
    }
}

// styles for timer and tooltip wrapper
.timer-wrapper {
    position: relative;
}

// styles for timer tooltip
.timer-tooltip {
    position: absolute;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #343A40;
    color: #FFF;
    border-radius: 6px;
    white-space: nowrap;
    opacity: .64;
    animation: blinking 1.6s infinite ease-in-out;
    z-index: 100;

    &[data-size="small"] {
        top: -36px;
        bottom: -36px;
        padding: 4px 6px;
        font-size: 10px;
    }

    &[data-size="large"] {
        top: -26px;
        bottom: -26px;
        padding: 6px 12px;
        font-size: 12px;
    }

    // styles for timer tooltip's arrow mark
    &:before {
        content: '';
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        border-left: 6px solid transparent;
        border-right: 6px solid transparent;
        width: 0;
        height: 0;
    }

    &[data-position="top"] {
        bottom: unset;

        &::before {
            bottom: -6px;
            border-top: 6px solid #343A40;
        }
    }

    &[data-position="bottom"] {
        top: unset;

        &::before {
            top: -6px;
            border-bottom: 6px solid #343A40;
        }
    }
}

// styles for timer button
.timer-button {
    margin: auto;
    padding: 5px;

    &[data-size="small"] {
        height: 46px;
    }

    &[data-size="large"] {
        height: 60px;
    }

    // styles for timer button when it is beeping
    &.beeping {
        animation: beeping 1.6s infinite ease-in-out;
        transform: rotate(0deg);
    }
}

// blinking animation for timer tooltip
@keyframes blinking {

    0%,
    100% {
        opacity: 0
    }

    50% {
        opacity: .64
    }
}

// beeping animation for timer
@keyframes beeping {

    0%,
    70%,
    100% {
        transform: rotate(0deg);
    }

    76%,
    88% {
        transform: rotate(30deg);
    }

    82%,
    94% {
        transform: rotate(-30deg);
    }
}
</style>

<script>

export default {
    name: "MeetingTimer",
    props: {
        isTimerBeeping: Boolean, // get the state of timer (is beeping)
        tooltipText: String, // get the text to be shown in the tooltip
        tooltipPosition: { // get the position of the tooltip at which it has to be positioned (top, bottom)
            type: String,
            default: 'top',
            validator(value) {
                return ['top', 'bottom'].includes(value);
            }
        },
        size: { // get the size of timer and tooltip (large, small)
            type: String,
            default: 'large',
            validator(value) {
                return ['small', 'large'].includes(value);
            }
        }
    },
    methods: {
        // hanlde on click the timer button
        handleTimerClick() {
            // fire custom event to the parent
            this.$emit('on-click-timer');
        }
    }
}
</script>