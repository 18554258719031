/**
 * App Variable constants
 * 
 * This file defines constants related to application.
 */

// The REGIONAL_STATUS_TYPES object defines constants for different types of regional subscription variables

export const DEFAULT_LOCALE_ID = 1;

export const REGIONAL_STATUS_TYPES = Object.freeze({
    NEW: {
        COLOR: '#000000',
        TRANSLATION_KEY: 'subscription.imported_text',
        VALUE: 0,
    },
    PILOT: {
        COLOR: '#15d406',
        TRANSLATION_KEY: 'subscription.pilot_text',
        VALUE: 1,
    },
    TRIAL: {
        COLOR: '#15d406',
        TRANSLATION_KEY: 'subscription.trial_text',
        VALUE: 2,
    },
    ACTIVE: {
        COLOR: '#15d406',
        TRANSLATION_KEY: 'subscription.active_text',
        VALUE: 3,
    },
    INACTIVE: {
        COLOR: '#ed0942',
        TRANSLATION_KEY: 'subscription.inactive_text',
        VALUE: 4,
    }
});