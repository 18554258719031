<template>
    <div>
      <div class="rel-grid-container">
        <div ></div>
        <div >
          <div id="access-control-box" class="grid-split-2column fit-width auto-margin">
            <b-button v-on:click="selectAll" class="action-button auto-margin" variant="clear-blue"><div class="cancel-button"></div><span>{{$t('global.select_all')}}</span></b-button>
            <b-button v-on:click="selectNone" class="action-button auto-margin" variant="clear-blue"><div class="cancel-button"></div><span>{{$t('global.select_none')}}</span></b-button>
          </div>
            <table v-if="chapters.length>0" class="table b-table table-bordered table-sm">
          <thead>
            <tr >
              <th v-for="field in fields" class="tableHeader">{{ field.label }}</th>
            </tr>
          </thead>
            <tbody>
              <tr v-for="chapter in chapters">
                <td class=" center-text"><b-form-checkbox  v-model="chapter.select">
                          </b-form-checkbox></td>
                <td>{{ chapter.name }}</td>
                <td v-for="role in roles"><div v-for="member in chapter[role]" :style="{color: member.has_pass? 'black' : 'red', fontStyle: member.last_login? '' : 'italic'}">{{ member.name }}  {{ member.last_login? "("+$t('access.last_login')+": "+ $formatDate(member.last_login, 'all')+")" : "" }}</div></td>
              </tr>
            </tbody>
            </table>

            <b-button v-if="chapters.length>0" :disabled="chapters.filter(v => v.select==1)==0" class="action-button auto-margin clickable" v-on:click="sendAccessEmails" variant="grey-section">{{$t('access.send_emails')}}</b-button>
        </div>
        <div class="div-space"></div>
      </div>
    </div>
  </template>
  <style scoped lang="scss">
  .action-button{
    background-color: white !important;
  }
  #access-control-box{
    margin-bottom: 10px !important;
  }
  
  </style>
  <script>
      export default {
      name: "Access",
        data() {
          return {
            roles: [],
            fields: [
              { key: 'select',
                label: this.$t('access.select_text'),
                thStyle: { "background-color":  process.env.VUE_APP_TABLE_HEADER,"color": "#ffffff" }
              },
              {
                key: 'name',
                label: this.$t('access.chapter_text'),
                thStyle: { "background-color":  process.env.VUE_APP_TABLE_HEADER,"color": "#ffffff"}
              },
            ],
            chapters: []
          }
        },
      methods: {
        selectAll(){
        for(let chapter of this.chapters){
          chapter.select = true;
        }
      },
      selectNone(){
        for(let chapter of this.chapters){
          chapter.select = false;
        }
      },
        sendAccessEmails(){
          var chapter_ids = this.chapters.filter(v => v.select==1).map(v => v.id);
          this.$http.post("/api/organization/chapter/access/sendemails", {chapters: chapter_ids }).then((response) => {
                if (response.data.success) {
                  for(let chapter of this.chapters){
                    chapter.select = false;
                  }
                  this.$bvToast.toast(this.$t('access.emails_sent'), {
                    variant: 'success',
                    solid: true
                    });
                }
            }).catch(() => {
        });
        },
        getChapterAccess() {
            this.$http.post("/api/organization/chapter/access", { }).then((response) => {
                if (response.data.success) {
                  if(response.data.chapters.length>0){
                    
                      for(let field in response.data.chapters[0]){
                        if(field != 'id' && field != "name"){
                          this.fields.push({ key: field,
                            label: field,
                            thStyle: { "background-color":  process.env.VUE_APP_TABLE_HEADER,"color": "#ffffff" }
                          })
                          this.roles.push(field);
                        }
                      }
                    }
                    for(let chapter of response.data.chapters){
                        chapter.select = false;
                    }
                    this.chapters = response.data.chapters;
                    
                    
                }
            }).catch(() => {
        });
      },
      },
      destroyed() {
        this.removeClass(document.getElementById("menu-access"), "active-menu");
        this.removeClass(document.getElementById("menu-admin"), "active-menu");
      },
      mounted() {
        this.addClass(document.getElementById("menu-access"), "active-menu");
        this.addClass(document.getElementById("menu-admin"), "active-menu");
        this.getChapterAccess();
      }
    }
  </script>
  