<template>
  <div>
    <!-- The unsaved changes alert modal, which is displayed when there are unsaved changes in the application. -->
    <!-- This modal gives the user the option to either discard changes or save them before continuing. -->
    <b-modal @hidden="onHiddenUnsavedChangesAlertModal" centered v-model="unsavedChangesInfo.open" hide-header>
      <div slot="modal-header" class="d-flex justify-content-end pb-2">
          <b-button class="action-button" v-on:click="unsavedChangesInfo.open = false;" variant="clear-blue"><div class="cancel-button"></div></b-button>
      </div>
      <p>{{ $t('performance.unsaved_changes') }}</p>
      <div slot="modal-footer" class="submit-box-2">
        <!-- global.button_discard: 'CANCEL' -->
        <b-button class="action-button" v-on:click="onDiscardContinue" variant="clear-blue"><div class="cancel-button"></div><span>{{$t('global.button_cancel')}}</span></b-button>
        <!-- global.button_save: 'SAVE' -->
        <b-button class="action-button" v-on:click="onSaveContinue" variant="clear-blue"><div class="save-button"></div><span>{{$t('global.button_save')}}</span></b-button>
      </div>
    </b-modal>
    <Header :useruid="this.$route.params.useruid" :title="$t('performance.manage_performance')" :showDate="true"></Header>
    <div id="performance-page-wrapper" class="rel-grid-container">
      <div ></div>
      <div>
        <Tip :tipNumber="15"></Tip>
        <template v-if="this.$route.params.useruid == undefined  && tracking_periods!=0">
          <div id="info-wrapper"><span id="organization-name">{{permissions.organization.name}}</span> > <b>{{current_date.length>0 ? $formatDate(new Date(current_date.split("-").join("/") + " 00:01")) : ''}}</b></div>
          <div id="attendance-buttons" class="d-flex justify-content-between align-items-center flex-wrap mx-auto my-3">
            <b-button v-show="!this.$route.params.org" :disabled="meetings_start_date >= current_date" v-on:click="onPreNavigate(previousPerformance)" class="action-button mx-2" variant="clear-blue">
              <!-- "global.button_back": "BACK" -->
              <div class="back-button"></div><span>{{$t('global.button_back')}}</span>
            </b-button>
            <b-button v-show="!this.$route.params.org" :disabled="current_date >= meetings_last_date" v-on:click="onPreNavigate(nextPerformance)" class="action-button mx-2" variant="clear-blue">
              <!-- "global.button_next": "NEXT" -->
              <div class="next-button"></div><span>{{$t('global.button_next')}}</span>
            </b-button> 
          </div>
        </template>
        <div v-if="tracking_periods==0" id="choose-periods">{{$t('performance.choose_periods')}}</div>
        <form v-on:submit.prevent="updateReports" v-if="tracking_periods!=0">
          <!-- TYFCB Currency dropdown field -->
          <div class="currency-select-container mx-auto my-3 px-4">
            <label class="mr-2" for="tyfcb-currency-dropdown">Currency:</label>
            <b-form-select @change="unsavedChangesInfo.hasChanges = true;" id="tyfcb-currency-dropdown" class="currency-select" v-model="currency" :options="currencyOptions"></b-form-select>
          </div>
            <div id="reporting-periods" class="skinned-text">{{$t('performance.reporting_periods')}}</div>
            <div id="reports-grid" class="fit-width">
              <div id="measurements-text" class="skinned-text">{{$t('performance.measurements_text')}}</div>
              <div class="report-column">
                <div id="empty-header" class="report-header"></div>
                <div class="report-header">{{split_referrals==1? $t('performance.internal_referrals') : $t('performance.referrals_text') }}</div>
                <div v-if="split_referrals==1" class="report-header">{{$t('performance.external_referrals')}}</div>
                <div class="report-header">{{$t('performance.visitors_text')}}</div>
                <div class="report-header">{{$t('performance.one_to_ones')}}</div>
                <div class="report-header">{{$t('performance.tyfcb_text')}}</div>
                <div class="report-header">{{$t('performance.ceus_text')}}</div>
              </div>
              <div v-for="r in reports.filter(v => (v.period_id & tracking_periods)>0)"  :key="r.period_id" class="report-column">
                <div class="report-header">{{$t('general.tracking_option_'+r.period_id)}}</div>
                <div><b-form-input @change="unsavedChangesInfo.hasChanges = true;" :formatter="inputFormatter" v-model="r.referrals" min="0" type="number" class="medium-input" :state="parseFloat(r.referrals) >= 0 ? null : false" /></div>
                <div v-if="split_referrals==1"><b-form-input  @change="unsavedChangesInfo.hasChanges = true;" :formatter="inputFormatter" v-model="r.external_referrals" min="0" type="number" class="medium-input" :state="parseFloat(r.external_referrals) >= 0 ? null : false" /></div>
                <div><b-form-input @change="unsavedChangesInfo.hasChanges = true;" :formatter="inputFormatter" v-model="r.visitors" min="0" type="number" class="medium-input" :state="parseFloat(r.visitors) >= 0 ? null : false" /></div>
                <div><b-form-input @change="unsavedChangesInfo.hasChanges = true;" :formatter="inputFormatter" v-model="r.one_to_ones" min="0" type="number" class="medium-input" :state="parseFloat(r.one_to_ones) >= 0 ? null : false" /></div>
                <div><b-form-input @change="unsavedChangesInfo.hasChanges = true;" :formatter="inputFormatter" v-model="r.tyfcb" min="0" type="number" class="medium-input" :state="parseFloat(r.tyfcb) >= 0 ? null : false" /></div>
                <div><b-form-input @change="unsavedChangesInfo.hasChanges = true;" :formatter="inputFormatter" v-model="r.ceus" min="0" type="number" class="medium-input" :state="parseFloat(r.ceus) >= 0 ? null : false" /></div>
              </div>
            </div>
          
          <br />
          <div v-if="showAlert" class="performance-alert-wrapper">
          <b-alert variant="warning" dismissible :show="showAlert" @dismissed="showAlert=false">
            {{$t('performance.error_type1')}}
          </b-alert>
        </div>
          <div v-if="showSuccess" class="performance-alert-wrapper">
            <b-alert variant="success" dismissible :show="showSuccess" @dismissed="showSuccess=false">
              {{$t('performance.thanks_message_1')}}<br />
              {{$t('performance.thanks_message_2')}}
            </b-alert>
          </div>
          <div class="submit-box-2">
            <b-button class="action-button" v-on:click="unsavedChangesInfo.hasChanges = true; clearValues()" variant="clear-blue"><div class="delete-button"></div><span>{{$t('global.button_clear')}}</span></b-button>
            <b-button class="action-button" v-if="!this.$route.params.org" v-on:click="back" variant="clear-blue"><div class="cancel-button"></div><span>{{$t('global.button_cancel')}}</span></b-button>
            <b-button class="action-button" type="submit" variant="clear-blue"><div class="save-button"></div><span>{{$t('global.button_save')}}</span></b-button>
          </div>
        </form>
      </div>
      <div class="div-space"></div>
    </div>
    <Footer :useruid="this.$route.params.useruid"></Footer>
  </div>
</template>
<style scoped lang="scss">
/* no border for disabled buttons on focus or hover */
.action-button.disabled {
  &:hover {
    border-color: transparent;
  }
  div {
    filter: grayscale(100%);
  }
}
/* styles for tyfcb currency dropdown container */
.currency-select-container {
  max-width: 1000px;
}
/* styles for tyfcb currency dropdown field */
.currency-select {
  width: 240px;
}
#performance-page-wrapper{
  max-width:100%;
}
#choose-periods{
  text-align:center; font-size:18px;
}
#info-wrapper{
  font-size: 24px; text-align:center;
}
#organization-name{
  color:red
}
#reporting-periods{
  color:#3cd2f0;font-size:15px;text-align: center;margin-bottom: 10px;
}
#reports-grid{
  display: grid;grid-template-columns: 20px repeat(8,max-content); ; grid-gap: 30px; margin:auto;
}
#measurements-text{
  color:#3cd2f0;font-size:15px;writing-mode: vertical-rl;text-orientation: upright;text-align: center;
}
#empty-header{
  background-color: #fff; border: none;
}
.report-column{
  display:grid; grid-template-columns: max-content;grid-gap: 10px;
}
.performance-alert-wrapper{
  max-width: 600px;margin: auto;
}
#attendance-buttons{
  max-width: 1000px;
}
 .report-header{
   height: 38px;
   width:200px;
   line-height: 38px;
   font-size: 18px;
   text-align: center;
   background-color: #ccc;
   border: 1px solid #aaa;
 }
</style>
<script>
  import router from "../router"
    export default {
    name: "Reports",
      data() {
        return {
          unsavedChangesInfo: { // The `unsavedChangesInfo` object holds information related to unsaved changes in the application.
            continueFunc: null, // the continuation function to be executed
            hasChanges: false, // whether there are unsaved changes
            open: false, // whether the unsaved changes alert modal should be shown.
          },
          currency: 'USD', // tyfcb currency selected
          currencyOptions: ['USD'], // list of currency options to select for currency
          showAlert: false,
          showAddReport : false,
          saveVariant: "blue",
          title: "",
          type: 0,
          reports: [],
          meetings_start_date: null,
          meetings_last_date: null,
          current_date: '',
          dayIndex: 0,
          dates: [],
          tracking_periods: 0,
          next_meeting : '',
          showSuccess: false,
          useruid: "",
          split_referrals: false
        }
      },
    methods: {
      inputFormatter(value) {
        let val = value;
        val = val.replaceAll('-', '').replaceAll('e', '');
        val = parseFloat(val);
        val = isNaN(val) ? '' : `${val}`;
        return val;
      },
      // get list of currencies
      getCurrencyList() {
        this.$http.get("/api/currency-list", {}).then((response) => {
          if (response.data.success) {
            const currencyList = response.data.currencyList.sort();
            // check for the currency if present in the list, if not present check for USD else null
            if (!currencyList.includes('USD')) {
              currencyList.unshift('USD');
            }
            if (!currencyList.includes(this.currency)) {
              this.currency = 'USD';
            }
            this.currencyOptions = currencyList;
          } else {
            this.currencyOptions = ['USD'];
            this.currency = 'USD';
          }
        }).catch(error => {
          console.error(error);
          this.currencyOptions = ['USD'];
          this.currency = 'USD';
        });
      },
      /**
       * This method is called when the unsaved changes alert modal is closed.
       * It resets the `continueFunc` to null to prevent any unintended actions after closing the modal.
       */
      onHiddenUnsavedChangesAlertModal() {
        this.unsavedChangesInfo.continueFunc = null;
      },
      /**
       * This method is invoked when the user decides to discard their changes and continue.
       * It closes the unsaved changes alert modal and executes the `continueFunc` if defined.
       * 
       * @returns {void}
       */
      onDiscardContinue() {
        // Close the unsaved changes modal
        this.unsavedChangesInfo.open = false;
        // Check if continueFunc is defined and execute it
        if (typeof this.unsavedChangesInfo.continueFunc === 'function')
          this.unsavedChangesInfo.continueFunc();
      },
      /**
       * This method is called when the user want to save their changes and continue.
       * It attempts to save the report, and if successful, it closes the modal and executes the `continueFunc` (then next function provided).
       * If an error occurs, it closes the modal and does not execute `continueFunc` (then next function provided).
       * 
       * @returns {Promise<void>} A promise that resolves when the save operation is complete or rejects if there is an error.
       */
      onSaveContinue() {
        // Attempt to save the reports and handle the response
        this.onSaveReports().then(response => {
          // Close the unsaved changes modal after a successful save
          this.unsavedChangesInfo.open = false;
          // Check if continueFunc is defined and execute it after saving the report
          if (typeof this.unsavedChangesInfo.continueFunc === 'function')
            this.unsavedChangesInfo.continueFunc();
        }).catch(error => {
          // If there's an error during the save process, close the modal
          this.unsavedChangesInfo.open = false;
        });
      },
      /**
       * This method is invoked before navigating to other date performance details.
       * It checks if there are unsaved changes, and if so, it shows the modal and defers the navigation action until the user confirms.
       * 
       * @param {Function} nextFunc - The function to be executed after the user confirms the unsaved changes.
       * @param {...*} nextFuncProps - Additional arguments that will be passed to the `nextFunc`.
       * @returns {void}
       */
      onPreNavigate (nextFunc, ...nextFuncProps) {
        // Check if there are unsaved changes that need to be confirmed by the user
        if (this.unsavedChangesInfo.hasChanges) {
          // Store the navigation function to be executed after the user confirms the action
          this.unsavedChangesInfo.continueFunc = () => {
            // Ensure the next function is valid before executing it
            if (typeof nextFunc === 'function')
              nextFunc(...nextFuncProps);
          };
          // Show the unsaved changes alert modal to the user
          this.unsavedChangesInfo.open = true;
        } else {
          // If there are no unsaved changes, proceed with the next function immediately
          if (typeof nextFunc === 'function')
            nextFunc(...nextFuncProps);
        }
      },
      previousPerformance(){
        this.dayIndex = Math.min(this.dates.length - 1, this.dayIndex + 1);
        this.current_date = this.dates[this.dayIndex];
        this.getReports();

       /* if(this.dayIndex<this.dates.length-1){
          this.dayIndex+=1;
          this.current_date = this.dates[this.dayIndex];
          this.getReports();
        }*/
      },
      nextPerformance(){
        this.dayIndex = Math.max(0, this.dayIndex - 1);
        this.current_date = this.dates[this.dayIndex];
        this.getReports();
        

        /*if(this.dayIndex>0){
          this.dayIndex-=1;
          this.current_date = this.dates[this.dayIndex];
          this.getReports();
        }*/
      },
        clearReports(){
          var ids = [1,2,4,8,16,32,64];
          this.reports = [];
          this.currency = 'USD';
          for(var i =0; i<ids.length; i++){
            this.reports.push({id: null,period_id:ids[i], referrals: "0", visitors: "0", one_to_ones: "0", tyfcb: "0", currency: "USD", ceus: "0", external_referrals: "0", isNew: true})
          }
        },
        clearValues(){
          for(var i=0; i<this.reports.length;i++){
            this.reports[i].referrals = "0";
            this.reports[i].external_referrals = "0";
            this.reports[i].visitors = "0";
            this.reports[i].one_to_ones = "0";
            this.reports[i].tyfcb = "0";
            // after clearing the values set the currency as USD if present in currency list else null
            this.currency = 'USD';
            this.reports[i].currency = this.currency;
            this.reports[i].ceus = "0";
          }
        },
      getReports() {
        this.$http.post("/api/organization/reports/get"+(this.useruid==''? "" : "/byuseruid"), {useruid: this.useruid, day: this.current_date}).then((response) => {
          if (response.data.success) {
            this.clearReports();
            this.unsavedChangesInfo.hasChanges = false;
            for(var i=0; i<response.data.reports.length; i++){
              for(var j=0; j<this.reports.length; j++){
                if(this.reports[j].period_id == response.data.reports[i].period_id){
                  response.data.reports[i].referrals +="";
                  response.data.reports[i].visitors +="";
                  response.data.reports[i].one_to_ones +="";
                  response.data.reports[i].tyfcb +="";
                  // set the currency to USD if not present
                  response.data.reports[i].currency = response.data.reports[i].currency || "USD";
                  this.currency = response.data.reports[i].currency;
                  response.data.reports[i].ceus +="";
                  response.data.reports[i].external_referrals +="";
                  this.$set(this.reports,j,response.data.reports[i]);
                  break;
                }
              }
              
            }
          }
        }).catch(() => {
        });
      },
      check(){
        if (!this.currency)
          return false;
        const measurements = ['referrals','visitors','one_to_ones','tyfcb','ceus'];
        for(var i=0; i<this.reports.length; i++){
          if (!!this.reports[i] && (this.reports[i].period_id & this.tracking_periods) > 0) {
            for (let measurement of measurements) {
              this.reports[i][measurement] = this.inputFormatter(this.reports[i][measurement]);
              if (this.reports[i][measurement].length === 0)
                return false;
            }
          }
        }
        return true;
      },
      /**
       * Returns a promise that attempts to save the reports by sending an HTTP POST request to the server.
       * This method checks for any validation errors before making the request.
       * If the validation fails, it rejects the promise. 
       * If the request is successful, it resolves the promise, otherwise it rejects it.
       * 
       * @returns {Promise}
       */
      onSaveReports() {
        return new Promise((resolve, reject) => {
          this.showAlert = false;
          if(!this.check()){
            this.showAlert = true;
            return reject(null);
          }
          this.$http.post("/api/organization/reports/update"+(this.useruid==''? "" : "/byuseruid"), {
            useruid: this.useruid,
            reports: this.reports.map(report => ({ ...report, currency: this.currency })), // update the currency for the reports
            day: this.current_date
          }).then(resolve).catch(reject);
        });
      },
      updateReports() {
        this.onSaveReports().then(response => {
          if (response.data.success === true) {
            this.saveVariant = 'success';
             if(this.useruid==''){
              this.back();
            }else{
              this.getReports();
               this.showSuccess = true;
            }
           
          } else {
            this.saveVariant = 'danger';
          }
        }).catch((errors) => {
          if (!errors) return;
          console.log(errors);
          this.$bvToast.show('serverError');
        });
      },
      getReportDates(){
        this.$http.post("/api/organization/reports/info"+(this.useruid==''? "" : "/byuseruid"), {useruid: this.useruid}).then((response) => {
          if (response.data.success) {
              this.dates = response.data.dates;
              this.meetings_start_date = this.dates[this.dates.length - 1];
              this.meetings_last_date = this.dates[0];
              this.tracking_periods = response.data.tracking_periods;
              this.next_meeting = response.data.next_meeting;
              this.split_referrals = response.data.split_referrals;
              this.dayIndex = 0;
              this.current_date = this.dates[this.dayIndex];
              if(this.$route.params.day){
                if (!this.dates.includes(this.$route.params.day)) {
                  this.dates.push(this.$route.params.day);
                  this.dates.sort((b, a) => a > b ? 1 : a < b ? -1 : 0);
                }
                this.dayIndex = this.dates.findIndex(this.$route.params.day);
                  this.current_date = this.$route.params.day;
                }
              this.getReports();
          }
          }).catch(() => {
        });
          
      },
      back() {
        router.push("/admin/dashboard");
      },
    },
    destroyed() {
      this.removeClass(document.getElementById("menu-performance"), "active-menu");
      this.removeClass(document.getElementById("menu-chapter"), "active-menu");
    },
    mounted() {
      if(this.$route.params.useruid){
        this.useruid = this.$route.params.useruid;
      }
      this.addClass(document.getElementById("menu-performance"), "active-menu");
      this.addClass(document.getElementById("menu-chapter"), "active-menu");
      this.getCurrencyList();
      this.getReportDates();
    }
  }
</script>
