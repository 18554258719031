<template>
  <!-- Modal component from BootstrapVue, used as a base for the custom modal -->
  <b-modal centered :title="title" v-model="internalValue" @hide="onHide" @hidden="onHidden">

    <!-- Default slot to allow content to be passed to the modal -->
    <slot></slot>
    
    <!-- Custom footer content for the modal -->
    <div slot="modal-footer" class="custom-modal-footer">
      
      <!-- Alert container for showing a warning message -->
      <div ref="ref_alert_container">
        <b-alert v-model="showAlert" variant="warning" dismissible @dismissed="showAlert = false">
          <!-- Warning message prompting the user to click "OK" or "Cancel" -->
          {{ $t("slide.modal_click_out_warning") }}
        </b-alert>
      </div>
      
      <!-- Action buttons container -->
      <div class="submit-box-2">
        
        <!-- Delete button, only visible if actions.delete is true -->
        <b-button v-if="actions.delete" class="action-button" v-on:click="onDelete" variant="clear-blue">
          <slot name="delete-button-content">
            <!-- Default content for delete button if no slot content is provided -->
            <div class="delete-button"></div><span>{{ $t('global.button_delete') }}</span>
          </slot>
        </b-button>

        <!-- Cancel button, only visible if actions.cancel is true -->
        <b-button v-if="actions.cancel" class="action-button" v-on:click="onCancel" variant="clear-blue">
          <slot name="cancel-button-content">
            <!-- Default content for cancel button if no slot content is provided -->
            <div class="cancel-button"></div><span>{{ $t('global.button_cancel') }}</span>
          </slot>
        </b-button>

        <!-- Save button, only visible if actions.save is true -->
        <b-button v-if="actions.save" class="action-button" v-on:click="onSave" variant="clear-blue">
          <slot name="save-button-content">
            <!-- Default content for save button if no slot content is provided -->
            <div class="ok-button"></div><span>{{ $t('global.button_ok') }}</span>
          </slot>
        </b-button>
      </div>
    </div>
  </b-modal>
</template>

<style scoped>
/* Styling for the custom modal footer */
.custom-modal-footer {
  display: flex;
  flex-direction: column;
  margin-top: 0.75rem;
  padding-top: 0.75rem;
  border-top: 1px solid #dee2e6;
  border-bottom-right-radius: calc(0.3rem - 1px);
  border-bottom-left-radius: calc(0.3rem - 1px);
}
</style>

<script>

export default {
  // Props passed into the modal component
  props: {
    actions: {
      type: Object,
      default() {
        return {
          cancel: true, // Show cancel button by default
          delete: false, // Hide delete button by default
          save: true,    // Show save button by default
        };
      }
    },
    title: String,
    // Modal visibility controlled by v-model, bound to `value`
    value: Boolean
  },

  // Watchers to keep track of value changes and ensure proper two-way data binding
  watch: {
    // Watch the value prop passed from parent to sync internal value
    value(newValue) {
      this.internalValue = newValue; // Update internal state when parent changes value
    },
    // Watch internalValue to emit update to parent when the modal visibility changes
    internalValue(newValue) {
      this.$emit('update:value', newValue); // Emit the updated value to parent component
    }
  },

  // Data properties for internal modal state
  data() {
    return {
      internalValue: this.value,  // Store the modal visibility state
      showAlert: false,           // Alert visibility state, used for showing warning
    };
  },

  // Methods to handle various modal actions (cancel, delete, save, hide)
  methods: {
    // Handle the cancel action, emits 'onCancel' to the parent
    onCancel(event) {
      this.$emit('onCancel', event); // Emit cancel event
    },
    
    // Handle the delete action, emits 'onDelete' to the parent
    onDelete(event) {
      this.$emit('onDelete', event); // Emit delete event
    },

    // Handle when the modal is about to be hidden
    onHide(event) {
      // If the modal is hidden due to clicking outside or pressing the ESC key, show the alert
      if (['backdrop', 'esc'].includes(event.trigger)) {
        event.preventDefault(); // Prevent hiding if triggered by backdrop or ESC key
        this.showAlert = true;   // Show the warning alert
        // Scroll the alert container into view after a short delay
        setTimeout(() => {
          this.$refs['ref_alert_container'].scrollIntoView({ behavior: 'smooth', block: 'center' });
        }, 100);
      } else {
        // If modal is closed by clicking buttons (save/cancel), hide the alert
        this.showAlert = false;
        this.$emit('onHide', event); // Emit the onHide event to parent component
      }
    },

    // Handle when the modal is fully hidden (after the closing animation)
    onHidden(event) {
      this.$emit('onHidden', event); // Emit the onHidden event to parent component
    },

    // Handle the save action, emits 'onSave' to the parent
    onSave(event) {
      this.$emit('onSave', event); // Emit save event
    }
  }
};
</script>
