<template>
    <div >
        <div class="rel-grid-container">
            <div class="fit-width auto-margin grid-fit-2column">
                <span class="form-box-name">{{$t('goal.from_date')}}</span><DatePicker class="input-vmargins" :state="from_date== null? false : null" v-model="from_date" />
                <span class="form-box-name">{{$t('goal.to_date')}}</span><DatePicker class="input-vmargins" :state="to_date== null || from_date> to_date? false : null" v-model="to_date" />
            </div>
            <b-alert variant="success" dismissible :show="showSuccess"  @dismissed="showSuccess=false"  class="fit-width auto-margin">
            {{$t('attendance.export_success')}}
            </b-alert>
            <b-alert variant="warning" dismissible :show="showAlert"  @dismissed="showAlert=false"  class="fit-width auto-margin">
            {{$t('attendance.export_failure')}}
            </b-alert>
            <div class="fit-width grid-fit-2column auto-margin">
                <b-button class="action-button auto-margin"  v-if="!showSuccess" :disabled="from_date==null || to_date == null || from_date> to_date" v-on:click="generateEngagementReport" variant="clear-blue"><div class="import-button"></div><span>{{$t('global.button_export')}}</span></b-button>
                <div v-if="exportFile.filename.length>0 && showSuccess"  class="fit-width auto-margin"> 
                    <b-button class="action-button" v-on:click="downloadEngagementReport" variant="clear-blue" ><div class="import-button"></div><span>{{$t('attendance.download_export')}}</span></b-button>
                    <a id="export-file" :download="exportFile.filename" :href="exportFile.content" hidden></a>
                </div>
            </div>
        </div>
    </div>
</template>
<style scoped>

</style>
<script>
import DatePicker from './common/DatePicker.vue';
export default {
name: "EngagementReport",
components: {
    DatePicker
},
data() {
        return {
        showAlert: false,
        showSuccess: false,
        exportFile: {filename: '', content: ''},
        from_date: null,
        to_date: null
        }
},
methods: {
    generateEngagementReport(){
        this.$http.post("/api/organization/egagementreport/export", {from_date: this.from_date, to_date: this.to_date}).then((response) => {
        if (response.data.success === true) {
            this.exportFile = response.data.file;
            this.showSuccess = true;
        }else{
            this.showAlert = true;
        }
        }).catch(() => { });
    },
    downloadEngagementReport(){
        document.getElementById("export-file").click();
        this.showSuccess = false;
        this.showAlert = false;
    },
},
destroyed() {
    this.removeClass(document.getElementById("menu-engagement"), "active-menu");
    this.removeClass(document.getElementById("menu-chapter"), "active-menu");
    },
created() {
    this.addClass(document.getElementById("menu-engagement"), "active-menu");
    this.addClass(document.getElementById("menu-chapter"), "active-menu");
}
}
</script>
