import { createRouter, createWebHashHistory } from 'vue-router'

import Login from "@/components/Login";
import Home from "@/components/Home";
import Dashboard from "@/components/Dashboard";
import General from "@/components/Chapter";
import Route from "@/components/Route";
import Members from "@/components/Members";
import Member from "@/components/Member";
import Roles from "@/components/Roles";
import Role from "@/components/Role";
import Schedule from "@/components/Speakers";
import Entry from "@/components/Speaker";
import Categories from "@/components/Categories";
import Category from "@/components/Category";
import Analytics from "@/components/Analytics";
import Section from "@/components/Section";
import Agenda from "@/components/Agenda";
import Slide from "@/components/Slide";
import Controller from "@/components/Controller";
import Attendance from "@/components/Attendance";
import Meeting from "@/components/Meeting";
import Reset from "@/components/Reset";
import Presentation from "@/components/Presentation";
import Preparer from "@/components/Preparer";
import Bios from "@/components/Bios";
import Organizations from "@/components/Chapters";
import Scripts from "@/components/Scripts";
import Announcements from "@/components/Announcements";
import Announcement from "@/components/Announcement";
import Logs from "@/components/Logs";
import Emaillogs from "@/components/Emaillogs";
import MemberPhoto from "@/components/MemberPhoto";
import Import from "@/components/Import";
import Goals from "@/components/Goals";
import Goal from "@/components/Goal";
import Portal from "@/components/Portal";
import Visitor from "@/components/Visitor";
import Demo from "@/components/Demo";
import Contacts from "@/components/Contacts";
import Setup from "@/components/Setup";
import CreateRegion from "@/components/CreateRegion";
import Filelog from "@/components/Filelog";
import Language from "@/components/superadmin/Language";
import Languages from "@/components/superadmin/Languages";
import Country from "@/components/superadmin/Country";
import Countries from "@/components/superadmin/Countries";
import Region from "@/components/superadmin/Region";
import Regions from "@/components/superadmin/Regions";
import Settings from "@/components/superadmin/Settings";
import Translations from "@/components/Translations";
import Quote from "@/components/Quote";
import FileControl from "@/components/FileControl";
import ControllerAttendance from "@/components/ControllerAttendance";
import Notifications from "@/components/Notifications";
import Tips from "@/components/superadmin/Tips";
import OnboardTips from "@/components/superadmin/OnboardTips";
import Performance from "@/components/Performance";
import ContactTypes from "@/components/superadmin/ContactTypes";
import Recognition from "@/components/Recognition";
import Status from "@/components/superadmin/Status";
import Events from "@/components/Events";
import EventPage from "@/components/Event";
import Newsletter from "@/components/NewsletterSettings";
import Regional from "@/components/Regional";
import Public from "@/components/Public"
import YearRoles from "@/components/YearRoles"
import UserDash from "@/components/UserDash"
import RegionImport from "@/components/RegionImport"
import SendEmail from "@/components/superadmin/SendEmail"
import Mobile from "@/components/Mobile"
import AdminImport from "@/components/superadmin/Import"
import PublicEvent from "@/components/PublicEvent"
import RegionDash from "@/components/RegionDash"
import Quotes from "@/components/Quotes"
import MeetingDates from "@/components/MeetingDates"
import Questions from "@/components/Questions"
import ContactDash from "@/components/ContactDash"
import Topics from "@/components/Topics"
import Topic from "@/components/Topic"
import PowerTeams from "@/components/PowerTeams"
import PowerTeam from "@/components/PowerTeam"
import Unauthorized from "@/components/Unauthorized"
import RegionMembers from "@/components/RegionMembers"
import SlideCopy from "@/components/superadmin/SlideCopy";
import SiteTranslations from "@/components/superadmin/SiteTranslations";
import Terms from "@/components/Terms";
import Privacy from "@/components/Privacy";
import MeetingAssistant from "@/components/MeetingAssistant";
import ConfirmSpeaker from "@/components/ConfirmSpeaker";
import AssistantAsks from "@/components/AssistantAsks"
import AssistantAsksRegion from "@/components/AssistantAsksRegion"
import Assistants from "@/components/Assistants"
import Blocked from "@/components/Bounced";
import RegionSlideCopy from "@/components/SlideCopy";
import EngagementReport from "@/components/EngagementReport"
import MediaTypes from "@/components/superadmin/MediaTypes"
import SlideOps from "@/components/SlideOps"
import Sync from "@/components/superadmin/Sync"
import CoreValueSpeakers from "@/components/CoreValueSpeakers"
import CoreValueSpeaker from "@/components/CoreValueSpeaker"
import ChapterAccess from "@/components/Access"
import Errorlog from "@/components/superadmin/Errorlog"
import MoveChapters from "@/components/superadmin/MoveChapters"

export default createRouter({
  history: createWebHashHistory(),
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
  }
    if (to.hash) {
      //return { el: to.hash }
      return {
        selector: to.hash,
        behavior: 'smooth'
      };
    }
    return { x: 0, y: 0 };  // Go to the top of the page if no hash
  },
  routes: [
    {
      path: "/",
      name: "Route",
      component: Route
    },
    {
      path: "/unauthorized",
      name: "Unauthorized",
      component: Unauthorized
    },
    {
      path: "/terms",
      name: "Terms",
      component: Terms
    },
    {
      path: "/privacy",
      name: "Privacy",
      component: Privacy
    },
    // {
    //   path: "/setup",
    //   name: "Setup",
    //   component: Setup
    // },
    // {
    //   path: "/setup/:org",
    //   component: Setup,
    //   props: true
    // },
    // {
    //   path: "/setup/:org/:member",
    //   component: Setup,
    //   props: true
    // },
    {
      path: "/login",
      name: "Login",
      component: Login
    },
    {
      path: "/controller/login",
      component: Login
    },
    {
      path: "/confirmspeaker/:hash/:confirmed",
      name: "ConfirmSpeaker",
      component: ConfirmSpeaker,
      props: true
    },
    {
      path: "/assistant/:linkuid",
      name: "MeetingAssistant",
      component: MeetingAssistant,
      props: true
    },
    {
      path: "/controller/attendance",
      name: "ControllerAttendance",
      component: ControllerAttendance,
    },
    {
      path: "/controller/attendance/:useruid",
      name: "ControllerAttendanceUser",
      component: ControllerAttendance,
      props: true
    },
    {
      path: "/controller/view",
      name: "Controller",
      component: Controller,
    },
    {
      path: "/demo",
      name: "Demo",
      component: Demo
    },
    {
      path: "/demo/:d",
      component: Demo,
      props: true
    },
    {
      path: "/public/:h",
      name: "Public",
      component: Public,
      props: true
    },
    {
      path: "/publicevent/:h",
      component: PublicEvent,
      props: true
    },
    {
      path: '/reset/:h',
      component: Reset,
      props: true
    },
    {
      path: '/memberinfo/:useruid',
      component: MemberPhoto,
      props: true
    },
    {
      path: '/memberinfo/:useruid/:pin',
      component: MemberPhoto,
      props: true
    },
    {
      path: '/attendance/:hash',
      component: Attendance,
      props: true
    },
    {
      path: '/speakers/:org/:day',
      component: Bios,
      props: true
    },
    {
      path: '/presentation/:h',
      component: Presentation,
      props: true
    },
    {
      path: '/topics/:id/:useruid',
      component: Topic,
      props: true
    },
    {
      path: '/powerteams/:id/:useruid',
      component: PowerTeam,
      props: true
    },
    {
      path: '/powerteams/:useruid',
      component: PowerTeams,
      props: true
    },
    {
      path: '/quote/:useruid',
      component: Quote,
      props: true
    },
    {
      path: '/categories/:useruid',
      component: Categories,
      props: true
    },
    {
      path: '/category/:id/:useruid',
      component: Category,
      props: true
    },
    {
      path: '/filecontrol/:h',
      component: FileControl,
      props: true
    },
    {
      path: '/prepare/:h',
      component: Preparer,
      props: true
    },
    {
      path: '/prepare/:h/:useruid',
      component: Preparer,
      props: true
    },
   
    {
      path: '/meeting',
      component: Meeting
    },
    {
      path: '/meeting/:offline',
      component: Meeting,
      props: true
    },
    {
      path: '/portal',
      component: Portal
    },
    {
      path: '/visitor/:hash',
      component: Visitor,
      props: true
    },
    {
      path: '/performance/:useruid',
      component: Performance,
      props: true
    },
    {
      path: '/visitor',
      component: Visitor
    },
    {
      path: '/newsletter/:useruid',
      component: Newsletter,
      props: true
    },
    {
      path: '/events/:useruid',
      component: Events,
      props: true
    },
    {
      path: '/events/:id/:useruid',
      component: EventPage,
      props: true
    },
   
    {
      path: '/schedule/:useruid',
      component: Schedule,
      props: true
    },
    {
      path: '/schedule/:id/:useruid',
      component: Entry,
      props: true
    },
    {
      path: '/userdash',
      component: UserDash
    },
    {
      path: '/mobile',
      component: Mobile
    },
    {
      path: '/contactdash',
      component: ContactDash
    },
    {
      path: "/admin",
      name: "Home",
      component: Home,
      children: [
        {
          path: 'movechapters',
          component: MoveChapters
        },
        {
          path: 'errorlog',
          component: Errorlog
        },
        {
          path: 'access',
          component: ChapterAccess
        },
        {
          path: 'slide-ops',
          component: SlideOps
        },
        {
          path: 'regionasks',
          component: AssistantAsksRegion
        },
        {
          path: 'asks',
          component: AssistantAsks
        },
        {
          path: 'assistants',
          component: Assistants
        },
        {
          path: 'questions',
          component: Questions
        },
        {
          path: 'meetingdates',
          component: MeetingDates
        },
        {
          path: 'meetingdates/:id',
          component: MeetingDates,
          props: true
        },
        {
          path: 'adminimport',
          component: AdminImport
        },
        {
          path: 'regionmembers',
          component: RegionMembers
        },
        {
          path: 'sendemail',
          component: SendEmail
        },
        {
          path: 'prepare/:h',
          component: Preparer,
          props: true
        },
        {
          path: 'regionimport',
          component: RegionImport
        },
        {
          path: 'yearroles',
          component: YearRoles
        },
       {
         path: 'regional',
         component: Regional
       },
        {
          path: 'events/:id',
          component: EventPage,
          props: true
        },
        {
          path: 'events',
          component: Events
        },
        {
          path: 'topics/:id',
          component: Topic,
          props: true
        },
        {
          path: 'topics',
          component: Topics
        },
        {
          path: 'powerteams/:id',
          component: PowerTeam,
          props: true
        },
        {
          path: 'powerteams',
          component: PowerTeams
        },
        {
          path: 'recognition',
          component: Recognition
        },
        {
          path: 'performance',
          component: Performance
        },
        {
          path: 'notifications',
          component: Notifications
        },
        {
          path: 'quote',
          component: Quote
        },
        {
          path: 'quotes',
          component: Quotes
        },
        {
          path: 'quotes/:id',
          component: Quote,
          props: true
        },
        {
          path: 'translations',
          component: Translations
        },
        {
          path: 'language/:id',
          component: Language,
          props: true
        },
        {
          path: 'region/:id',
          component: Region,
          props: true
        },
        {
          path: 'country/:id',
          component: Country,
          props: true
        },
        {
          path: 'slidecopy',
          component: SlideCopy,
        },
        {
          path: 'regional-slidecopy',
          component: RegionSlideCopy,
        },
        {
          path: 'engagement-report',
          component: EngagementReport,
        },
        {
          path: 'sitetranslations',
          component: SiteTranslations,
        },
        {
          path: 'languages',
          component: Languages
        },
        {
          path: 'tips',
          component: Tips
        },
        {
          path: 'onboard-tips',
          component: OnboardTips
        },
        {
          path: 'countries',
          component: Countries
        },
        {
          path: 'regions',
          component: Regions
        },
        {
          path: 'settings',
          component: Settings
        },
        {
          path: 'status',
          component: Status
        },
        {
          path: 'logs',
          component: Logs
        },
        {
          path: 'emaillogs',
          component: Emaillogs
        },
        {
          path: 'blocked',
          component: Blocked
        },
        {
          path: 'filelog',
          component: Filelog
        },
        {
          path: 'dashboard',
          component: Dashboard
        },
        {
          path: 'regiondash',
          component: RegionDash
        },
        {
          path: 'import',
          component: Import
        },
        {
          path: 'general',
          component: General
        },
        {
          path: 'chapters',
          component: Organizations
        },
        {
          path: 'members',
          component: Members
        },
        {
          path: 'member/:id',
          component: Member,
          props: true
        },
        {
          path: 'member/:id/:org',
          component: Member,
          props: true
        },
        {
          path: 'contact/:id',
          component: Member,
          props: true
        },
        {
          path: 'contacts',
          component: Contacts
        },
        {
          path: 'contacttypes',
          component: ContactTypes
        },
        {
          path: 'mediaTypes',
          component: MediaTypes
        },
        {
          path: 'scripts',
          component: Scripts
        },
        {
          path: 'scripts/:id',
          component: Scripts,
          props: true
        },
        {
          path: 'roles',
          component: Roles
        },
        {
          path: 'role/:id',
          component: Role,
          props: true
        },
         {
          path: 'goals',
          component: Goals
        },
        {
          path: 'goal/:id',
          component: Goal,
          props: true
        },
        {
          path: 'announcements',
          component: Announcements
        },
        {
          path: 'announcement/:id',
          component: Announcement,
          props: true
        },
        {
          path: 'schedule',
          component: Schedule
        },
        {
          path: 'schedule/:id',
          component: Entry,
          props: true
        },
        {
          path: 'categories',
          component: Categories
        },
        {
          path: 'category/:id',
          component: Category,
          props: true
        },
        {
          path: 'corevalues',
          component: CoreValueSpeakers
        },
        {
          path: 'corevalues/:id',
          component: CoreValueSpeaker,
          props: true
        },
        {
          path: 'analytics',
          component: Analytics
        },
        {
          path: 'attendance',
          component: Attendance
        },
        {
          path: 'section/:id',
          component: Section,
          props: true
        },
        {
          path: 'agenda',
          component: Agenda,
          name: "Agenda",
        },
        {
          path: 'agenda/:id',
          component: Agenda,
          props: true
        },
        {
          path: 'slide/:sid/:id',
          component: Slide,
          props: true
        },
        {
          path: 'newsletter',
          component: Newsletter
        },
        {
          path: 'sync',
          component: Sync
        },
      ]
    },
    {
      path: '/:pathMatch(.*)*',
      component: Login
    }
  ]
});
