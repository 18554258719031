<template>
<div id="assistant-wrapper">
  <div id="inner-assistant-wrapper">
    <div v-if="showAttendance==0 && showPast==0 && showView!=4 && showView != 5" id="assistant-header">
        <img id="assistant-logo" src="../assets/CC-LogoMark-white.png" />
        <div id="assistant-header-info">
            <div id="assistant-org-name">{{assistant.org_name}}</div>
            <div id="assistant-day">{{assistant.day? $formatDate(new Date("" + assistant.day.split("-").join("/") + " 00:01")) : ""}}</div>
            <div id="assistant-start">{{meeting.meet_start && meeting.meet_end? meeting.meet_start+" - "+meeting.meet_end : ""}}</div>
            <div id="assistant-username"><b>{{assistant.name}}</b></div>
        </div>
        <div id="assistant-type-text">{{event_chapter? $t('assistant.event_assistant'): $t('assistant.meeting_assistant')}}</div>
        <div v-if="languageOptions.length>0" id="switch-language-button" class="clickable" v-on:click="switchLanguage">{{this.permissions.organization.language.toUpperCase()}}</div>
        <div v-if="languageOptions.length==0"></div>
    </div>
    <div v-if="showAttendance==1" id="attendance-top-buffer"></div>
    <div id="assistant-main-view">
        <transition-group :name="transition_name" >
            <div v-if="showView==8" class="full-area-absolute" key="view8" ref="view8">
                 <div>
                    <div class="assistant-section-header dark-blue-section-header">
                        <img src="../assets/AddToCalendarWhite.png" /> <span>{{$t('assistant.meeting_details')}}</span> <span></span><div v-on:click="showView=1;" class="section-header-close clickable"></div>
                    </div>
                </div>
                <div v-if="linkEvent" id="link-event-wrapper">
                    <div id="link-event-info">
                        <div id="link-event-org">{{assistant.org_name}}</div>
                        <div id="link-event-day">{{linkEvent.day? $formatDate(new Date("" + linkEvent.day.split("-").join("/") + " 00:01")) : ""}}</div>
                        <div id="link-event-start">{{linkEvent.meet_start}} - {{linkEvent.meet_end}}</div>
                        <div id="link-event-type"><i>{{linkEvent.meeting_mode==0? $t('general.hybrid_text') : linkEvent.meeting_mode==1? $t('general.online_text') : $t('general.inperson_text')}}</i></div>
                        <div id="select-calendar">{{$t('assistant.select_calendar')}}</div>
                    </div>
                    <div class="calendar-option clickable" v-on:click="googleCalendar(linkEvent)"><img src="../assets/icon-Cal-Google.png" /><span>{{$t('assistant.google_calendar')}}</span></div>
                    <!--<div class="calendar-option clickable" v-on:click="outlookCalendar(linkEvent,false)"><img src="../assets/icon-Cal-Outlook.png" /><span>{{$t('assistant.microsoft_outlook')}}</span></div>-->
                    <div class="calendar-option clickable" v-on:click="outlookCalendar(linkEvent,true)"><img src="../assets/icon-Cal-Office365.png" /><span>{{$t('assistant.office_365')}}</span></div>
                    <div class="calendar-option clickable" v-on:click="yahooCalendar(linkEvent)"><img src="../assets/icon-Cal-Yahoo.png" /><span>{{$t('assistant.yahoo_calendar')}}</span></div>
                    <div class="calendar-option clickable" v-on:click="makeICSFile(linkEvent)"><img src="../assets/icon-Cal-ICS.png" /><span>{{$t('assistant.download_ics')}}</span></div>
                </div>
            </div>
            <div v-if="showView==7" class="full-area-absolute" key="view7" ref="view7">
                <div class="valign-grid full-assistant-height">
                    <div></div>
                    <div class="end-text" v-html="$t('assistant.thank_you')">
                    </div>
                    <div></div>
                </div>
                <div class="end-logo">
                    <img src="../assets/PoweredByMeetingHUB.png" />
                </div>
            </div>
            <div v-if="showView==6" class="full-area-absolute" key="view6" ref="view6">
                <div class="valign-grid full-assistant-height">
                    <div></div>
                    <div class="end-text" v-html="$t('assistant.bad_link')">
                    </div>
                    <div></div>
                </div>
                <div class="end-logo">
                    <img src="../assets/PoweredByMeetingHUB.png" />
                </div>
            </div>
            <div v-if="showView==9" class="full-area-absolute" key="view9" ref="view9">
                <div class="full-assistant-height">
                    <div>
                        <div class="assistant-section-header dark-blue-section-header">
                            <img src="../assets/icon-MA-Guest.png" /> <span>{{$t('assistant.register_guest')}}</span><span></span> <div v-on:click="showView=1;" class="section-header-close clickable"></div>
                        </div>
                    </div>
                    <div id="guest-info-box" class="assistant-section">
                        <b-form-input :disabled="guest.id>0" :placeholder="$t('assistant.name_text')" v-model="guest.name" type="text" pattern="[^<>]*" :title="$t('global.pattern_title')" class="guest-input" ></b-form-input>
                        <b-form-input :disabled="guest.id>0" :placeholder="$t('assistant.company_text')" v-model="guest.company" type="text" pattern="[^<>]*" :title="$t('global.pattern_title')" class="guest-input" ></b-form-input>
                        <b-form-input :disabled="guest.id>0" :placeholder="$t('assistant.mobile_phone')" v-model="guest.phone" type="tel" class="guest-input" ></b-form-input>
                        <b-form-input :disabled="guest.id>0" :placeholder="$t('assistant.email_text')" v-model="guest.email" type="email" class="guest-input"></b-form-input>
                        <b-form-input :disabled="guest.id>0" :placeholder="$t('assistant.linkedin_url')" v-model="guest.linkedin_url" type="text" class="guest-input" ></b-form-input>
                        <b-form-select :disabled="guest.id>0" v-model="guest.inperson_or_virtual" :options="attendanceTypeOptions"/>
                        <b-form-select :disabled="guest.id>0" v-model="guest.pref_lang" :options="languageOptions" value-field="id" text-field="name"/>
                        <b-alert class="assistant-alert" variant="danger" dismissible :show="showAlert" @dismissed="showAlert=false">
                            {{$t('global.complete_fields')}}
                        </b-alert>
                        <b-button id="guest-update-button" v-if="!(guest.id>0)" class="action-button" v-on:click="updateGuest(true)" variant="clear-blue"><div class="save-button" ></div><span>{{guest.id>0? $t('global.button_save') : $t('assistant.register_text')}}</span></b-button>
                    </div>
                    
                </div>
            </div>
            <div v-if="showView==5" class="full-area-absolute" key="view5" ref="view5">
                <div class="full-assistant-height">
                    <div>
                        <div class="assistant-section-header dark-blue-section-header">
                            <img src="../assets/icon-MA-Attendance.png" /> <span>{{$t('assistant.attendee_helpers')}}</span><span></span> <div v-on:click="showView=1;" class="section-header-close clickable"></div>
                        </div>
                    </div>
                    <div class="assistant-section no-padding-section">
                        <div v-for="a in attendance.filter(v => spotlight.connections.map(x => x.assistant_id).includes(v.assistant_id))" :key="a.id" class="attendee-helper">
                            <div class="attendance-header attendee-helper-header" v-on:click="a.collapse = 1-a.collapse">
                                <div class="circular-member circular-member-image" :style="{'background-image': 'url('+a.photo+')'}">
                                    <div class="guest-banner guest-text" v-if="a.guest==1">{{$t('assistant.guest_text')}}</div>
                                </div>
                                <b>{{a.name}}</b>
                                <i>{{a.company}}</i>
                                <div class="section-arrow" :class="{'up-section-arrow' : a.collapse==1, 'down-section-arrow': a.collapse==0}"></div>
                            </div>
                            <b-collapse accordion="attendees" v-model="a.collapse">
                                <div class="fit-width padded-center">
                                    <div class="fit-width grid-fit-2column padded-center">
                                        <img v-if="a.linkedin_url && a.linkedin_url.length>0" class="clickable linkedin-image" v-on:click="openLinkedin(a);" src="../assets/LinkedIn.png"/>
                                        <div v-if="!(a.linkedin_url && a.linkedin_url.length>0)" class="row-span2"></div>
                                        <a :href="'mailto:'+a.email">{{a.email}}</a>
                                        <a :href="'tel:'+a.phone">{{a.phone}}</a>
                                    </div>
                                    <div class="purpose-button assistant-button-filled vcar-button" v-on:click="makeVCard(a)"><b>{{$t('assistant.download_vcard')}}</b></div>
                                </div>
                            </b-collapse>
                            <div  v-for="c in spotlight.connections.filter(v => v.assistant_id == a.assistant_id)" :key="c.id" class="spotlight-connection">
                                <div><b>{{c.name}}</b> <span v-if="c.relationship>0">({{$t('assistant.assistant_relationship'+c.relationship)}})</span></div>
                                <div><i>{{c.company}}</i></div>
                                <br/>
                                <div>{{c.info}}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="showView==4" class="full-area-absolute" key="view4" ref="view4">
                <div class="full-assistant-height">
                    <div>
                        <div class="assistant-section-header dark-blue-section-header">
                            <img src="../assets/icon-MA-Attendance.png" /> <span>{{$t('assistant.attendee_helpers')}}</span> <span></span><div v-on:click="showView=1;" class="section-header-close clickable"></div>
                        </div>
                    </div>
                    <div v-if="ask.helpers.length>0" class="assistant-section no-padding-section">
                        <div v-for="a in attendance.filter(v => ask.helpers.includes(v.assistant_id))" :key="a.id" >
                            <div class="attendance-header" v-on:click="a.collapse = 1-a.collapse" :style="{'background-color': a.collapse==1? 'white' : '', border: a.collapse==1? 'none' : '', color: a.collapse==1? 'black' : ''}">
                                <div class="circular-member circular-member-image" :style="{'background-image': 'url('+a.photo+')'}">
                                    <div class="guest-banner guest-text" v-if="a.guest==1">{{$t('assistant.guest_text')}}</div>
                                </div>
                                <b>{{a.name}}</b>
                                <i>{{a.company}}</i>
                                <div class="section-arrow" :class="{'up-section-arrow' : a.collapse==1, 'down-section-arrow': a.collapse==0}"></div>
                            </div>
                            <b-collapse accordion="attendees" v-model="a.collapse">
                                <div class="fit-width padded-center">
                                    <div class="fit-width grid-fit-2column padded-center">
                                        <img v-if="a.linkedin_url && a.linkedin_url.length>0" class="clickable linkedin-image" v-on:click="openLinkedin(a);" src="../assets/LinkedIn.png"/>
                                        <div v-if="!(a.linkedin_url && a.linkedin_url.length>0)" class="row-span2"></div>
                                        <a :href="'mailto:'+a.email">{{a.email}}</a>
                                        <a :href="'tel:'+a.phone">{{a.phone}}</a>
                                    </div>
                                    <div class="purpose-button assistant-button-filled vcard-button" v-on:click="makeVCard(a)"><b>{{$t('assistant.download_vcard')}}</b></div>
                                </div>
                            </b-collapse>
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="showView==3" class="full-area-absolute" key="view3" ref="view3">
                <div class="full-assistant-height">
                    <div>
                        <div class="assistant-section-header dark-blue-section-header">
                            <img src="../assets/icon-MA-HelpYou.png" /> <span>{{$t('assistant.how_can_we_help')}}</span> <span></span><div v-on:click="showView=1;" class="section-header-close clickable"></div>
                        </div>
                    </div>
                    <div class="help-box">
                        <div>{{$t('assistant.your_ask')}}</div>
                        <b-form-textarea v-model="ask.ask" placeholder="" :rows="12"/>
                        <div v-if="ask.helpers>0" class="assistant-margin-top20">
                            <div id="attendess-can-help" v-on:click="showHelpers(ask)" class="purpose-button assistant-button-filled fit-width" >{{$tc('assistant.attendees_can_help',ask.helpers.length, {num: ask.helpers.length})}}</div>
                        </div>
                        <div v-if="!past" class="submit-box-2 assistant-margin-top20">
                            <b-button class="action-button" v-on:click="updateAsk(ask)" variant="clear-blue"><div class="save-button"></div><span>{{$t('global.button_save')}}</span></b-button>
                            <b-button class="action-button" v-on:click="deleteAsk(ask)" variant="clear-blue"><div :class="{'delete-button': ask.id>0, 'cancel-button': ask.id==0}"></div><span>{{ask.id>0? $t('global.button_delete') : $t('global.button_cancel')}}</span></b-button>
                        </div>
                        <div v-if="past" class="submit-box-2 assistant-margin-top20">
                            <b-button class="action-button" v-on:click="showView = 1;" variant="clear-blue"><div class="back-button"></div><span>{{$t('global.button_back')}}</span></b-button>
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="showView==2" class="full-area-absolute" key="view2" ref="view2">
                <div class="full-assistant-height">
                    <div>
                        <div class="assistant-section-header dark-blue-section-header">
                            <img src="../assets/icon-MA-Spotlight.png" /> <span><span>{{$t('assistant.spotlight_presentation')}}</span><br/><span class="spotlight-name">{{spotlight.name}}</span></span><span></span><div v-on:click="showView=1;" class="section-header-close clickable"></div> 
                        </div>
                    </div>
                    <div  class="help-box">
                        <b-form-input class="connection-input" :placeholder="$t('assistant.name_text')" v-model="connection.name" type="text" pattern="[^<>]*" :title="$t('global.pattern_title')"></b-form-input>
                        <b-form-input class="connection-input" :placeholder="$t('assistant.company_text')" v-model="connection.company" type="text" pattern="[^<>]*" :title="$t('global.pattern_title')"></b-form-input>
                        <div>{{$t('assistant.relationship_nature')}}</div>
                        <div id="connection-type">
                            <div class="purpose-button relation-button" :style="{'font-weight': connection.relationship==1? 'bold': 'normal'}" :class="{'assistant-button-filled': connection.relationship==1}" v-on:click="connection.relationship=1">{{$t('assistant.assistant_relationship1')}}</div>
                            <div class="purpose-button relation-button" :style="{'font-weight': connection.relationship==2? 'bold': 'normal'}" :class="{'assistant-button-filled': connection.relationship==2}" v-on:click="connection.relationship=2">{{$t('assistant.assistant_relationship2')}}</div>
                            <div class="purpose-button relation-button" :style="{'font-weight': connection.relationship==3? 'bold': 'normal'}" :class="{'assistant-button-filled': connection.relationship==3}" v-on:click="connection.relationship=3">{{$t('assistant.assistant_relationship3')}}</div>
                        </div>
                        <div class="assistant-margin-top20">{{$t('assistant.additional_info')}}</div>
                        <b-form-textarea v-model="connection.info" :rows="6" class="assistant-textarea"/>

                        <b-alert variant="danger" dismissible :show="showAlert" @dismissed="showAlert=false" class="assistant-alert">
                            {{$t('global.complete_fields')}}
                        </b-alert>
                        <div v-if="!past" class="submit-box-2 assistant-margin-top20">
                            <b-button class="action-button" v-on:click="updateConnection(connection)" variant="clear-blue"><div class="save-button"></div><span>{{$t('global.button_save')}}</span></b-button>
                            <b-button class="action-button" v-on:click="connection.id>0? showDelete=true : showView = 1;" variant="clear-blue"><div :class="{'delete-button': connection.id>0, 'cancel-button': connection.id==0}"></div><span>{{connection.id>0? $t('global.button_delete') : $t('global.button_cancel')}}</span></b-button>
                        </div>
                        <div v-if="past" class="submit-box-2 assistant-margin-top20">
                            <b-button class="action-button" v-on:click="showView = 1;" variant="clear-blue"><div class="back-button"></div><span>{{$t('global.button_back')}}</span></b-button>
                        </div>
                        <b-modal id="modal-center" v-model="showDelete" centered :title="$t('assistant.delete_title')">
                            <p class="my-4" v-html="$t('assistant.delete_text')"></p>
                            <div slot="modal-footer" class="submit-box-2">
                                <b-button class="action-button" v-on:click="showDelete=false" variant="clear-blue"><div class="cancel-button"></div><span>{{$t('global.button_cancel')}}</span></b-button>
                                <b-button class="action-button" v-on:click="deleteConnection" variant="clear-blue"><div class="delete-button"></div><span>{{$t('global.button_delete')}}</span></b-button>
                            </div>
                        </b-modal>
                    </div>
                </div>
            </div>
            <div v-if="showView==1" class="full-area-absolute" key="view1" ref="view1">
                <div class="full-assistant-height">
                <div v-show="showAttendance==0 && showPast==0 && (!past || assistant.member_id>0)">
                    <div v-b-toggle:details-section >
                        <div class="assistant-section-header">
                            <img src="../assets/icon-MA-Details.png" /> <span>{{event_chapter? $t('assistant.event_details'):$t('assistant.meeting_details')}}</span><span></span><div></div>
                        </div>
                    </div>
                    <b-collapse  @show="openDetails" id="details-section" accordion="sections" :visible="currentTab == 1">
                        <div >
                            <div v-for="a in upcomingMeetings" :key="a.id" >
                                <div class="attendance-header" v-on:click="a.collapse = 1-a.collapse" :style="{'padding-bottom': a.collapse==1? '0px' : '', 'background-color': a.collapse==1? 'white' : '', border: a.collapse==1? 'none' : '', color: a.collapse==1? 'black' : '', 'grid-template-columns': '65px max-content auto 32px'}">
                                    <div class="upcoming-meeting-info">
                                        <span class="upcoming-meeting-day">{{a.day? $formatDate(new Date("" + a.day.split("-").join("/") + " 00:01"), 'day_month_long') : ""}}</span>
                                        <span class="upcoming-meeting-start">{{a.meet_start}}</span>
                                        <span class="upcoming-meeting-type">{{a.meeting_mode==0? $t('general.hybrid_text') : a.meeting_mode==1? $t('general.online_text') : $t('general.inperson_text')}}</span>
                                    </div>
                                    <div v-if="!event_chapter && a.name != null" class="circular-member circular-member-image-speaker" :style="{'background-image': 'url('+a.photo+')'}"></div>
                                    <b v-if="!event_chapter && a.name != null" >{{a.name}}</b>
                                    <i v-if="!event_chapter && a.name != null" >{{a.company}}</i>
                                    <div v-if="!event_chapter && a.name == null" class="speaker-break"></div>
                                    <div v-if="!event_chapter && a.name == null">{{$t('assistant.speaker_tbd')}}</div>
                                    <div v-if="!event_chapter && a.name == null"></div>
                                    <div v-if="event_chapter" class="event-photo" :style="{'background-image': 'url('+a.event_photo+')'}"></div>
                                    <div v-if="event_chapter" class="event-title">{{a.title}}</div>
                                    <div v-if="event_chapter"></div>
                                    <div class="section-arrow fourth-column" :class="{'up-section-arrow' : a.collapse==1, 'down-section-arrow': a.collapse==0}"></div>
                                </div>
                                <b-collapse accordion="attendees" v-model="a.collapse">
                                    <div class="date-actions-box">
                                        <div class="event-links-wrapper">
                                            <div></div>
                                            <div class="clickable event-links-button" v-on:click="goToEventLinks(a)">
                                                <img src="../assets/AddToCalendarWhite.png" />
                                            </div>
                                        </div>
                                        <div class="fit-width topic-padding">
                                            <span v-if="!event_chapter && a.name != null">{{a.topic}}</span>
                                        </div>
                                        <div class="register-guest-button purpose-button assistant-button-filled " v-on:click="registerGuest(a)"><b>{{$t('assistant.register_guest')}}</b></div>
                                    </div>
                                    <div v-if="a.meeting_mode!=2 && a.meeting_link" class="meeting-type-box">
                                        <div class="meeting-type-text"><b>{{$t('assistant.virtual_meeting')}}</b></div>
                                        <img class="zoom-icon" src="../assets/icon-Zoom.png" />
                                        <div v-if="a.meeting_link.length>0" class="fit-width grid-fit-2column auto-margin">
                                            <div class="purpose-button assistant-button-filled zoom-option-button" v-on:click="sendLinkEmail(a.id)">{{$t('assistant.email_link')}}</div>
                                            <div class="purpose-button assistant-button-filled zoom-option-button" v-on:click="openZoom(a.meeting_link)">{{$t('assistant.open_zoom')}}</div>
                                        </div>
                                    </div>
                                    <div v-if="a.meeting_mode!=1 &&  a.day_location" class="meeting-type-box">
                                        <div class="meeting-type-text"><b>{{$t('assistant.inperson_meeting')}}</b></div>
                                        <div class="in-person-location-box">
                                            <img class="in-person-location-image" src="../assets/location.png" alt="location"/>
                                            <pre class="pre-format in-person-location">{{a.day_location}}</pre>
                                        </div>
                                        <div id="map-button" class=" purpose-button assistant-button-filled " v-on:click="openMap(a.day_location)">{{$t('assistant.open_map')}}</div>
                                    </div>
                                    <div v-if="!((a.meeting_mode!=1 && a.day_location) || (a.meeting_mode!=2 && a.meeting_link))" id="details-unavailable">
                                        {{$t('assistant.details_not_available')}}
                                    </div>
                                </b-collapse>
                            </div>
                        </div>
                    </b-collapse>
                </div>
                <div v-show="showPast==0">
                     <div v-b-toggle:attendees-section >
                        <div class="assistant-section-header" :style="{'position': showAttendance? 'absolute' : '', 'width': '100%', 'height':'62px', 'top': showAttendance? '-62px': ''}">
                            <img src="../assets/icon-MA-Attendance.png" /> <span>{{event_chapter? $t('assistant.event_attendees'):$t('assistant.meeting_attendees')}}</span><span></span> <div :class="{'section-header-close': showAttendance}"></div>
                        </div>
                    </div>
                    <b-collapse @show="openAttendance" id="attendees-section" accordion="sections" v-model="showAttendance" :visible="currentTab == 2">
                        <div class="assistant-section no-padding-section">
                            <div v-for="a in attendance" :key="a.id" >
                                <div class="attendance-header" v-on:click="a.collapse = 1-a.collapse" :style="{'background-color': a.collapse==1? 'white' : '', border: a.collapse==1? 'none' : '', color: a.collapse==1? 'black' : ''}">
                                    <div class="circular-member circular-member-image" :style="{'background-image': 'url('+a.photo+')'}">
                                        <div class="guest-banner guest-text" v-if="a.guest==1">{{$t('assistant.guest_text')}}</div>
                                    </div>
                                    <b>{{a.name}}</b>
                                    <i>{{a.company}}</i>
                                    <div class="section-arrow" :class="{'up-section-arrow' : a.collapse==1, 'down-section-arrow': a.collapse==0}"></div>
                                </div>
                                <b-collapse accordion="attendees" v-model="a.collapse">
                                    <div class="fit-width padded-center">
                                            <div class="fit-width grid-fit-2column padded-center">
                                            <img v-if="a.linkedin_url && a.linkedin_url.length>0" class="clickable linkedin-image" v-on:click="openLinkedin(a);" src="../assets/LinkedIn.png"/>
                                            <div v-if="!(a.linkedin_url && a.linkedin_url.length>0)" class="row-span2"></div>
                                            <a :href="'mailto:'+a.email">{{a.email}}</a>
                                            <a :href="'tel:'+a.phone">{{a.phone}}</a>
                                        </div>
                                        <div class="purpose-button assistant-button-filled vcard-button" v-on:click="makeVCard(a)"><b>{{$t('assistant.download_vcard')}}</b></div>
                                    </div>
                                </b-collapse>
                            </div>
                        </div>
                    </b-collapse>
                </div>
                <div v-show="assistant.guest ==1 && showAttendance == 0 && showPast==0 && !past">
                    <div v-b-toggle:welcome-section >
                        <div class="assistant-section-header">
                            <img src="../assets/icon-MA-Welcome.png" /> <span>{{$t('assistant.welcome_text', {name: assistant.name})}}</span><span></span><div></div>
                        </div>
                    </div>
                    <b-collapse @show="openWelcome" id="welcome-section" accordion="sections" :visible="currentTab == 3">
                        <div  class="fit-width assistant-section">
                            <div class="center-text" >{{$t('assistant.here_to')}}</div>
                            <div class="purpose-button" :style="{'font-weight': connection.relationship==1? 'bold': 'normal'}" :class="{'assistant-button-filled': assistant.purpose==1}" v-on:click="setPurpose(1)">{{$t('assistant.assistant_purpose1')}}</div>
                            <div class="purpose-button" :style="{'font-weight': connection.relationship==1? 'bold': 'normal'}" :class="{'assistant-button-filled': assistant.purpose==2}" v-on:click="setPurpose(2)">{{$t('assistant.assistant_purpose2')}}</div>
                            <div class="purpose-button" :style="{'font-weight': connection.relationship==1? 'bold': 'normal'}" :class="{'assistant-button-filled': assistant.purpose==3}" v-on:click="setPurpose(3)">{{$t('assistant.assistant_purpose3')}}</div>
                        </div>
                    </b-collapse>
                </div>
                <div v-show="showAttendance==0 && showPast==0">
                    <div v-b-toggle:we-help-section >
                        <div class="assistant-section-header">
                            <img src="../assets/icon-MA-HelpYou.png" /> <span>{{$t('assistant.how_can_we_help')}}</span>
                                <span><span v-if="assistant.asks.map(v => v.unseen_helpers.length).reduce((v1,v2) => v1+v2,0)>0 && !past" class="unseen-number">{{assistant.asks.map(v => v.unseen_helpers.length).reduce((v1,v2) => v1+v2,0)}}</span></span>
                                 <div></div>
                        </div>
                    </div>
                    <b-collapse @show="openAsks" id="we-help-section" accordion="sections" :visible="currentTab == 4">
                        <div  class="assistant-section no-overflow-section">
                            <div v-if="assistant.asks.length>0" id="asks-wrapper" v-touch:swipe.left="prevAsk" v-touch:swipe.right="nextAsk">
                                <div class="center-text">{{$t('assistant.your_ask')}}</div>
                                <transition-group :name="transition_type" >
                                    <div class="assistant-ask" v-for="a in assistant.asks.filter(v => v==ask)" :key="a.id" ref="ask" >
                                        <div v-on:click="editAsk(a)" :class="{'clickable': !past}" class="ask-box">
                                            <div class="fit-width">
                                                <div v-if="a.unseen_helpers.length>0" v-on:click.stop="showHelpers(ask)" class="unseen-number unseen-helpers">{{a.unseen_helpers.length}}</div>
                                                <pre class="pre-format curtail-text pre-ask">{{a.ask}}</pre>
                                            </div>
                                        </div>
                                    </div>
                                </transition-group>
                            </div>
                            <div v-if="assistant.asks.length>1" class="center-text">
                                <span class="clickable" v-for="o in assistant.asks" :key="o.id" v-on:click="goToAsk(o)" :style="{'color': ask == o? '#24679A': '#98989A'}">
                                    &#9679;
                                </span>
                            </div>
                            <div v-if="!past" class="center-text">
                                <b-button class="action-button auto-margin" v-on:click="newAsk" variant="clear-blue"><div class="new-button"></div><span>{{$t('global.button_new')}}</span></b-button>
                            </div>
                        </div>
                    </b-collapse>
                </div>
                <div v-show="showAttendance==0 && showPast==0">
                    <div v-b-toggle:you-help-section >
                        <div class="assistant-section-header">
                            <img src="../assets/icon-MA-YouHelp.png" /> <span>{{$t('assistant.how_can_you_help')}}</span>
                            <span><span v-if="other_asks.filter(v => v.unseen).length>0 && !past" class="unseen-number">{{other_asks.filter(v => v.unseen).length}}</span> </span>
                            
                            <div></div>
                        </div>
                    </div>
                    <b-collapse @show="openOtherAsks" id="you-help-section" accordion="sections" v-model="showOtherAsks" :visible="currentTab == 5">
                        <div  class="assistant-section no-overflow-section">
                            <div v-if="other_asks.length>0" id="other-asks-wrapper" v-touch:swipe.left="prevOtherAsk" v-touch:swipe.right="nextOtherAsk">
                                <transition-group :name="transition_type" >
                                    <div class="assistant-ask" v-for="ask in other_asks.filter(v => v==showAsk)" :key="ask.id" ref="ask" >
                                        <div class="other-ask-member-info">
                                            <div class="circular-member other-ask-member-image" :style="{'background-image': 'url('+ask.photo+')'}"></div>
                                            <div>{{ask.name}}</div>
                                            <i class="curtail-text" >{{ask.company}}</i>
                                        </div>
                                        <div class="other-ask-wrapper">
                                            <div >
                                                <pre class="pre-format pre-other-ask">{{ask.ask}}</pre>
                                            </div>
                                        </div>
                                        <b-form-checkbox v-if="!past" @change="updateOtherAsk(ask)" class="center-text" switch size="lg" v-model="ask.canHelp">{{$t('assistant.i_can_help', {name: ask.name})}}</b-form-checkbox>
                                    </div>
                                </transition-group>
                        </div>
                            <div v-if="other_asks.length>1" class="center-text">
                                <span class="clickable" v-for="o in other_asks" :key="o.id" v-on:click="goToOtherAsk(o)" :style="{'color': showAsk == o? '#24679A': (o.unseen && !past? 'red' : '#98989A')}">
                                    &#9679;
                                </span>
                            </div>
                            <div v-if="other_asks.length==0" class="center-text">
                                {{$t('assistant.no_other_asks')}}
                            </div>
                        </div>
                    </b-collapse>
                </div>
                <div v-if="spotlight.name && spotlight.name.length>0 && showAttendance==0 && showPast==0 && (!past || spotlight.connections.length>0)">
                    <div v-b-toggle:spotlight-section >
                        <div class="assistant-section-header">
                            <img src="../assets/icon-MA-Spotlight.png" /> 
                            <span class="spotlight-name-wrapper">
                                <span>{{$t('assistant.spotlight_presentation')}}</span>
                                <br/><span class="spotlight-name">{{spotlight.name}}</span>
                            </span>
                                <span><span v-if="spotlight.is_member && spotlight.connections.filter(v=>v.unseen==1).length>0 && !past" class="unseen-number">{{spotlight.connections.filter(v=>v.unseen==1).length}}</span></span>
                                <div></div> 
                        </div>
                    </div>
                    <b-collapse @show="openSpotlight" id="spotlight-section" accordion="sections" :visible="currentTab == 6">
                        <div v-if="!spotlight.is_member" class="fit-width assistant-section">
                            <div class="center-text">{{$t('assistant.opportunities_for', {name : spotlight.name})}}:</div>
                            <div v-for="c in spotlight.connections" :key="c.id" class="purpose-button assistant-button-filled connection-text" v-on:click="editConnection(c)">
                                <b>{{c.name}}</b><br/>
                                <i>{{c.company}}</i>
                            </div>
                            <div v-if="!past" class="purpose-button assistant-button-filled connection-text" v-on:click="newConnection">{{$t('assistant.add_new')}}</div>
                        </div>
                        <div v-if="spotlight.is_member && spotlight.connections.length>0" class="fit-width assistant-section">
                            <div class="purpose-button assistant-button-filled" v-on:click="seeConnections()">
                                <b>{{$tc('assistant.num_opportunities',spotlight.connections.length, {num: spotlight.connections.length})}}</b><br/>
                                <span>{{$t('assistant.were_noted')}}</span>
                            </div>
                        </div>
                    </b-collapse>
                </div>
                <div v-show="showAttendance==0 && showPast==0 && assistant.member_id>0">
                    <div v-b-toggle:register-guests >
                        <div class="assistant-section-header">
                            <img src="../assets/icon-MA-Guest.png" /> <span>{{$t('assistant.register_guests')}}</span> <span></span> <div></div>
                        </div>
                    </div>
                    <b-collapse @show="showGuests" id="register-guests" accordion="sections" :visible="currentTab == 8">
                        <div v-if="!addingGuest" id="guests-wrapper">
                            <div>{{$t('assistant.registered_guests')}}</div>
                            <div v-for="g in guests" :key="g.id" v-on:click="editGuest(g)" class="clickable guest-info">
                                <div><b>{{g.name}}</b></div>
                                <div><i>{{g.company}}</i></div>
                                <div><b>{{g.day? $formatDate(new Date("" + g.day.split("-").join("/") + " 00:01"))+" @ "+g.meet_start : ""}}</b></div>
                            </div>
                            <b-button class="action-button auto-margin" v-on:click="newGuest" variant="clear-blue"><div class="new-button"></div><span>{{$t('global.button_new')}}</span></b-button>
                        </div>
                       <div v-if="addingGuest">
                            <div v-for="a in upcomingMeetings" :key="a.id" >
                                <div class="attendance-header" v-on:click="a.collapse2 = (1-a.collapse2)" :style="{'padding-bottom': a.collapse2==1? '0px' : '', 'background-color': a.collapse2==1? 'white' : '', border: a.collapse2==1? 'none' : '', color: a.collapse2==1? 'black' : '', 'grid-template-columns': '65px max-content auto 32px'}">
                                    <div class="upcoming-meeting-info">
                                        <span class="upcoming-meeting-day">{{a.day? $formatDate(new Date("" + a.day.split("-").join("/") + " 00:01"), 'day_month_long') : ""}}</span>
                                        <span class="upcoming-meeting-start">{{a.meet_start}}</span>
                                        <span class="upcoming-meeting-type">{{a.meeting_mode==0? $t('general.hybrid_text') : a.meeting_mode==1? $t('general.online_text') : $t('general.inperson_text')}}</span>
                                    </div>
                                    <div v-if="!event_chapter && a.name != null" class="circular-member circular-member-image-speaker" :style="{'background-image': 'url('+a.photo+')'}"></div>
                                    <b v-if="!event_chapter && a.name != null" >{{a.name}}</b>
                                    <i v-if="!event_chapter && a.name != null" >{{a.company}}</i>
                                    <div v-if="!event_chapter && a.name == null" class="speaker-break"></div>
                                    <div v-if="!event_chapter && a.name == null">{{$t('assistant.speaker_tbd')}}</div>
                                    <div v-if="!event_chapter && a.name == null"></div>
                                    <div v-if="event_chapter" class="event-photo" :style="{'background-image': 'url('+a.event_photo+')'}"></div>
                                    <div v-if="event_chapter" class="event-title">{{a.title}}</div>
                                    <div v-if="event_chapter"></div>
                                    <div class="section-arrow fourth-column" :class="{'up-section-arrow' : a.collapse2==1, 'down-section-arrow': a.collapse2==0}"></div>
                                </div>
                                <b-collapse accordion="attendees" v-model="a.collapse2">
                                    <div class="date-actions-box">
                                        <div class="event-links-wrapper">
                                            <div></div>
                                            <div class="clickable event-links-button" v-on:click="goToEventLinks(a)">
                                                <img src="../assets/AddToCalendarWhite.png" />
                                            </div>
                                        </div>
                                        <div v-if="!event_chapter && a.name != null" class="fit-width topic-padding">
                                            {{a.topic}}
                                        </div>
                                        <div class="purpose-button assistant-button-filled register-guest-button" v-on:click="registerGuest(a)"><b>{{$t('assistant.register_guest')}}</b></div>
                                    </div>
                                </b-collapse>
                            </div>
                        </div>
                    </b-collapse>
                </div>
                <div v-show="showAttendance==0 && showPast==0">
                    <div v-b-toggle:wrapup-section >
                        <div class="assistant-section-header">
                            <img src="../assets/icon-MA-WrapUp.png" /> <span>{{event_chapter? $t('assistant.event_wrapup'):$t('assistant.meeting_wrapup')}}</span> <span></span> <div></div>
                        </div>
                    </div>
                    <b-collapse @show="openWrapUp" id="wrapup-section" accordion="sections" :visible="currentTab == 7">
                        <div v-if="assistant.submitted==0 && !past" class="fit-width assistant-section">
                            <div class="center-text" >{{$t('assistant.feedback_text')}}:</div>
                            <b-form-textarea v-model="assistant.feedback" :rows="6" :placeholder="$t('assistant.feedback_placeholder')" class="assistant-textarea"/>
                            <b-form-checkbox v-if="assistant.guest==1" class="center-text" switch size="lg" v-model="assistant.contact" ><span id="please-contact">{{$t('assistant.please_contact')}}</span></b-form-checkbox>
                            <div class="purpose-button assistant-button-filled" id="submit-comments" v-on:click="submitFeedback">{{$t('assistant.submit_comments')}}</div>
                        </div>
                        <div v-if="assistant.submitted==1 && !otherAsksShown && !past" class="fit-width assistant-section">
                            <div class="center-text" >{{$t('assistant.review_asks')}}</div>
                            <div class="fit-width grid-fit-2column auto-margin">
                                <div class="purpose-button assistant-button-filled review-button" v-on:click="otherAsksShown=true;">{{$t('global.button_no')}}</div>
                                <div class="purpose-button assistant-button-filled review-button" v-b-toggle:you-help-section>{{$t('global.button_yes')}}</div>
                            </div>
                        </div>
                        <div v-if="!past && assistant.submitted==1 && otherAsksShown" class="fit-width assistant-section">
                            <div class="center-text" v-html="$t('assistant.thank_meeting')"></div>
                        </div>
                        <div v-if="past" class="fit-width assistant-section">
                            <div class="center-text" v-html="$t('assistant.past_meeting')"></div>
                        </div>
                    </b-collapse>
                </div>
                <div v-show="showAttendance==0 && assistant.guest ==0">
                     <div v-b-toggle:past-section >
                        <div class="assistant-section-header" >
                            <img src="../assets/icon-MA-Attendance.png" /> <span>{{event_chapter? $t('assistant.event_attendees'):$t('assistant.past_attendees')}}</span><span></span> <div :class="{'section-header-close': showPast}"></div>
                        </div>
                    </div>
                    <b-collapse @show="openAttendance" id="past-section" accordion="sections" v-model="showPast" :visible="currentTab == 2">
                        <div class="assistant-section no-padding-section">
                            <div id="past-attendance-search" class="fit-width grid-fit-2column ">
                                <span class="form-box-name">{{$t('assistant.name_text')}}</span><b-form-input  v-model="pastSearch.name" type="text" pattern="[^<>]*" :title="$t('global.pattern_title')"></b-form-input>
                                <span class="form-box-name">{{$t('assistant.from_date')}}</span><DatePicker :state="pastSearch.from_date== null? false : null" class="input-vmargins" v-model="pastSearch.from_date" />
                                <span class="form-box-name">{{$t('assistant.to_date')}}</span><DatePicker :state="pastSearch.to_date== null || pastSearch.from_date> pastSearch.to_date? false : null"  class="input-vmargins" v-model="pastSearch.to_date" />
                                <b-button id="search-past-button" class="action-button" @click="searchPast()" :disabled="pastSearch.from_date==null || pastSearch.to_date == null || pastSearch.from_date> pastSearch.to_date" variant="clear-blue"><div class="search-button"></div><span>{{$t('assistant.search_button')}}</span>
                                </b-button>
                            </div>
                            <div v-for="guest in pastGuests" :key="guest.id" >
                                <div class="attendance-header" v-on:click="guest.collapse = 1-guest.collapse" :style="{'grid-template-columns':'max-content auto auto 32px','background-color': guest.collapse==1? 'white' : '', border: guest.collapse==1? 'none' : '', color: guest.collapse==1? 'black' : ''}">
                                    <div class="circular-member circular-member-image" :style="{'background-image': 'url('+guest.photo+')'}">
                                        <div class="guest-banner guest-text" v-if="guest.guest==1">{{$t('assistant.guest_text')}}</div>
                                    </div>
                                    <b>{{guest.name}}</b>
                                    <i class="second-column">{{guest.company}}</i>
                                    <b class="third-column column-span2 auto-margin">{{guest.day}}</b>
                                    <div class="section-arrow fourth-column" :class="{'up-section-arrow' : guest.collapse==1, 'down-section-arrow': guest.collapse==0}"></div>
                                </div>
                                <b-collapse accordion="attendees" v-model="guest.collapse" v-on:hidden="guest.reinvite=false">
                                    <div class="fit-width padded-center">
                                        <div class="fit-width grid-fit-2column padded-center">
                                            <img v-if="guest.linkedin_url && guest.linkedin_url.length>0" class="clickable linkedin-image" v-on:click="openLinkedin(guest);" src="../assets/LinkedIn.png"/>
                                            <div v-if="!(guest.linkedin_url && guest.linkedin_url.length>0)" class="row-span2"></div>
                                            <a :href="'mailto:'+guest.email">{{guest.email}}</a>
                                            <a :href="'tel:'+guest.phone">{{guest.phone}}</a>
                                        </div>
                                        <div id="past-guest-options" class="fit-content grid-fit-2column auto-margin">
                                            <div class="purpose-button assistant-button-filled past-guest-button" v-on:click="makeVCard(guest)"><b>{{$t('assistant.download_vcard')}}</b></div>
                                            <div class="purpose-button assistant-button-filled past-guest-button" v-if="upcomingMeetings.length>0 && (guest.reinvite==undefined || !guest.reinvite)" v-on:click="showReinvite(guest)"><b>{{$t('assistant.re_invite')}}</b></div>
                                        </div>
                                    </div>
                                    <div v-if="guest.reinvite">
                                            <div v-for="a in upcomingMeetings" :key="a.id" >
                                                <div class="attendance-header" v-on:click="a.collapse2 = (1-a.collapse2)" :style="{'padding-bottom': a.collapse2==1? '0px' : '', 'background-color': a.collapse2==1? 'white' : '', border: a.collapse2==1? 'none' : '', color: a.collapse2==1? 'black' : '', 'grid-template-columns': '65px max-content auto 32px'}">
                                                    <div class="upcoming-meeting-info">
                                                        <span class="upcoming-meeting-day">{{a.day? $formatDate(new Date("" + a.day.split("-").join("/") + " 00:01"), 'day_month_long') : ""}}</span>
                                                        <span class="upcoming-meeting-start">{{a.meet_start}}</span>
                                                        <span class="upcoming-meeting-type">{{a.meeting_mode==0? $t('general.hybrid_text') : a.meeting_mode==1? $t('general.online_text') : $t('general.inperson_text')}}</span>
                                                    </div>
                                                    <div v-if="!event_chapter && a.name != null" class="circular-member circular-member-image-speaker" :style="{'background-image': 'url('+a.photo+')'}"></div>
                                                    <b v-if="!event_chapter && a.name != null" >{{a.name}}</b>
                                                    <i v-if="!event_chapter && a.name != null" >{{a.company}}</i>
                                                    <div v-if="!event_chapter && a.name == null" class="speaker-break"></div>
                                                    <div v-if="!event_chapter && a.name == null">{{$t('assistant.speaker_tbd')}}</div>
                                                    <div v-if="!event_chapter && a.name == null"></div>
                                                    <div v-if="event_chapter" class="event-photo" :style="{'background-image': 'url('+a.event_photo+')'}"></div>
                                                    <div v-if="event_chapter" class="event-title">{{a.title}}</div>
                                                    <div v-if="event_chapter"></div>
                                                    <div class="section-arrow fourth-column" :class="{'up-section-arrow' : a.collapse2==1, 'down-section-arrow': a.collapse2==0}"></div>
                                                </div>
                                                <b-collapse accordion="reinvite_guests" v-model="a.collapse2">
                                                    <div class="date-actions-box">
                                                        <div class="event-links-wrapper">
                                                            <div></div>
                                                            <div class="clickable event-links-button" v-on:click="goToEventLinks(a)">
                                                                <img src="../assets/AddToCalendarWhite.png" />
                                                            </div>
                                                        </div>
                                                        <div v-if="!event_chapter && a.name != null" class="fit-width topic-padding">
                                                            {{a.topic}}
                                                        </div>
                                                        <div class="purpose-button assistant-button-filled register-guest-button" v-on:click="reinviteGuest(a,guest)"><b>{{$t('assistant.re_invite')}}</b></div>
                                                    </div>
                                                </b-collapse>
                                            </div>
                                        </div>
                                </b-collapse>
                            </div>
                        </div>
                    </b-collapse>
                </div>
                </div>
                <div id="bottom-logo">
                    <img src="../assets/PoweredByMeetingHUB.png" />
                </div>
            </div>
        </transition-group>
    </div>
    <b-modal id="modal-center" v-model="showGuestRegistered" centered>
        <p class="my-4" v-html="$t('assistant.guest_registered')"></p>
        <div slot="modal-footer" class="submit-box-2">
            <b-button class="action-button" v-on:click="showGuestRegistered=false" variant="clear-blue"><span>{{$t('global.button_ok')}}</span></b-button>
        </div>
    </b-modal>
  </div>
</div>
</template>
<style scoped>
.past-guest-button{
    width:200px !important;
}
#past-guest-options{
    grid-gap:20px;
}
#search-past-button{
    grid-column:1/3;margin:auto;
}
#past-attendance-search{
    margin:10px auto;
}
#submit-comments{
    padding:10px;font-weight:bold;margin:10px auto;
}
#please-contact{
    font-size:12px;
}
.review-button{
    padding:10px;font-weight:bold;width:55px;
}
.guest-info{
    border: 2px solid #FFD700; border-radius: 10px;border-radius: 10px;width: 300px;margin: 10px auto;padding:5px;
}
#guests-wrapper{
    padding:10px; text-align:center;
}
.connection-text{
    padding:10px;font-weight:bold;
}
.spotlight-name-wrapper{
    line-height:100%;
}
.pre-other-ask{
    font-family: Avenir,Helvetica,Arial,sans-serif;font-size:100%;
}
.other-ask-wrapper{
    width:300px;border-radius:15px;border:1px solid gray; margin: auto;height:150px;overflow: hidden;padding-left: 5px;
}
.other-ask-wrapper > div{
    overflow-y:auto;height:100%;
}
.other-ask-member-image{
    grid-row:1/3;width:50px;height:50px;margin-right:10px;
}
.other-ask-member-info{
    display:grid; grid-template-columns: 60px 240px;width:300px; margin:auto;padding:5px;
}
#other-asks-wrapper{
    position:relative; width:100%;height:250px;
}
.pre-ask{
    font-family: Avenir,Helvetica,Arial,sans-serif;font-size:100%;width: 300px;max-height: 150px;
}
.unseen-helpers{
    position: absolute;right: -10px;top: -20px;
}
.ask-box{
    width:300px;border-radius:15px;border:1px solid gray; margin: auto;height:150px;padding-left: 5px;
}
.ask-box > div{
    height:100%;position: relative;
}
.assistant-ask{
    width:100%;position:absolute;
}
#asks-wrapper{
    position:relative; width:100%;height:175px;
}
.no-overflow-section{
    overflow:hidden; width:100%;
}
#details-unavailable{
    text-align:center; padding:15px;
}
#map-button{
    width:120px;margin: 10px auto;
}
.in-person-location-image{
    width:50px; margin:10px;filter: brightness(0) invert(32%) sepia(67%) saturate(595%) hue-rotate(163deg) brightness(94%) contrast(88%);
}
.in-person-location{
    text-align:left; font-family: Avenir,Helvetica,Arial,sans-serif;font-size:100%;
}
.in-person-location-box{
    margin: 20px;color: #24679A; display:grid; grid-template-columns: max-content auto;
}
.zoom-option-button{
    width: 120px !important;
}
.zoom-icon{
    width:200px;margin-bottom: 30px;margin-top:10px;
}
.topic-padding{
    padding: 20px;
}
.meeting-type-text{
    background-color: #E8DBBE;padding:10px;
}
.meeting-type-box{
    text-align:center;border: 1px solid #E8DBBE;margin: 10px auto;width:300px;
}
.date-actions-box{
    margin:auto;padding-left:5px;padding-top:0px;width:100%;height:150px;display:grid;grid-template-columns: 65px auto;
}
.event-links-wrapper{
    width:65px;height:100%;background-color:#424143;grid-row: 1/3;display: grid;grid-template-rows: auto max-content;
}
.event-links-button{
    border: 2px solid rgb(255, 215, 0);padding: 7px;width: 50px;border-radius: 10px;margin: auto;margin-bottom: 5px;
}
.event-links-button > img{
    width:30px;margin: auto;display: block;
}
.register-guest-button{
    margin:auto !important;width:200px !important;grid-column: 2 !important;margin-left: calc(50% - 137px) !important;
}
.event-title{
    line-height:50px;
}
.event-photo{
    margin-left:10px;grid-row:1/3;width:100px;height:50px;position:relative;margin-right:20px;background-repeat: no-repeat;background-size: 100%;background-position: 50%;
}
.speaker-break{
    width:50px;grid-row:1/3;
}
.upcoming-meeting-type{
    font-size:10px;color:white;
}
.upcoming-meeting-start{
    font-size:10px;font-weight: bold;color:white;
}
.upcoming-meeting-day{
    color:#FFD700;font-weight:bold;font-size:14px;
}
.upcoming-meeting-info{
    line-height: 14px;grid-row:1/3;padding:3px 0px;width:65px;background-color:#424143;display:grid;grid-template-rows: auto auto auto;text-align:center;
}
.assistant-textarea{
    min-width:300px;border-radius:15px;
}
#connection-type{
    display: grid;grid-template-columns: repeat(3,100px);font-size: 12px;
}
.connection-input{
    margin:10px 0;
}
.spotlight-name{
    font-weight:normal;font-size: 16px;
}
#attendess-can-help{
    margin:auto;padding:15px 5px;
}
.help-box{
    width: 300px;margin: auto; margin-top: 20px;text-align: center;font-size:12px;
}
.assistant-margin-top20{
    margin-top:20px;
}
.spotlight-connection{
    margin:10px; border: 1px solid black;padding:5px;border-radius:10px;
}
.no-padding-section{
    padding:0px; position:relative;
}
.guest-text{
    background-color:#424143;
}
#bottom-logo{
    height:52px;padding:10px;
}
#bottom-logo > img{
    height:32px; margin:auto;display:block;
}
.vcard-button{
    margin:auto;width:200px;
}
.circular-member-image-speaker{
    margin-left:10px;
}
.circular-member-image, .circular-member-image-speaker{
    grid-row:1/3;width:50px;height:50px;position:relative;margin-right:20px;
}
.linkedin-image{
    grid-row:1/3;width:50px;height:50px;margin-right:20px;
}
.attendee-helper-header{
    background-color: white;
    border: none; color: black;
}
.attendee-helper{
    border-bottom: 2px solid black;
}
.padded-center{
    margin:auto;padding:20px;
}
#guest-update-button{
    margin: 10px auto;
}
.assistant-alert{
    margin-top:10px;
}
#guest-info-box{
    position:relative;display: grid;grid-gap: 10px;padding: 20px;text-align: center;margin: auto;width:340px;
}
.guest-input{
    max-width: 400px;
}
#link-event-org{
    color:#FFD700;font-size: 20px;
}
#select-calendar{
    margin-top:30px;text-align:center;font-size:14px;
}
#link-event-type{
    font-size:10px;
}
#link-event-start{
    font-size:14px;
}
#link-event-day{
    font-size:16px;
}
#link-event-info{
    text-align:left;padding:20px;
}
#link-event-wrapper{
    display:grid;
}
.end-text{
    background-color:#E8DBBE;padding: 20px;text-align:center;
}
.end-logo{
    height:85px;padding:10px;
}
.end-logo > img{
    height:65px; margin:auto;display:block;
}
#assistant-main-view{
    position:relative;min-height: calc(100% - 120px);
}
#attendance-top-buffer{
    height:62px;
}
#switch-language-button{
    margin: auto;color:white; border-radius: 12px; border: #FFD700 2px solid;width: 55px;text-align: center;font-weight: bold;
}
#assistant-type-text{
    color:#E8DBBE;text-align:center;margin: auto 0;font-size: 20px;text-align: center;
}
#assistant-username{
    grid-column: 1/3;color:white;text-align:left;
}
#assistant-start{
    color:#E8DBBE;font-size:14px;
}
#assistant-day{
    color:#E8DBBE;line-height: 90%;
}
#assistant-org-name{
    color:#FFD700;font-size: 20px;
}
#assistant-header-info{
    margin: auto 30px;grid-row:1/3;
}
#assistant-logo{
    height:75px;width:63px;grid-row:1/3;margin:auto;
}
#assistant-header{
    background-color:#424143; display:grid; grid-template-columns: 63px auto 100px;padding:10px;height:120px;width:100%;
}
#inner-assistant-wrapper{
    height:100%;width:100%;background-color: white;max-width:600px; margin:auto;overflow-x:hidden
}
#assistant-wrapper{
    background-color:#424143;height:100%;width:100%;
}
.full-assistant-height{
    min-height: calc(100% - 85px)
}
.dark-blue-section-header{
    background-color: #24679A;
}
.full-area-absolute{
    position:absolute;width:100%;height:100%;
}
.calendar-option{
    display:grid;
    grid-template-columns: 50px auto;
    width:100%;
    border: 1px solid #424143;
    background-color: #98989A;
    color: white;
    padding:10px;
}
.calendar-option > img{
    width:40px;
}
.relation-button{
    width:90px !important; margin:auto !important;padding:15px 5px !important;
}
.unseen-number{
    /*margin-left:20px;*/
    background-color: #FFD700;
    border-radius: 50%;
    padding: 3px;
    font-size: 16px;
    width: 30px;
    height: 30px;
    text-align: center;
    line-height: 24px;
    display: inline-block;
        border: 1px black solid;
    color: black;
}
.guest-banner{
    font-size: 9px;
    text-align: center;
    bottom: 0px;
    position: absolute;
    width: 100%;
    color:white;
}
.up-section-arrow{
    background-image: url('../assets/ChevronUp-Brn.png');
    /*filter:brightness(0);*/
}

.down-section-arrow{
    background-image: url('../assets/ChevronDn-Brn.png');
    /*filter:brightness(0);*/
}
.attendance-header{
    display:grid; grid-template-columns: max-content auto 32px;width:100%; margin:auto;padding:5px;
    border: 1px solid #424143;
    background-color: #98989A;
    cursor: pointer;
    font-size: 20px;
    color:white;
}
.section-arrow{
    grid-row: 1/3;
    grid-column: 3;
}
.assistant-button-filled{
    /*background-color:#E8DBBE;*/
    border: 2px solid #FFD700 !important;
}
.purpose-button{
    border: 1px solid lightgray;
    border-radius: 10px;
    color:#424143;
    cursor: pointer;
    padding: 5px;
    margin: 10px;
    width: 300px;
    text-align: center;
    color:#24679A;
}
/*.purpose-button:hover{
    background-color:#E8DBBE;
}*/
.assistant-section{
    margin:auto;
    padding:10px;
}
.assistant-section-header{
    display:grid;
    grid-template-columns: max-content minmax(auto,max-content) minmax(max-content, auto) 60px;
    overflow-anchor: none;
    padding: 10px;
    border: 1px solid #424143;
    color: white;
    font-weight: bold;
    grid-gap:10px;
    background-color: #98989A;
}

.assistant-section-header > img{
    width: 40px;
}
.assistant-section-header > span{
    margin: auto 0;
    font-size: 19px;
}
.assistant-section-header > div, .section-arrow{
    margin: auto;
    width: 30px;
    height: 22px;
    background-repeat: no-repeat;
    background-size: 100% 100%;
}
.righttolefttrans-enter-active{
    transition: all 0.5s ease-in;
} 
.righttolefttrans-leave-active {
    transition: all 0.5s ease-in;
    
  }
  .righttolefttrans-enter {
    transform: translateX(100%);
  }
  .righttolefttrans-leave-to {
    transform: translateX(-100%);
  }
  .lefttoright-enter-active, .lefttoright-leave-active {
    transition: all 0.5s ease-in;
  }
  .lefttoright-enter /* .fade-leave-active below version 2.1.8 */ {
    transform: translateX(-100%);
  }
  .lefttoright-leave-to {
    transform: translateX(100%);
  }
</style>
<script>
const ics = require('ics');
import Header from './Header.vue';
import DatePicker from './common/DatePicker.vue';
    export default {
  components: { Header, DatePicker },
    name: "MeetingAssistant",
      data() {
        return {
            attendanceTypeOptions: [{value: 0, text: this.$t('assistant.attendance_inperson')},{value: 1, text: this.$t('assistant.attendance_virtual')}],
            pastSearch: {to_date: null, from_date: null, name: ""},
            showPast: false,
            pastGuests: [],
            upcomingMeetings: [],
            upcomingDates: false,
            past: false,
            showOtherAsks: false,
            showAttendance: false,
            showDelete: false,
            showAlert: false,
            showView: 1,
            transition_name: "righttolefttrans",
            transition_type: "righttolefttrans",
            showAsk: null,
            slide: 0,
            assistant: {id: 0, member_id: 0, day: "", purpose: 0, ask: "", feedback: "", contact: false, submitted: 0, name: "", company: "", guest: 1, org_name: "", asks: [] },
            ask: {id: 0, ask: ''},
            other_asks: [],
            spotlight: {name: "", connections: [], is_member:false},
            linkuid: "",
            otherAsksShown:false,
            connection: {id: 0, name: "", company: "", relationship: 0, info: ""},
            meeting: {meeting_mode: 0, meeting_link: ""},
            attendance: [],
            client: {},
            id_string: "",
            ask: null,
            currentTab: 4,
            languageOptions: [],
            linkEvent: null,
            addingGuest: false,
            guest: {},
            guests: [],
            showGuestRegistered: false,
            event_chapter: false,
        }
      },
      sockets: {
        other_asks(data) {
            if(data.deleted){
                this.other_asks = this.other_asks.filter(v => v.id != data.other_ask.id);
                for(var ask of this.other_asks){
                    ask.canHelp = ask.canHelp==1;
                }
            }else if(data.other_ask && data.other_ask.id>0){
                var found = false;
                for(var j=0; j<this.other_asks.length; j++){
                    if(this.other_asks[j].id == data.other_ask.id){
                        found = true;
                        if(this.other_asks[j].ask != data.other_ask.ask){
                            this.other_asks[j].ask = data.other_ask.ask;
                            this.other_asks[j].unseen = !(this.showOtherAsks && this.showAsk==this.other_ask);
                        }
                        break;
                    }
                }
                this.other_asks = this.other_asks.filter(v => v.ask && v.ask.length>0);
                for(var ask of this.other_asks){
                    ask.canHelp = ask.canHelp==1;
                }
                if(!found){
                    data.other_ask.unseen = true;
                    this.other_asks.push(data.other_ask);
                }
            }
            
            if(!this.other_asks.includes(this.showAsk)){
                this.showAsk = null;
            }
            if(this.showOtherAsks && this.showAsk==null && this.other_asks.length>0){
                this.showAsk = this.other_asks[0];
                this.showAsk.unseen = false;
            }
        },
        speaker_connection(data){
            if(data.deleted){
                this.spotlight.connections = this.spotlight.connections.filter(v => v.id != data.connection.id);
            }else if(data.connection && data.connection.id>0){
                var found = false;
                for(var j=0; j<this.other_asks.length; j++){
                    if(this.spotlight.connections[j].id == data.connection.id){
                        found = true;
                        this.spotlight.connections[j] = data.connection;
                        this.spotlight.connections[j].unseen = (this.showView!=5);
                        break;
                    }
                }
                if(!found){
                    data.connection.unseen = (this.showView!=5);
                    this.spotlight.connections.push(data.connection);
                }
            }
        },
        ask_help(data){
            for(var i=0; i<this.assistant.asks.length; i++){
                if(this.assistant.asks[i].id == data.ask_id){
                    if(data.canHelp && !this.assistant.asks[i].helpers.includes(data.assistant_id)){
                        this.assistant.asks[i].helpers.push(data.assistant_id);
                        this.assistant.asks[i].unseen_helpers.push(data.assistant_id);
                    }else if(!data.canHelp){
                        this.assistant.asks[i].helpers = this.assistant.asks[i].helpers.filter(v => v!= data.assistant_id);
                        this.assistant.asks[i].unseen_helpers = this.assistant.asks[i].unseen_helpers.filter(v => v!= data.assistant_id);
                    }
                }
            }
        },
        connect(){
            this.$socket.emit("register_assistant",{ id_string : this.id_string });
        }
      },
    methods: {
        showReinvite(a){
            this.$set(a, 'reinvite', true);
        },
        searchPast(){
            this.pastSearch.linkuid = this.linkuid;
            this.$http.post("/api/organization/assistant/past/guests", this.pastSearch).then((response) => {
                if (response.data.success === true) {
                    this.pastGuests = response.data.guests;
                }else{
                    this.pastGuests=[];
                }
            }).catch((errors) => {this.pastGuests=[];console.log(errors);});
        },
        editGuest(g){
            this.guest = g;
            this.showView = 9;
        },
        reinviteGuest(a,g){
            this.guest = {
                arrival: a.day+" "+a.raw_start, update_time: a.day+" "+a.raw_start, linked_member: "", day: a.day, meet_start: a.meet_start, pref_lang: g.pref_lang || null,
                name: g? g.name: "", email: g? g.email: "", id: 0, phone: g? g.phone: "", website: g? g.website: "", profession: g? g.category: "", subscribed: 0, present: 0, preferred_contact: 0,
                company: g? g.company: "", member_id: this.assistant.member_id, deleted: 0, type: 3, payment: 0, photo_verification: 0, photo: null, photo_small: null, linkedin_url: g? g.linkedin_url: "",
                inperson_or_virtual: g? g.inperson_or_virtual: 0
            };
            this.updateGuest(false);
            g.reinvite = false;
        },
        updateGuest(check){
            if(check){
                this.showAlert = false;
                if(this.guest.name.length==0 || this.guest.company.length==0 || this.guest.phone.length==0 || this.guest.email.length==0){
                    this.showAlert = true;
                    return;
                }
            }
            this.guest.linkuid = this.linkuid;
            this.$http.post("/api/organization/assistant/guest/update", this.guest).then((response) => {
                if (response.data.success === true) {
                    if(!(this.guest.id>0)){
                        this.guest.id = response.data.inserted_id;
                        this.guests.push(this.guest);
                    }
                    this.showGuestRegistered = true;
                    this.showView = 1;
                }
            }).catch((errors) => {console.log(errors);});
        },
        newGuest(){
            this.addingGuest = true;
            if(this.upcomingMeetings.length>0){
                this.upcomingMeetings[0].collapse = 1;
            }
        },
        showGuests(){
            this.addingGuest = this.guests.length==0;
            if(this.upcomingMeetings.length>0){
                this.upcomingMeetings[0].collapse = 1;
            }
            this.currentTab = 8;
        },
        registerGuest(a, g){
            this.guest = {
                arrival: a.day+" "+a.raw_start, update_time: a.day+" "+a.raw_start, linked_member: "", day: a.day, meet_start: a.meet_start, pref_lang: g.pref_lang || null,
                name: g? g.name: "", email: g? g.email: "", id: 0, phone: g? g.phone: "", website: g? g.website: "", profession: g? g.category: "", subscribed: 0, present: 0, preferred_contact: 0,
                company: g? g.company: "", member_id: this.assistant.member_id, deleted: 0, type: 3, payment: 0, photo_verification: 0, photo: null, photo_small: null, linkedin_url: g? g.linkedin_url: "",
                inperson_or_virtual: g? g.inperson_or_virtual: 0
            };
            this.showView = 9;
            this.addingGuest = false;
        },
        goToEventLinks(e){
            this.showView = 8;
            this.linkEvent = e;
        },
        switchLanguage(){
            var i=0;
            for(i=0; i<this.languageOptions.length; i++){
                if(this.permissions.organization.language==this.languageOptions[i].locale){
                    break;
                }
            }
            var nextLanguage = this.languageOptions[(i+1)%this.languageOptions.length].locale;
            this.loadLanguageAsync(nextLanguage);
        },
        seeConnections(){
            this.showView = 5;
            for(var i=0; i<this.spotlight.connections.length; i++){
                this.spotlight.connections[i].unseen = 0;
            }
        },
        openLinkedin(m){
            window.open((m.linkedin_url.startsWith("http") ? "" : "http://") + m.linkedin_url);
        },
        sendLinkEmail(id){
            this.$http.post("/api/organization/assistant/emaillink", {linkuid: this.linkuid, id: id}).then((response) => {
                if(response.data.success){
                   this.$bvToast.toast(this.$t('assistant.email_sent'), {
                        variant: 'success',
                        solid: true
                    });
                }else{
                    this.$bvToast.toast(this.$t('assistant.email_not_sent'), {
                        variant: 'warning',
                        solid: true
                    });
                }
            }).catch(() => {
            });
        },
        openMap(l){
            window.open("http://maps.google.com/?q="+l);
        },
        openZoom(l){
            window.open((l.startsWith("http") ? "" : "http://") + l);
        },
        submitFeedback(){
            this.updateFeedback();
        },
        updateConnection(){
            this.showAlert = false;
            if((this.connection.id>0 && this.connection.name.length==0) ||
             (this.connection.name.length==0 && (this.connection.relationship!=0 || this.connection.company.length>0 || this.connection.info.length>0))){
                this.showAlert = true;
                return;
            }else if(this.connection.name.length==0){
                this.transition_name = "lefttoright";
                this.showView = 1;
                return;
            }
            this.connection.linkuid = this.linkuid;
            this.$http.post("/api/organization/assistant/connection/update", this.connection).then((response) => {
                if(response.data.success){
                    this.$socket.emit("speaker_connection", {id_string : this.id_string, linkuid: this.linkuid, connection_id: response.data.id, deleted:false})
                    this.getSpotlight();
                    this.transition_name = "lefttoright";
                    this.showView = 1;
                }
            }).catch(() => {
            });
            
        },
        deleteConnection(){
            this.showAlert = false;
            if(this.connection.id>0){
                this.connection.linkuid = this.linkuid;
                this.showDelete = false;
                this.$http.post("/api/organization/assistant/connection/delete", this.connection).then((response) => {
                    if(response.data.success){
                        this.getSpotlight();
                        this.transition_name = "lefttoright";
                        this.showView = 1;
                        this.$socket.emit("speaker_connection", {id_string : this.id_string, linkuid: this.linkuid, connection_id: this.connection.id, deleted:true})
                    }
                }).catch(() => {
                });
            }else{
                this.transition_name = "lefttoright";
                this.showView = 1;
            }
            
        },
        editConnection(c){
            this.transition_name = "righttolefttrans";
            this.showView = 2;
            this.connection = c;
        },
        newConnection(){
            this.transition_name = "righttolefttrans";
            this.showView = 2;
            this.connection = {id: 0, name: "", company: "", relationship: 0, info: ""};
        },
        goToOtherAsk(o){
            var n = this.other_asks.indexOf(this.showAsk);
            var n2 = this.other_asks.indexOf(o);
            this.transition_type = n>n2? "lefttoright" : "righttolefttrans" ;
            this.showAsk = o;
            this.showAsk.unseen = false;
        },
        prevOtherAsk(){
           var n = this.other_asks.indexOf(this.showAsk);
           this.transition_type = "righttolefttrans";
           if(n>0){
               this.showAsk = this.other_asks[n-1];
           }else if (n==0){
               this.showAsk = this.other_asks[this.other_asks.length-1];
           }
           this.showAsk.unseen = false;
        },
        nextOtherAsk(){
            var n = this.other_asks.indexOf(this.showAsk);
            this.transition_type = "lefttoright";
           if(n<this.other_asks.length-1){
               this.showAsk = this.other_asks[n+1];
           }else if (n==this.other_asks.length-1){
               this.showAsk = this.other_asks[0];
           }
           this.showAsk.unseen = false;
        },
        setPurpose(n){
            this.assistant.purpose = n;
            this.updatePurpose();
        },
        updateOtherAsk(a){
            this.$http.post("/api/organization/assistant/otherasks/update", {linkuid: this.linkuid, recipient_id: a.id, canHelp: a.canHelp}).then((response) => {
                if(response.data.success){
                    this.$socket.emit("ask_help", {id_string : this.id_string, linkuid: this.linkuid, ask_id: a.id, assistant_id: this.assistant.id, canHelp: a.canHelp})
                }
            }).catch(() => {
               
            });
            
        },
        openSpotlight(){
            this.currentTab = 6;
        },
        openWrapUp(){
            this.currentTab = 7;
        },
        openDetails(){
            this.currentTab = 1;
        },
        openAttendance(){
            this.currentTab = 2;
        },
        openWelcome(){
            this.currentTab = 3;
        },
        openOtherAsks(){
            this.currentTab = 5;
            this.otherAsksShown = true;
             if(this.showAsk==null && this.other_asks.length>0){
                this.showAsk = this.other_asks[0];
            }
             if(this.showAsk!=null){
                 this.showAsk.unseen = false;
             }
        },
        openAsks(){
            this.currentTab = 4;
             if(this.ask==null && this.assistant.asks.length>0){
                this.ask = this.assistant.asks[0];
            }
        },
        getOtherAsks(){
            this.$http.post("/api/organization/assistant/otherasks/get", {linkuid: this.linkuid}).then((response) => {
                if(response.data.success){
                    for(var i=0; i<response.data.otherasks.length; i++){
                        response.data.otherasks[i].unseen = true;
                    }
                    this.other_asks = response.data.otherasks;
                    for(var ask of this.other_asks){
                        ask.canHelp = ask.canHelp==1;
                    }
                    this.unseen = this.other_asks.length;
                }
            }).catch(() => {
            });
            
        },
        getSpotlight(){
             this.$http.post("/api/organization/assistant/spotlight/get", {linkuid: this.linkuid}).then((response) => {
                if(response.data.success){
                    this.spotlight= response.data.spotlight;
                }
            }).catch(() => {
            });
        },
        updateFeedback(){
            this.assistant.linkuid = this.linkuid;
            this.$http.post("/api/organization/assistant/update/feedback", this.assistant).then((response) => {
                if(response.data.success){
                    this.assistant.submitted = 1;
                    /*this.$bvToast.toast(this.$t('assistant.feedback_submitted'), {
                        variant: 'success',
                        solid: true
                    });*/
                }
            }).catch(() => {
            });
        },
        newAsk(){
            this.ask = {id: 0, ask: ""};
            this.showView = 3;
        },
        showHelpers(ask){
            this.ask = ask;
            this.ask.unseen_helpers = [];
            this.showView = 4;
        },
        editAsk(ask){
            this.ask = ask;
            this.showView = 3;
        },
        updateAsk(ask){
            var init_id = ask.id;
            ask.linkuid = this.linkuid;
            this.$http.post("/api/organization/assistant/update/ask", ask).then((response) => {
                if(response.data.success){
                    ask.id = response.data.id;
                    ask.helpers = [];
                    ask.unseen_helpers = [];
                    if(init_id == 0){
                        this.assistant.asks.push(ask);
                        this.ask = ask;
                    }
                    this.showView = 1;
                    this.$socket.emit("other_asks",{ id_string : this.id_string, linkuid: this.linkuid, ask_id: ask.id, deleted: false});
                }else{
                   this.$bvToast.show('serverError');
                }
            }).catch(() => {
            });
        },
        deleteAsk(ask){
            if(ask.id==0){
                if(this.ask.id==0){
                    this.ask = this.assistant.asks.length>0? this.assistant.asks[0] : null;
                }
                this.showView = 1;
            }else{
                ask.linkuid = this.linkuid;
                this.$http.post("/api/organization/assistant/delete/ask", ask).then((response) => {
                if(response.data.success){
                    var index = this.assistant.asks.indexOf(ask);
                     if (index > -1) {
                         this.assistant.asks.splice(index,1);
                     }
                    this.ask = this.assistant.asks.length>0? this.assistant.asks[0] : null;
                    this.showView = 1;
                    this.$socket.emit("other_asks",{ id_string : this.id_string, linkuid: this.linkuid, ask_id: ask.id, deleted: true});
                }else{
                   this.$bvToast.show('serverError');
                }
            }).catch(() => {
            });
            }
        },
        goToAsk(o){
            var n = this.assistant.asks.indexOf(this.ask);
            var n2 = this.assistant.asks.indexOf(o);
            this.transition_type = n>n2? "lefttoright" : "righttolefttrans" ;
            this.ask = o;
            this.ask.unseen = false;
        },
        prevAsk(){
           var n = this.assistant.asks.indexOf(this.ask);
           this.transition_type = "righttolefttrans";
           if(n>0){
               this.ask = this.assistant.asks[n-1];
           }else if (n==0){
               this.ask = this.assistant.asks[this.assistant.asks.length-1];
           }
           this.ask.unseen = false;
        },
        nextAsk(){
            var n = this.assistant.asks.indexOf(this.ask);
            this.transition_type = "lefttoright";
           if(n<this.assistant.asks.length-1){
               this.ask = this.assistant.asks[n+1];
           }else if (n==this.assistant.asks.length-1){
               this.ask = this.assistant.asks[0];
           }
           this.ask.unseen = false;
        },
        updatePurpose(){
            this.assistant.linkuid = this.linkuid;
            this.$http.post("/api/organization/assistant/update/purpose", this.assistant).then(() => {
            }).catch(() => {
            });
        },
        getAssistant(){
            this.$http.post("/api/organization/assistant/get", {linkuid: this.linkuid}).then((response) => {
                if(response.data.success){
                    this.past = response.data.past;
                    this.assistant = response.data.assistant;
                    this.meeting = response.data.meeting;
                    this.event_chapter = response.data.meeting.event_chapter==1;
                    
                    if(!this.$cookies.isKey("language")){
                        this.setCookie("language",this.assistant.org_language);
                        this.permissions.organization.language  =this.assistant.org_language;
                    }else{
                        this.permissions.organization.language = this.getCookie("language","en");
                    }
                    this.loadLanguageAsync(this.permissions.organization.language);

                    if(this.assistant.asks.length>0){
                        this.ask = this.assistant.asks[0];
                    }
                    this.getOtherAsks(false);
                    this.getSpotlight();
                    if(this.past){
                        this.currentTab = 7;
                    }else if(this.assistant.guest==1){
                        this.currentTab = 3;
                    }else{
                        this.currentTab = 4;
                    }
                }else{
                    this.showView = 6;
                }
            }).catch(() => {this.showView = 6;
            });
        },
        getAttendanceDetails(){
            this.$http.post("/api/organization/assistant/attendance/get", {linkuid: this.linkuid}).then((response) => {
                if(response.data.success){
                    this.attendance = response.data.attendance;
                }
            }).catch(() => {
            });
        },
        getUpcomingMeetings(){
            this.$http.post("/api/organization/assistant/upcomingmeetings/get", {linkuid: this.linkuid}).then((response) => {
                if(response.data.success){
                    this.upcomingMeetings = response.data.meetings;
                }
            }).catch(() => {
            }); 
        },
       yahooCalendar(e){
           var title = this.event_chapter? e.title :this.$t('assistant.cc_meeting');
            var descript = this.event_chapter? "" :this.$t('assistant.speaker_description', {speaker: e.name != null? e.name : this.$t('assistant.speaker_tbd')});
            var day = e.day.split("-").join("");
            var start = e.raw_start.split(":").join("");
            var end = e.raw_end.split(":").join("");
            var url = 'https://calendar.yahoo.com/?desc='+descript+'&dur=&et='+day+'T'+end+'&st='+day+'T'+start+'&title='+title+'&v=60'+(e.type!=1 && e.day_location? '&in_loc='+e.day_location : '');
            window.open(url);
       },
       googleCalendar(e){
            var title = this.event_chapter? e.title :this.$t('assistant.cc_meeting');
            var descript = this.event_chapter? "" :this.$t('assistant.speaker_description', {speaker: e.name != null? e.name : this.$t('assistant.speaker_tbd')});
            var day = e.day.split("-").join("");
            var start = e.raw_start.split(":").join("");
            var end = e.raw_end.split(":").join("");
            var url = 'https://calendar.google.com/calendar/render?action=TEMPLATE&dates='+day+'T'+start+'%2F'+day+'T'+end+'&details='+descript+'&text='+title+(e.type!=1 && e.day_location? '&location='+e.day_location : '');
            window.open(url);
        },
        outlookCalendar(e, is365){
            var title = this.event_chapter? e.title : this.$t('assistant.cc_meeting');
            var descript = this.event_chapter? "" :this.$t('assistant.speaker_description', {speaker: e.name != null? e.name : this.$t('assistant.speaker_tbd')});
            var start = e.raw_start.split(":").join("%3A");
            var end = e.raw_end.split(":").join("%3A");
            var url = 'https://outlook.'+(is365? 'office': 'live')+'.com/calendar/0/deeplink/compose?allday=false&body='+descript+'&enddt='+e.day+'T'+end+'&path=%2Fcalendar%2Faction%2Fcompose&rru=addevent&startdt='+e.day+'T'+start+'&subject='+title+(e.type!=1 && e.day_location? '&location='+e.day_location : '');
            window.open(url);
        },
        isEmpty(obj) {
            for (const prop in obj) {
                if (Object.hasOwn(obj, prop)) {
                return false;
                }
            }
            
            return true;
        },
        makeICSFile(e){
            var daySplit = e.day.split("-");
            var timeSplit = e.raw_start.split(":");
            var durationSplit = e.duration.split(":");
            var start = [];
            var event = {
                title: this.event_chapter? e.title : this.$t('assistant.cc_meeting'),
                description: this.event_chapter? "" : this.$t('assistant.speaker_description', {speaker: e.name != null? e.name : this.$t('assistant.speaker_tbd')}),
            };
            if(e.meeting_mode != 2 && e.meeting_link.length>0){
                event.url = (e.meeting_link.trim().startsWith("http") ? "" : "http://") +e.meeting_link.trim();
            }
            if(e.meeting_mode != 1){
                event.location= e.day_location;
            }
            if(daySplit.length >=3){
                var year = parseInt(daySplit[0]);
                var month = parseInt(daySplit[1]);
                var day = parseInt(daySplit[2]);
                start = [year,month,day];
            }

            if(timeSplit.length>=2){
                var hour = parseInt(timeSplit[0]);
                var minute = parseInt(timeSplit[1]);
                start.push(hour);
                start.push(minute);
            }
            if(start.length>=3){
                event.start = start;
            }
            var duration = {};
            if(durationSplit.length>=2){
                var hour = parseInt(durationSplit[0]);
                var minute = parseInt(durationSplit[1]);
                if(hour>0){
                    duration = {hours: hour, minutes: minute};
                }
                
            }
            if(!this.isEmpty(duration)){
                event.duration= duration
            }
            ics.createEvent(event, (error, value) => {
                if (error) {
                    console.log(error)
                    return;
                }

                var element = document.createElement('a');
                element.setAttribute('href', 'data:text/ics;charset=utf-8,' + encodeURIComponent(value));
                element.setAttribute('download', this.event_chapter? e.title.split(" ").join("_")+"_"+e.day+'.ics' :"ccmeeting_"+e.day+'.ics');

                element.style.display = 'none';
                document.body.appendChild(element);

                element.click();

                document.body.removeChild(element);
            })
        },
        makeVCard(m){
            let vcard = "BEGIN:VCARD\n"+
            "VERSION:3.0\n"+
            "N:"+m.name+"\n"+
            "FN:"+m.name+"\n"+
            "ORG:"+m.company+"\n"+
            "TITLE:"+m.job_title+"\n"+
            "EMAIL:"+m.email+"\n"+
            "ROLE:"+m.role+"\n"+
            "URL:"+m.website+"\n"+
            "TEL;TYPE#WORK,VOICE:"+m.phone+"\n"+
            "PHOTO;VALUE#URI;TYPE#JPEG:"+"https://cc.bniplus.com/"+m.photo+"\n"+
            "REV:"+new Date().toISOString()+"\n"+
            "END:VCARD";
            var element = document.createElement('a');
            element.setAttribute('href', 'data:text/vcard;charset=utf-8,' + encodeURIComponent(vcard));
            element.setAttribute('download', m.name+'.vcf');

            element.style.display = 'none';
            document.body.appendChild(element);
            element.click();
            document.body.removeChild(element);
        },
        getSocket(){
            var self = this;
            console.log("get");
            self.$http.post("/api/organization/assistant/client/get", {linkuid: this.linkuid}).then((response) => {
                if (response.data.id_string && !response.data.past) {
                    //self.client.socket = self.$io({transports: ['websocket'], upgrade: false});
                    self.id_string = response.data.id_string;
                    self.$socket.emit("register_assistant",{ id_string : self.id_string});
                    
                }
            }).catch((errors) => {
                console.log(errors);
            });
        },
         getLanguages(){
            this.$http.post("/api/organization/assistant/languages", {linkuid: this.linkuid}).then((response) => {
            if (response.data.success === true) {
                this.languageOptions = response.data.languages;
            }
            }).catch((errors) => {
            console.log(errors)
            });
        },
        getGuests(){
            this.$http.post("/api/organization/assistant/guests/get", {linkuid: this.linkuid}).then((response) => {
            if (response.data.success === true) {
                this.guests = response.data.guests;
            }
            }).catch((errors) => {
            console.log(errors)
            });
        },
    },
    mounted() {
        this.linkuid = this.$route.params.linkuid;
        if(this.$cookies.isKey("language")){
            this.permissions.organization.language = this.getCookie("language","en");
            this.loadLanguageAsync(this.permissions.organization.language);
        }
        this.getLanguages();
        this.getSocket();
        this.getAssistant();
        this.getAttendanceDetails();
        this.getOtherAsks();
        this.getUpcomingMeetings();
        this.getGuests();
    }
    }
</script>
