<template>
  <div>
    <div class="rel-grid-container">
      <div ></div>
      <div>
        <div v-if="permissions.organization.official" id="chapter-filters" class="fit-width">
          
          <span class="form-box-name">{{$t('organizations.regional_contacts')}}</span>
          <b-form-select v-model="chapterFilter.contact" class="filter-select" :options="select_contacts"  value-field="id" text-field="name" @change="contactChange"/>
          <span class="form-box-name">{{$t('organizations.leadership_contact')}}</span>
          <b-form-select v-model="chapterFilter.leadership" class="filter-select" :options="filterContacts"  value-field="id" text-field="name" @change="contactChange"/>
          <span class="form-box-name" id="filter-email">{{$t('organizations.login_email')}}</span>
          <b-form-input v-model="chapterFilter.email" type="text"  id="filter-email-input" />
          <b-button class="action-button" @click="clearChapterFilters()" variant="clear-blue">
            <div class="cancel-button"></div>
            <span>
            {{$t('organizations.clear_filters')}}
            </span>
          </b-button>
          <span v-if="isCorporateConnections" class="form-box-name  first-column">{{$t('slide.chapter_type')}}</span>
          <b-form-select v-if="isCorporateConnections" v-model="chapterFilter.type" :options="chapterTypeOptions" />
          <span v-if="isCorporateConnections" class="form-box-name">{{$t('general.country_text')}}</span><country-select v-if="isCorporateConnections" class="custom-select" v-model="chapterFilter.country"  :country="chapterFilter.country" />
        </div>
        <SetupTip class="setup-tip" :tipNumber="11"/>
        <b-table class="table-hover-new clickable" small bordered :items="organizations" :fields="fields" v-on:row-clicked="editOrganization" :tbody-tr-class="rowClass"
        :filter="chapterFilter" :filter-function="chapterFilterFunction">
              <template v-slot:cell(name)="data">
                {{data.item.name}}
                <img v-if="data.item.no_logins==1" class="name-icon skinned-image" v-b-popover.hover.top="$t('organizations.nologins_tooltip')"  src="../assets/icon-NoLogin.png" />
                <img v-if="data.item.cron_speakers==1" class="name-icon  skinned-image" v-b-popover.hover.top="$t('organizations.debug_tooltip')"  src="../assets/icon-NoNotification2.png" />
              </template>
              <template v-slot:cell(categories)="data">
                        <div v-for="category in data.item.categories" :key="category">{{category}}</div>
                  </template>
                  <template v-slot:cell(last_present)="data">
                       <div v-if="data.item.last_present_day">{{$formatDate(new Date("" + data.item.last_present_day.split("-").join("/") + " 00:01"))}} @ {{data.item.last_present_time}}</div> 
                  </template>
            <template v-slot:cell(actions)="data">
              <div class="chapter-actions-wrapper">
                <b-button class="chapter-action-button" v-if="isCorporateConnections" size="sm" :disabled="data.item.disabled==1" @click.stop="editMeetingDates(data.item)" variant="light-blue-outline">
                 {{$t('organizations.meeting_dates')}}
                </b-button>
                <b-button class="chapter-action-button" v-if="isCorporateConnections" size="sm" :disabled="data.item.disabled==1" @click.stop="editSpeakers(data.item)" variant="light-blue-outline">
                  {{$t('organizations.speakers_text')}}
                </b-button>
                <b-button class="chapter-action-button" v-if="isCorporateConnections" size="sm" :disabled="data.item.disabled==1" @click.stop="editQuotes(data.item)" variant="light-blue-outline">
                  {{$t('organizations.quotes_text')}}
                </b-button>
                <b-button class="chapter-action-button" size="sm" :disabled="data.item.disabled==1" @click.stop="loginAsChapter(data.item)" variant="light-blue-outline">
                  {{$t('organizations.login_as')}}
                </b-button>
                <!--<b-button v-if="data.item.subscription_status==0 && !isCorporateConnections" class="chapter-action-button" size="sm" @click.stop="inviteChapter(data.item)" variant="light-blue-outline">
                  {{$t('organizations.invite_text')}}
                </b-button>-->
              </div>
             </template>
      </b-table></div>
      <b-alert variant="warning"
                     dismissible
                     :show="showSetTimezones"
                     @dismissed="showSetTimezones=false">
              {{$t('organizations.set_timezones')}}
            </b-alert>
      <div class="submit-box-2">
        <b-button v-if="permissions.access" class="action-button auto-margin" v-on:click="getTimeZones" variant="clear-blue"><div class="new-button"></div><span>{{$t('global.button_new')}}</span></b-button>
        <b-modal id="slide-modal-3"  centered v-model="showNewOrg" :title="$tc('organizations.admin_title', organization_edit? 0 : 1)">
          <form id="org-edit-form">
            <div>
            <div class="org-edit-form-box">
                <span class="form-box-title">{{$t('organizations.chapter_info')}}</span>
                <div id="info-form-box" class="form-box">
                  <span class="form-box-name">{{$t('organizations.chapter_name')}}</span><b-form-input v-model="new_org.org_name" type="text" pattern="[^<>]*" :title="$t('global.pattern_title')" class="form-box-input medium-width-input" required></b-form-input>
                  
                  <b-form-checkbox v-if="organization_edit" :disabled="atMaxChapters && new_org.disabled==1" class="form-box-input" v-model="new_org.disabled">
                    {{$t('organizations.disabled_header')}}
                  </b-form-checkbox>
                  <span class="form-box-name first-column" >{{$t('organizations.admin_name')}}</span><b-form-input v-model="new_org.admin_name" :state="new_org.admin_name.length>0 && !chapterAdminNameInUse? null : false" @input="checkAdminName('chapterAdminNameInUse', new_org.admin_id, new_org.admin_name)" type="text" pattern="[^<>]*" :title="$t('global.pattern_title')" class="form-box-input medium-width-input" required></b-form-input>
                  <div v-if="chapterAdminNameInUse" class="red-text name-dupe">{{ $t("global.name_duplicate") }}</div>
                  <span class="form-box-name">{{$t('organizations.admin_password')}}</span><b-form-input v-model="new_org.admin_pass" type="password" class="form-box-input medium-width-input" ></b-form-input>
                  <span v-if="new_org.admin_pass.length>0" class="form-box-name">{{$t('organizations.retype_password')}}</span><b-form-input v-if="new_org.admin_pass.length>0" :state="new_org.admin_pass===new_org.admin_retype && new_org.admin_retype.length>0" v-model="new_org.admin_retype" type="password" class="form-box-input" ></b-form-input>
                  <div v-if="organization_edit"  id="password-change">{{$t('organizations.password_change')}}</div>
                  <span v-if="organization_edit" class="form-box-name  first-column">{{$t('organizations.languages_text')}}</span>
                  <div  v-if="organization_edit" id="language-select" >
                    <multiselect v-model="associated_langs_list" :options="languageOptions" class="blueselect" :noOptionsText="$t('global.empty_list')"  mode="tags" track-by="name" valueProp="id" :close-on-select="false" :searchable="true" :placeholder="$t('country.choose_language')" label="name">
                    </multiselect>
                  </div>
                  <b-form-checkbox v-if="organization_edit" class="form-box-input" v-model="new_org.onboard" >
                    {{$t('organizations.setup_header')}}
                  </b-form-checkbox>
                  <b-button v-if="official_region>0 && organization_edit" id="move-chapter-button" v-on:click="showNewOrg=false;showMoveChapter=true;" variant="light-blue-outline"><span>{{$t('organizations.move_chapter')}}</span></b-button>
                  <b-form-checkbox v-if="!organization_edit && isCorporateConnections" class="form-box-input" v-model="new_org.event_chapter" v-on:change="new_org.allow_logins = (1-new_org.event_chapter)">
                    {{$t('organizations.event_chapter')}}
                  </b-form-checkbox>
                  <b-form-checkbox v-if="!organization_edit" :disabled="new_org.event_chapter==1" class="form-box-input" v-model="new_org.allow_logins" >
                    {{$t('organizations.allow_logins')}}
                  </b-form-checkbox>
                  <span v-if="isCorporateConnections" class="form-box-name  first-column">{{$t('organizations.type_text')}}</span>
                  <b-form-select v-if="isCorporateConnections" v-model="new_org.official" :options="chapter_types"  value-field="id" text-field="name"/>
                  <div v-if="!organization_edit" id="no-logins-note" v-html="$t('organizations.no_logins_note')">
                  </div>
                  <div v-if="new_org.allow_logins==0 && organization_edit" id="import-members-wrapper">
                      <b-button class="action-button" v-on:click="showImportMembers" variant="clear-blue"><div class="import-button"></div><span>{{$t('organizations.import_members')}}</span></b-button>
                  </div>
                </div>
            </div>
            <div class="no-logins center-text" v-if="new_org.allow_logins==0" v-html="$t('organizations.no_logins')">
            </div>
          </div>
            <div class="org-edit-form-box" v-if="this.organization_edit">
                <span class="form-box-title">{{$t('organizations.chapter_contacts')}}</span>
                <div id="chapter-contacts-form-box" class="form-box">
                  <div v-for="ctype in typeOptions" :key="ctype.id" class="contact-type">
                    <span class="form-box-name">{{ctype.name}}</span>
                    <multiselect class="blueselect contact-select" v-model="ctype.members" :options="contacts.filter(t => (t.contact_type.split(',').find(v => v == ctype.id)!=undefined))" :object="true" :placeholder="$t('global.select_types')" label="name" track-by="name"  :noOptionsText="$t('global.empty_list')"  mode="tags" valueProp="id" :close-on-select="false" :searchable="true">
                  </multiselect>
                  </div>
              </div>
            </div>
            <b-alert variant="danger"
                     dismissible
                     :show="showAlert"
                     @dismissed="showAlert=false">
              {{alertText}}
            </b-alert>
          </form>
          
          <div slot="modal-footer" class="submit-box-2">
            <b-button v-if="new_org.was_disabled==1"  class="action-button" v-on:click="showNewOrg=false;showDeleteChapter=true;" variant="clear-blue"><div class="delete-button"></div><span>{{$t('global.button_delete')}}</span></b-button>
            <b-button  class="action-button" v-on:click="showNewOrg=false;" variant="clear-blue"><div class="cancel-button"></div><span>{{$t('global.button_cancel')}}</span></b-button>
            <b-button class="action-button" v-on:click="saveOrg" variant="clear-blue"><div class="save-button"></div><span>{{$t('global.button_save')}}</span></b-button>
          </div>
        </b-modal>
        <b-modal id="modal-center" v-model="showDeleteChapter" centered :title="$t('organizations.delete_title')" >
            <p class="my-4" v-html="$t('organizations.delete_chapter')"></p>
            <div slot="modal-footer" class="submit-box-2">
              <b-button class="action-button" v-on:click="showDeleteChapter=false" variant="clear-blue"><div class="cancel-button"></div><span>{{$t('global.button_cancel')}}</span></b-button>
              <b-button class="action-button" v-on:click="deleteChapter" variant="clear-blue"><div class="delete-button"></div><span>{{$t('global.button_delete')}}</span></b-button>
            </div>
          </b-modal>
          <b-modal id="modal-center" v-model="showMoveChapter" centered :title="$t('organizations.move_chapter')">
            <p class="my-4" v-html="isCorporateConnection? $t('organizations.move_confirm_cc') : $t('organizations.move_confirm')"></p>
            <div slot="modal-footer" class="submit-box-2">
              <b-button class="action-button" v-on:click="showMoveChapter=false;" variant="clear-blue"><div class="cancel-button"></div><span>{{$t('global.button_cancel')}}</span></b-button>
              <b-button class="action-button" v-on:click="moveChapter" variant="clear-blue"><div class="save-button"></div><span>{{$t('global.button_yes')}}</span></b-button>
            </div>
          </b-modal>
          <b-modal id="modal-center" v-model="showInviteSent" centered :title="$t('organizations.invitation_title')">
            <div>
              <span>{{$t('organizations.invitation_sent')}}:</span>
              <br/><br/>
              <div v-for="contact in sent_contacts" :key="contact.email">
                  {{contact.name+" ("+contact.email+")"}}
              </div>
            </div>
            <div slot="modal-footer" class="submit-box-2">
              <b-button class="action-button" v-on:click="showInviteSent = false;" variant="clear-blue"><div class="save-button"></div><span>{{$t('global.button_ok')}}</span></b-button>
            </div>
          </b-modal>
          <b-modal id="modal-center" v-model="showInviteModal" centered :title="$t('organizations.invite_confirm_title')">
            <div>
              {{$t('organizations.invite_chapter_confirm_text')}}
            </div>
            <div slot="modal-footer" class="submit-box-2">
              <b-button class="action-button" v-on:click="showInviteModal = false;" variant="clear-blue"><div class="cancel-button"></div><span>{{$t('global.button_cancel')}}</span></b-button>
              <b-button class="action-button" v-on:click="inviteConfirmed" variant="clear-blue"><div class="save-button"></div><span>{{$t('global.button_confirm')}}</span></b-button>
            </div>
          </b-modal>
           <b-modal id="slide-modal-2" v-model="showChapterSpeakers" centered :title="currentOrganization.name">
            <Schedule v-if="speakerObject.id==-1" :chapter_id="currentOrganization.id" :speakerObject="speakerObject"></Schedule>
            <Entry v-if="speakerObject.id>=0" :chapter_id="currentOrganization.id" :speakerObject="speakerObject"></Entry>
            <div slot="modal-footer" class="submit-box-2">
            </div>
          </b-modal>
           <b-modal id="slide-modal" v-model="showChapterQuotes" centered :title="currentOrganization.name">
            <Quotes v-if="quoteObject.id==-1" :chapter_id="currentOrganization.id" :quoteObject="quoteObject"></Quotes>
            <Quote v-if="quoteObject.id>=0" :chapter_id="currentOrganization.id" :quoteObject="quoteObject" ></Quote>
            <div slot="modal-footer" class="submit-box-2">
            </div>
          </b-modal>
          <b-modal id="slide-modal" v-model="showMeetingDates" centered :title="currentOrganization.name">
            <MeetingDates :chapter_id="currentOrganization.id" ></MeetingDates>
            <div slot="modal-footer" class="submit-box-2">
            </div>
          </b-modal>
      </div>
      <div class="div-space"></div>
    </div>
      <b-modal id="slide-modal-2"  v-model="showMemberSelection" centered :title="$t('members.import_title')">
        <div >
          <div id="import-form-box" class="fit-width">
            <span class="form-box-name">{{$t('members.name_header')}}</span>
            <b-form-input v-model="memberFilter.name" type="text" class="import-form-input" />
            <span class="form-box-name import-form-name" >{{$t('members.category_header')}}</span>
            <b-form-input v-model="memberFilter.category" type="text" class="import-form-input" />
            <span class="form-box-name import-form-name">{{$t('members.organization_header')}}</span>
            <b-form-input v-model="memberFilter.chapter" type="text" class="import-form-input" />
            <b-button class="action-button" @click="clearMemberFilters()" variant="clear-blue">
              <div class="cancel-button"></div>
              <span>
              {{$t('organizations.clear_filters')}}
              </span>
            </b-button>
          </div>
         <b-table class="table-hover-new clickable" small bordered :items="members" :fields="memberFields" :tbody-tr-class="importRowClass" :per-page="perPage" :current-page="currentPage"
          @filtered="onFiltered" :filter="memberFilter" :filter-function="tableFilter">
              <template v-slot:cell(import)="data">
                <b-form-checkbox  class="fit-width auto-margin" v-model="data.item.import">
                </b-form-checkbox>
              </template>
        </b-table>
        <b-pagination v-if="totalRows>perPage"  v-model="currentPage" :total-rows="totalRows" :per-page="perPage" align="fill" size="sm" class="my-0" ></b-pagination>
        <div id="member-exists-note">*{{$t('organizations.member_exists')}}</div>
        </div>
      <div slot="modal-footer" class="submit-box-2">
        <b-button class="action-button" v-on:click="showMemberSelection=false;" variant="clear-blue"><div class="cancel-button"></div><span>{{$t('global.button_cancel')}}</span></b-button>
        <b-button class="action-button" v-on:click="importSelectedMembers" variant="clear-blue"><div class="import-button"></div><span>{{$t('global.button_import')}}</span></b-button>
      </div>
    </b-modal>
  </div>
</template>
<style scoped>
.no-logins{
  padding:10px;
  font-size:12px;
}
.name-dupe{
  grid-column: 2/4;
}
.contact-type{
  display: grid;
  grid-template-columns: 30% 70%;
  margin: 5px 0px;
}
#chapter-contacts-form-box{
  grid-template-columns: auto;
}
#import-members-wrapper{
  grid-column: 2;margin: auto;margin-top: 20px;
}
#no-logins-note{
  grid-column: 2 / 3;font-size: 10px;margin: auto;margin-bottom: 10px;
}
#move-chapter-button{
  grid-column: 2;margin-top:10px;
}
#password-change{
  font-size: 10px;grid-column: 1/3;margin-left: auto;font-size: 10px;margin-bottom: 20px;
}
#info-form-box{
  grid-template-columns: 40% 60%;
}
#org-edit-form{
  display:grid; grid-template-columns: 40% 60%;;
}
#member-exists-note{
  color:rgba(100,180,100,.8);float:right;
}
.import-form-name{
  margin-left: 20px;
}
.import-form-input{
  width:200px;
}
#import-form-box{
  display:grid; grid-template-columns: repeat(7,max-content);margin:auto;
}
.name-icon{
  height: 16px;margin: 7px 5px;
}
.chapter-actions-wrapper{
  display:grid; grid-template-columns: repeat(4,max-content);
}
.chapter-action-button{
  position:relative;font-size:16px;width:100px;margin:0 10px;
}
#filter-email-input{
  width:200px;
}
#filter-email{
  margin-left: 20px;
}
.filter-select{
  min-width:100px;
}
#chapter-filters{
  display: grid;grid-template-columns: repeat(8,auto);margin:auto; margin-bottom: 20px;grid-gap:10px;
}
#language-select{
  grid-column: 2/3;margin-bottom: 10px; max-width: 300px;
}
.contact-select{
  grid-column:2/4;
}
</style>
<script>
  import MeetingDates from './MeetingDates.vue';
  import Quotes from './Quotes.vue';
  import Quote from './Quote.vue';
  import Schedule from './Speakers.vue';
  import Entry from './Speaker.vue';
  
  import router from "../router"
import { DEFAULT_LOCALE_ID } from '../constants/appConstants';
    export default {
      name: "Organizations",
      components: {
      MeetingDates,
      Schedule,
      Quotes,
      Quote,
      Entry
    },watch:{
        '$i18n.locale': {
          handler: function () {
            /*this.fields[0].label = this.$t('organizations.chapter_header');
            this.fields[1].label = this.$t('organizations.next_meeting');
            this.fields[2].label = this.$t('organizations.lastpresent_header');*/
            this.getContactTypes();
          },
          deep: true
        }
      },
      data() {
        return {
          showSetTimezones: false,
          chapterAdminNameInUse: false,
          chapterTypeOptions:[{value: 0, text: this.$t('slide.meeting_type0')},{value: 1, text: this.$t('region.startup_text')},{value: 2, text: this.$t('region.official_text')}],
          chapterFilter: {country: "", type:0, email: "", leadership: 0, contact: 0},
          memberFilter: {name: "", category: "", chapter: ""},
          perPage: 10,
          currentPage:1,
          totalRows:0,
          members: [],
          startup_chapters: [],
          importMembers: [],
          allMembers: [],
          showMemberSelection: false,
          speakerObject: {id: -1},
          quoteObject: {id: -1},
          showChapterSpeakers: false,
          showChapterQuotes: false,
          currentOrganization: {},
          showMeetingDates: false,
          showInviteModal: false,
          inviteRegionId: 0,
          sent_contacts: [],
          showMoveChapter: false,
          showDeleteChapter: false,
          languageOptions: [],
          associated_langs_list: [],
          memberFields:  [
             { key: 'import',
              label: this.$t('organizations.import_header'),
              sortable: true,
              thStyle: { "background-color":  process.env.VUE_APP_TABLE_HEADER,"color": "#ffffff"},
              tdClass: "actions-centre"
            },
            
            { key: 'name',
              label: this.$t('members.name_header'),
              sortable: true,
              thStyle: { "background-color":  process.env.VUE_APP_TABLE_HEADER,"color": "#ffffff"},
              tdClass: "bolded-text"
            },
            { key: 'category',
              label: this.$t('members.category_header'),
              sortable: true,
              thStyle: { "background-color":  process.env.VUE_APP_TABLE_HEADER,"color": "#ffffff" }
            },
            { key: 'company',
              label: this.$t('members.company_header'),
              sortable: true,
              thStyle: { "background-color":  process.env.VUE_APP_TABLE_HEADER,"color": "#ffffff" }
            },
            { key: 'org',
              label: this.$t('members.organization_header'),
              sortable: true,
              thStyle: { "background-color":  process.env.VUE_APP_TABLE_HEADER,"color": "#ffffff"},
            },
          ],
          fields: [
            { key: 'name',
              label: this.$t('organizations.chapter_header'),
              sortable: true,
              thStyle: { "background-color":  process.env.VUE_APP_TABLE_HEADER,"color": "#ffffff"},
              tdClass: "bolded-text"
            },
             { key: 'size',
              label: this.$t('dashboard.size_header'),
              sortable: true,
              thStyle: { "background-color":  process.env.VUE_APP_TABLE_HEADER,"color": "#ffffff"},
              tdClass: "actions-centre"
            },
             
            { key: 'next_meeting',
              label: this.$t('organizations.meet_day'),
              sortable: true,
              thStyle: { "background-color":  process.env.VUE_APP_TABLE_HEADER,"color": "#ffffff" }
            },
            { key: 'meet_start',
              label: this.$t('organizations.meet_time'),
              sortable: true,
              thStyle: { "background-color":  process.env.VUE_APP_TABLE_HEADER,"color": "#ffffff" }
            },
             
             { key: 'last_present',
              label: this.$t('organizations.lastpresent_header'),
              sortable: true,
              thStyle: { "background-color":  process.env.VUE_APP_TABLE_HEADER,"color": "#ffffff" }
            },
            { key: 'actions',
              label: this.$t('organizations.manage_text'),
              sortable: false,
              thStyle: { "background-color": process.env.VUE_APP_TABLE_HEADER, "color": "#ffffff"},
              tdClass: "actions-centre"
            }
          ],
          organization_edit: false,
          organizations: [],
          temp: [],
          new_org: { admin_id: 0, org_id:0, org_name: "", admin_name: "", admin_pass: "", default_lang: null, associated_langs_list: [], laguage_admin: 0, onboard: 0, allow_logins: 1,event_chapter: 0,
          edmin_email: "", admin_retype: "", pull_parent: 1, exec_director: 0, ambassador: 0, director_consultant: 0, area_director: 0,was_disabled:0, official: 1 },
          showNewOrg: false,
          showAlert: false,
          alertText: "",
          contacts: [],
          typeOptions: [],
          email_search: "",
          contact_search: 0,
          leadership_search: 0,
          filterContacts: [],
          select_contacts: [],
          official_region: 0,
          atMaxChapters: false,
          showInviteSent: false,
          chapter_types: [{id: 1, name: this.$t('region.official_text')},{id: 0, name: this.$t('region.startup_text')}]
        }
      },
    methods: {
      checkAdminName(varName, id, name){
            this.$http.post("/api/member/admin/name/check", {id: id, name: name}).then((response) => {
                if (response.data.success == true) {
                 this.$set(this, varName, response.data.check);
                }
              }).catch(() => {
              });
          },
      onFiltered(filteredItems) {
        this.totalRows = filteredItems.length;
        //this.currentPage = 1;
      },
      clearMemberFilters(){
        this.memberFilter= {name: "", category: "", chapter: ""};
      },
      tableFilter(row, filter){
        if((filter.name.length==0 || row.name.toLocaleLowerCase().includes(filter.name.toLocaleLowerCase())) &&
          (filter.category.length==0 || row.category.toLocaleLowerCase().includes(filter.category.toLocaleLowerCase())) &&
          (filter.chapter.length==0 || row.org.toLocaleLowerCase().includes(filter.chapter.toLocaleLowerCase()))){
          return true;
        }
        return false;
      },
      clearChapterFilters(){
        this.chapterFilter= {country: "", type: 0, email: "", leadership: 0, contact: 0};
      },
      chapterFilterFunction(row, filter){
        return ((filter.country == "" || filter.country == row.country) && (filter.type==0 || filter.type == row.chapter_type+1) && (filter.email.length==0 || row.emails.toLocaleLowerCase().includes(filter.email.toLocaleLowerCase())) && 
          (filter.leadership == 0 || row.leadership.split(",").includes(filter.leadership+"")) && 
          (filter.contact == 0 || row.contacts.split(",").includes(filter.contact+"")));
      },
      deleteChapter(){
        this.$http.post("/api/region/chapter/delete", {id: this.new_org.org_id}).then((response) => {
          if(response.data.success){
            this.organizations = this.organizations.filter(v => v.id != this.new_org.org_id);
            this.showDeleteChapter = false;
          }else{
            this.showDeleteChapter = false;
          }
        }).catch(() => {
        });
      },
      importRowClass(item){
        if (!item) return
        if (item.exists==1) return 'light-green-row'
      },
      rowClass(item) {
            if (!item) return
            if (item.shadow==1) return 'shadowClass'
            if (item.disabled) return 'disabledClass'
          },
      goToScripts(id) {
        router.push("/admin/scripts/" + id);
      },
      goToAgenda(id) {
        router.push("/admin/agenda/" + id);
      },
     
      getOrganizations() {
        this.$http.post("/api/organization/get/children/withinfo", {}).then((response) => {
          for(var i=0; i<response.data.children.length;i++){
            var d = response.data.children[i];
            d.next_meeting = this.$t('general.day_option_'+(d.meet_day_of_week));
            if(d.formatted_date ==null){
              d.next_meeting="";
            }else if((response.data.isCorporateConnections || d.official!=1) && d.date_diff>=7){
              d.next_meeting = this.$formatDate(d.formatted_date);
            }
          }
          this.organizations = response.data.children;

        }).catch(() => {
        });
      },
      getContactTypes(){
        this.$http.post("/api/contact/types/get", {only_assignable: true}).then((response) => {
          for(var j=0; j<response.data.types.length;j++){
              response.data.types[j].members = [];
            }
          this.typeOptions = response.data.types;
          
        }).catch(() => {
        });
      },
      newOrganization() {
        this.new_org.org_name = "";
        this.new_org.admin_name = "";
        this.new_org.admin_pass = "";
        this.new_org.admin_email = "";
        this.new_org.admin_retype = "";
        this.new_org.pull_parent = 0;
        this.organization_edit = false;
        this.showNewOrg = true;
        this.new_org.exec_director= false;
        this.new_org.ambassador= 0; 
        this.new_org.director_consultant= 0;
        this.new_org.area_director= 0;
        this.new_org.disabled = false;
        this.new_org.cron_speakers = 1;
        this.new_org.onboard = false;
        this.new_org.allow_logins = true;
        this.new_org.official = 1;
        this.new_org.default_lang = null;
      },
      editOrganization(org) {
        this.$http.post("/api/organization/get/admin_info", {id: org.id}).then((response) => {
          if (response.data.success) {
            this.new_org.org_name = response.data.organization.name;
            this.new_org.official = response.data.organization.official;
            this.new_org.allow_logins = response.data.organization.allow_logins;
            this.new_org.admin_name = response.data.admin.name;
            this.new_org.admin_id = response.data.admin.id;
            this.new_org.org_id = response.data.organization.id;
            this.new_org.admin_pass = "";
            this.new_org.admin_email = response.data.admin.email;
            this.new_org.admin_retype = "";
            this.organization_edit = true;
            this.showNewOrg = true;
            this.new_org.was_disabled = response.data.organization.disabled==1;
            this.new_org.disabled = response.data.organization.disabled==1;
            this.new_org.cron_speakers = response.data.organization.cron_speakers==1;
            this.new_org.contacts = {};
            this.new_org.language_admin = response.data.organization.language_admin==1;
            this.new_org.onboard = response.data.organization.onboard==1;
            for(var j=0; j<this.typeOptions.length;j++){
              this.typeOptions[j].members = [];
            }
            this.new_org.default_lang = response.data.organization.default_lang;
            this.associated_langs_list = (response.data.organization.associated_langs_list || '').split(",").reduce((pv, cv) => {
              const langId = parseInt(cv);
              if (!isNaN(langId)) pv.push(langId);
              return pv;
            }, []);
            this.$http.post("/api/organization/contacts/assigned/noauth", {org: org.id}).then((response) => {
                for(var i=0; i<response.data.contacts.length; i++){
                  for(var j=0; j<this.typeOptions.length;j++){
                      if(response.data.contacts[i].type_id == this.typeOptions[j].id){
                        this.typeOptions[j].members.push(response.data.contacts[i]);
                      }
                    }
                }
            }).catch((errors) => {console.log(errors);});
            
          }
        }).catch((e) => {console.log(e);
        });
      },
      editSpeakers(org){
        this.speakerObject= {id: -1};
        this.currentOrganization = org;
        this.showChapterSpeakers = true;
      },
      editQuotes(org){
        this.quoteObject= {id: -1};
        this.currentOrganization = org;
        this.showChapterQuotes = true;
      },
      editMeetingDates(org){
        this.currentOrganization = org;
        this.showMeetingDates = true;
        // router.push("/admin/meetingdates/" + org.id);
      },
      loginAsChapter(org){
        this.$http.post("/api/login/tochild", {id: org.id}).then((response) => {
            if(response.data.success){
              this.permissions.access = response.data.permissions.access;
              this.permissions.admin = response.data.permissions.admin;
              this.permissions.access = response.data.permissions.access;
              this.permissions.access = response.data.permissions.access;
              this.permissions.access = response.data.permissions.access; 
              this.permissions.organization = response.data.permissions.organization;
              this.permissions.user = response.data.permissions.user;
              document.title = this.$t('global.document_title_admin') +" "+ this.permissions.organization.name;
              router.push("/admin/dashboard");
              this.$root.$emit('userChange', "");
            }
        }).catch((errors) => {console.log(errors);});
      },
      inviteChapter(org){
         this.inviteRegionId = org.id;
         this.showInviteModal = true;
      },
      inviteConfirmed(){
        if(this.inviteRegionId>0){
          this.$http.post("/api/invite/child", {id: this.inviteRegionId}).then((response) => {
            this.inviteRegionId = 0;
            this.showInviteModal = false;
            if(response.data.success){
              this.showInviteSent = true;
              this.sent_contacts = response.data.contacts;
            }
        }).catch((errors) => {console.log(errors);});
        }
      },
      updateAdmin() {
        
        this.showAlert = false;
        if (this.new_org.org_name.length == 0) {
          this.showAlert = true;
          this.alertText = this.$t('organizations.alert_chapter');
          return;
        }
        if (this.new_org.admin_name.length == 0 || this.chapterAdminNameInUse) {
          this.showAlert = true;
          this.alertText = this.$t('organizations.alert_admin');
          return;
        }
        /*if (this.new_org.admin_email.length == 0) {
          this.showAlert = true;
          this.alertText = this.$t('organizations.alert_email');
          return;
        }*/
        if (this.new_org.admin_pass.length>0 && this.new_org.admin_pass != this.new_org.admin_retype) {
          this.showAlert = true;
          this.alertText = this.$t('organizations.alert_match');
          return;
        }
        this.alertText = "";
        this.new_org.contacts = this.typeOptions;

        // if the chapter's associated languages list is empty, add the default language
        if (this.associated_langs_list.length === 0) {
          this.associated_langs_list.push(this.new_org.default_lang || DEFAULT_LOCALE_ID);
        }
        // if the chapter's default language is not in the associated languages list, then add the first language in the list
        if (this.associated_langs_list.findIndex(langId => parseInt(langId) === this.new_org.default_lang) === -1) {
          this.new_org.default_lang = this.associated_langs_list[0];
        }

        this.new_org.associated_langs_list = this.associated_langs_list.join(",");

        this.$http.post("/api/organization/update/admin_info", this.new_org).then((response) => {
          if (response.data.success) {
            this.organization_edit = false;
            this.showNewOrg = false;
            this.getOrganizations();
          } else if (response.data.error.errno == 1062) {
            this.showAlert = true;
            this.alertText = "The name '" + response.data.error.conflict + "' is already in use.";
          } else {
            this.showAlert = true;
            this.alertText = response.data.error;
          }
        }).catch(() => {
          this.$bvToast.show('serverError');
        });
      },
      saveOrg() {
        if (this.organization_edit) {
          this.updateAdmin();
          return;
        }
        this.showAlert = false;
        if (this.new_org.org_name.length == 0) {
          this.showAlert = true;
          this.alertText = this.$t('organizations.alert_chapter');
          return;
        }
        if (this.new_org.admin_name.length == 0) {
          this.showAlert = true;
          this.alertText = this.$t('organizations.alert_admin');
          return;
        }
        if (this.new_org.admin_pass.length == 0) {
          this.showAlert = true;
          this.alertText = this.$t('organizations.alert_password');
          return;
        }
        /*if (this.new_org.admin_email.length == 0) {
          this.showAlert = true;
          this.alertText = this.$t('organizations.alert_email');
          return;
        }*/
        if (this.new_org.admin_pass != this.new_org.admin_retype) {
          this.showAlert = true;
          this.alertText = this.$t('organizations.alert_match');
          return;
        }
        this.alertText = "";
        this.new_org.allow_logins = this.new_org.allow_logins? 1 : 0;
        this.$http.post("/api/organization/new", this.new_org).then((response) => {
          if (response.data.success) {
            this.showNewOrg = false;
            this.getOrganizations();
          } else {
            this.showAlert = true;
            this.alertText = response.data.error;
          }
        }).catch(() => {
        });
      },
      disableOrg(org) {
        this.$http.post("/api/organization/update/disable", org).then(() => {
        }).catch(() => {
          this.$bvToast.show('serverError');
        });
      },
      setPayed(org){
        this.$http.post("/api/organization/update/expiry", {id : org.id}).then((response) => {
          if(response.data.success){
            org.expired = 0;
          }
        }).catch(() => {
          this.$bvToast.show('serverError');
        });
      },
      pullOrg(org) {
        this.$http.post("/api/organization/update/pull", org).then(() => {
        }).catch(() => {
          this.$bvToast.show('serverError');
        });
      },
      onboardOrg(org) {
        this.$http.post("/api/organization/update/onboard", org).then(() => {
        }).catch(() => {
          this.$bvToast.show('serverError');
        });
      },
      getLanguages(){
        this.$http.post("/api/organization/languages/possible", {}).then((response) => {
          if (response.data.success === true) {
            this.languageOptions = response.data.languages;
          }
        }).catch((errors) => {
          console.log(errors)
        });
      },
      getContacts() {
        this.$http.post("/api/organization/contacts", {}).then((response) => {
            this.contacts = response.data.contacts;
            this.select_contacts = response.data.contacts.filter(function () {
              return true;
            });
             this.select_contacts.unshift({id: 0, name: this.$t('organizations.select_contact')});
            
        }).catch((errors) => {console.log(errors);});
      },
      getRegionLeadership(){
        this.$http.post("/api/region/get/leadership", {}).then((response) => {
            this.filterContacts = response.data.members;
            this.filterContacts.unshift({id: 0, name: this.$t('organizations.select_contact')});
        }).catch((errors) => {console.log(errors);});
      },
      getOfficialRegion(){
        this.$http.post("/api/region/get/officialregion", {}).then((response) => {
            if(response.data.success && response.data.official==0){
              this.official_region = response.data.official_region;
            }
        }).catch((errors) => {console.log(errors);});
      },
      moveChapter(){
        this.$http.post("/api/organization/child/makeofficial", {id: this.new_org.org_id}).then((response) => {
            if(response.data.success){
              this.getOrganizations();
            }
            this.showMoveChapter=false;
        }).catch((errors) => {console.log(errors); this.showMoveChapter=false;});
      },
      getTrialInfo(){
        this.$http.post("/api/organization/trialinfo", {}).then((response) => {
            if(response.data.success){
              this.atMaxChapters = response.data.at_max_chapters;
            }
        }).catch((errors) => {console.log(errors); this.showMoveChapter=false;});
      },
      showImportMembers(){
        this.getMembers();
        this.showMemberSelection = true;
      },
      importSelectedMembers(){
        this.importMembers = this.members.filter(v => v.import);
        this.$http.post("/api/organization/members/copy/tochapter", { members: this.importMembers, chapter_id: this.new_org.org_id }).then((response) => {
           this.showMemberSelection=false;
           this.$bvToast.toast(this.$t('organizations.members_imported'), {
            variant: 'success',
            solid: true
            });
           this.clearChapterFilters();
            this.importMembers = [];
            if (response.data.success) {
             this.getOrganizations();
            }
          }).catch((errors) => {
             this.showMemberSelection=false;
          this.importMembers = [];
          });
        
      },
      getMembers() {
        this.members = [];
        this.$http.post("/api/organization/members/forimport",{chapter_id: this.new_org.org_id}).then((response) => {
            for (var i = 0; i < response.data.members.length; i++) {
              response.data.members.import = false;
            }
            this.totalRows = response.data.members.length;
            this.members = response.data.members;
        }).catch((errors) => {console.log(errors);});
      },
      getTimeZones() {
        this.$http.post("/api/organization/timezones/regional", {}).then((response) => {
          if (response.data.success === true) {
            if(response.data.timezone_ids_string.length==0){
              this.showSetTimezones = true;
            }else{
              this.newOrganization();
            }
          }
        }).catch((errors) => {
          console.log(errors)
        });
      },
    },
    destroyed() {
      this.removeClass(document.getElementById("menu-chapters"), "active-menu");
      this.removeClass(document.getElementById("menu-chapter"), "active-menu");
    },
    mounted() {
      this.addClass(document.getElementById("menu-chapters"), "active-menu");
      this.addClass(document.getElementById("menu-chapter"), "active-menu");
       if(!this.isCorporateConnections){
            this.fields.splice(4, 0, { key: 'categories',
            label: this.$t('dashboard.top_categories'),
            sortable: true,
            thStyle: { "background-color":  process.env.VUE_APP_TABLE_HEADER,"color": "#ffffff" }
          });
        }else{
          this.chapterTypeOptions=[{value: 0, text: this.$t('slide.meeting_type0')},{value: 1, text: this.$t('region.startup_text')},{value: 2, text: this.$t('region.launched_text')}];
          this.chapter_types= [{id: 1, name: this.$t('region.launched_text')},{id: 0, name: this.$t('region.startup_text')}];
        }
      this.getOrganizations();
      this.getContacts();
      this.getContactTypes();
      this.getLanguages();
      this.getRegionLeadership();
      this.getOfficialRegion();
      this.getTrialInfo();
    }
    }
</script>
