<template>
  <div>
    <div class="rel-grid-container">
      <div ></div>
      <form id="event-form" v-on:submit.prevent="updateEvent" class="form-fix">
        <div>
          <span  class="form-box-title">{{isManager || useruid==''? $t('event.event_title') : $t('event.event_submit')+ " "+organization_name}}</span>
          <div id="event-form-box" class="form-box">
            <span class="form-box-name">{{$t('event.title_text')}}</span><b-form-input :disabled="event.is_parent==1" :state="event.title_state"  v-model="event.title" type="text" pattern="[^<>]*" :title="$t('global.pattern_title')" class="form-box-input" maxlength="255"></b-form-input>
            <span class="form-box-name">{{$t('event.when_text')}}</span><b-form-input :disabled="event.is_parent==1" :state="event.when_state" v-model="event.when_description" type="text" pattern="[^<>]*" :title="$t('global.pattern_title')" class="form-box-input" maxlength="100"></b-form-input>
             <span class="form-box-name">{{$t('event.short_description')}}</span><b-form-textarea id="event-description" :disabled="event.is_parent==1" v-model="event.description" :rows="8"  class="form-box-input"/>
            <span class="form-box-name">{{$t('event.registration_link')}}</span><b-form-input :disabled="event.is_parent==1"  v-model="event.link" type="url" :title="$t('global.pattern_title')" class="form-box-input" maxlength="1000"></b-form-input>
            <span v-if="event.is_parent==1  && event.image" class="form-box-name">{{$t('event.picture_text')}}</span><img v-if="event.is_parent==1 && event.image" id="event-photo" :src="event.image" />
            <ImageSelector  v-if="event.is_parent==0" id="event-image" ref="imageSelector" :imageObject="event" :imageField="'image'" :maxWidth="1000" :maxHeight="1000" :smallImageField="'image'" :clearButton="true" />
            <span id="event-submitted-by" class="form-box-name" v-if="(isManager || useruid=='') && event.is_parent==0">{{ $t('event.submitted_by') }}</span><b-form-select v-if="(isManager || useruid=='') && !event.is_parent" v-model="event.submitted_by" :options="members" value-field="id" text-field="name" />
          </div>
        </div>
        <div v-if="showSuccess">
          <b-alert variant="success" :show="showSuccess">
            {{$t('event.successful_save')}}
          </b-alert>
        </div>
        <div class="submit-box-2" v-if="event.is_parent==0">
          <b-button v-b-modal.modal-center v-if="event.id>0" class="action-button" variant="clear-blue"><div class="delete-button"></div><span>{{$t('global.button_delete')}}</span></b-button>
          <b-button class="action-button" v-if="!showSuccess" v-on:click="back" variant="clear-blue"><div class="cancel-button"></div><span>{{$t('global.button_cancel')}}</span></b-button>
          <b-button class="action-button" type="submit" v-if="!showSuccess" variant="clear-blue"><div class="save-button"></div><span>{{$t('global.button_save')}}</span></b-button>
        </div>
        <b-modal id="modal-center" v-model="showDelete" centered :title="$t('event.delete_title')">
          <p class="my-4">{{$t('event.delete_text')}}</p>
          <div slot="modal-footer" class="submit-box-2">
            <b-button class="action-button" v-on:click="showDelete=false" variant="clear-blue"><div class="cancel-button"></div><span>{{$t('global.button_cancel')}}</span></b-button>
            <b-button class="action-button" v-on:click="deleteEvent" variant="clear-blue"><div class="delete-button"></div><span>{{$t('global.button_delete')}}</span></b-button>
          </div>
        </b-modal>
      </form>
      <div class="div-space"></div>
    </div>
  </div>
</template>
<style scoped>
#event-image{
  margin-left:10px;grid-column: 1/3;
}
#event-submitted-by{
  grid-column:1/2;
}
#event-description{
  margin-bottom: 7px;
}
#event-form-box{
  grid-template-columns: max-content auto;
}
#event-form{
  width:600px;
}
#event-photo {
    max-height: 100px;
    max-width: 200px;
    border: 1px gray solid;
  }
</style>
<script>

      
    
  import router from "../router"
  import VueCropper from 'vue-cropperjs';
    import 'cropperjs/dist/cropper.css';

    export default {
    name: "Event",
    components: {
      VueCropper
    },
      data() {
        return {
            showDelete: false,
            event: {id: 0, title : '', when_description: '', description: "", link: "", image: "", title_state: null, link_state: null, when_state: null,submitted_by: 0, is_parent:false},
            useruid: '',
            showSuccess: false,
            members: [],
            isManager: false,
            organization_name: '',
        }
      },
    methods: {
      getEvent(){
        if(this.event.id>0){
          this.event.useruid = this.useruid;
           this.$http.post("/api/organization/events/get"+(this.useruid==''? "" : "/byuseruid"), this.event).then((response) => {
              if (response.data.success) {
                response.data.title_state=null; response.data.link_state= null; response.data.when_state= null;
                this.event = response.data.event;
              }
            }).catch(() => {});
        }
      },
        updateEvent(){
          this.event.title_state=null; this.event.link_state= null; this.event.when_state= null;
          var eventCheck = true;
          if(this.event.title.length==0){
            eventCheck = false;
            this.event.title_state = false;
          }
          if(this.event.when_description.length==0){
            eventCheck = false;
            this.event.when_state = false;
          }
          if(eventCheck){
            this.event.useruid = this.useruid;
            this.$http.post("/api/organization/events/update"+(this.useruid==''? "" : "/byuseruid"), this.event).then((response) => {
              if (response.data.success) {
                if(this.isManager || this.useruid==''){
                  this.back();
                }else{
                  this.showSuccess=true;
                }
              }
            }).catch(() => {});
          }
        },
        deleteEvent(){
          this.event.useruid = this.useruid;
          this.$http.post("/api/organization/events/delete"+(this.useruid==''? "" : "/byuseruid"), this.event).then((response) => {
            if (response.data.success) {
              this.back();
            }
          }).catch(() => {});
        },
        getMembers(org) {
          if(org>0){
             this.$http.post("/api/organization/members/noauth", {org:org,all:true}).then((response) => {
               if (response.data.success) {
                  this.members = response.data.members;
               }
            }).catch(() => { });
          }
           
        },
        getIsManager(){
          if(this.useruid!=''){
            this.$http.post("/api/organization/events/getismanager", {useruid: this.useruid}).then((response) => {
             if (response.data.success) {
                  this.isManager = response.data.manager;
                  if(this.event.id==0){
                    this.event.submitted_by = response.data.member_id;
                  }
                  if(this.isManager){
                    this.getMembers(response.data.organization);
                  }else{
                    this.organization_name = response.data.organization_name;
                  }
             }

            }).catch(() => { });
          }else{
            this.getMembers(this.permissions.organization.id);
            if(this.event.id==0){
              this.event.submitted_by = this.permissions.user.id;
            }
          }
           
        },
          back(){
            router.push(this.useruid==''? "/admin/events": "/events/"+this.useruid);
          },
    },
    destroyed() {
      this.removeClass(document.getElementById("menu-events"), "active-menu");
      this.removeClass(document.getElementById("menu-chapter"), "active-menu");
    },
    mounted() {
      
      if(this.$route.params.useruid){
        this.useruid = this.$route.params.useruid;
      }
      this.event.id = this.$route.params.id;
      this.getIsManager();
      this.getEvent();
      this.addClass(document.getElementById("menu-events"), "active-menu");
      this.addClass(document.getElementById("menu-chapter"), "active-menu");
    }
  }
</script>
