<template>
  <div>
    <div class="rel-grid-container">
      <div ></div>
      <form v-on:submit.prevent="updateGoal" class="form-fix">
        <span class="form-box-title">{{$t('goal.goal_text')}}</span>
        <div id="goal-form-box" class="form-box">
          <span class="form-box-name">{{$t('goal.title_text')}}</span><b-form-input :state="showSaveAlert && goal.title.length==0? false : null" v-model="goal.title" type="text" pattern="[^<>]*" :title="$t('global.pattern_title')" class="form-box-input medium-width-input" maxlength="35"></b-form-input>
          <span class="form-box-name">{{$t('goal.description_text')}}</span><b-form-textarea id="goal-description" v-model="goal.description" pattern="[^<>]*" :title="$t('global.pattern_title')" placeholder="" class="form-box-input" :rows="2"  maxlength="255"/>
          <span class="form-box-name">{{$t('goal.value_type')}}</span><b-form-select id="goal-type" v-model="goal.type" :options="value_options" value-field="id" text-field="name" class="form-box-input"/>
          <span class="form-box-name" v-if="goal.type != GOAL_TYPES.DATE_RANGE.VALUE" >{{$t('goal.target_value')}}</span><b-form-input v-if="goal.type != GOAL_TYPES.DATE_RANGE.VALUE" :disabled="goal.type==GOAL_TYPES.PERCENTAGE.VALUE" v-model="goal.target" type="number" :step="goal.type==GOAL_TYPES.DECIMAL.VALUE? .01: 1" pattern="[^<>]*" :title="$t('global.pattern_title')" class="form-box-input medium-width-input"></b-form-input> 
          <span v-if="goal.type == GOAL_TYPES.DATE_RANGE.VALUE" class="form-box-name">{{$t('goal.from_date')}}</span><DatePicker v-if="goal.type == GOAL_TYPES.DATE_RANGE.VALUE" :state="showSaveAlert && goal.start_date== null? false : null" v-model="goal.start_date" class="form-box-input input-vmargins" />
          <span v-if="goal.type == GOAL_TYPES.DATE_RANGE.VALUE" class="form-box-name">{{$t('goal.to_date')}}</span><DatePicker v-if="goal.type == GOAL_TYPES.DATE_RANGE.VALUE" :state="showSaveAlert && goal.end_date== null? false : null" v-model="goal.end_date" class="form-box-input input-vmargins" />
          <span v-if="goal.id==0 && goal.type != GOAL_TYPES.DATE_RANGE.VALUE" class="form-box-name">{{$t('goal.starting_date')}}</span><DatePicker v-if="goal.id==0 && goal.type != GOAL_TYPES.DATE_RANGE.VALUE" :state="showSaveAlert && goal.starting_date== null? false : null" v-model="goal.starting_date" class="form-box-input input-vmargins" />
          <span v-if="goal.id==0 && goal.type != GOAL_TYPES.DATE_RANGE.VALUE" class="form-box-name">{{$t('goal.starting_value')}}</span><b-form-input v-if="goal.id==0 && goal.type != GOAL_TYPES.DATE_RANGE.VALUE"  :step="goal.type==GOAL_TYPES.DECIMAL.VALUE?  .01: 1" :state="showSaveAlert && (isNaN(goal.starting_value))? false : null"  v-model="goal.starting_value" type="number" pattern="[^<>]*" :title="$t('global.pattern_title')" class="form-box-input medium-width-input" ></b-form-input> 
          <div id="goal-disabled">
          <b-form-checkbox v-model="goal.disabled" value="1" unchecked-value="0">
              {{$t('goal.disabled_text')}}
          </b-form-checkbox>
        </div>
        </div>
        <div id="goal-updates-wrapper" v-if="goal.id>0">
          <table class="table b-table table-hover-new table-bordered table-sm">
            <thead>
              <tr>
                <th class="tableHeader">{{$t('goal.last_update')}}</th>
                <th class="tableHeader" >{{$t('goal.highlight_notes')}}</th>
                <th v-if="goal.type != GOAL_TYPES.DATE_RANGE.VALUE" class="tableHeader center-text">{{$t('goal.last_value')}}</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="update in updates" v-on:click="editUpdate(update)" :key="update.id">
                <td>{{$formatDate(new Date(update.display_day))}}</td>
                <td ><pre class="pre-format" v-html="update.notes" ></pre></td>
                <td v-if="goal.type != GOAL_TYPES.DATE_RANGE.VALUE" class="center-text">{{update.value}}</td>
              </tr>
            </tbody>
          </table>
          <b-button v-if="permissions.access" class="action-button auto-margin" v-on:click="newGoalUpdate" variant="clear-blue"><div class="new-button"></div><span>{{$t('goal.new_update')}}</span></b-button>
        </div>
      <div v-if="showSaveAlert" class="goal-alert">
          <b-alert variant="danger" dismissible :show="showSaveAlert" @dismissed="showSaveAlert=false">
              {{$t('global.complete_fields')}}
          </b-alert>
      </div>
      <div v-if="showStartingAlert" class="goal-alert">
          <b-alert variant="danger" dismissible :show="showStartingAlert" @dismissed="showStartingAlert=false">
              {{$t('goal.starting_alert1')}}<br/>
              {{$t('goal.starting_alert2')}}
          </b-alert>
      </div>
      <div v-if="showStartingAlert2" class="goal-alert">
          <b-alert variant="danger" dismissible :show="showStartingAlert2" @dismissed="showStartingAlert2=false">
              {{$t('goal.starting_alert1')}}<br/>
              {{$t('goal.starting_date_alert2')}}
          </b-alert>
      </div>
      <div v-if="showNameAlert" class="goal-alert">
          <b-alert variant="danger" dismissible :show="showNameAlert" @dismissed="showNameAlert=false">
              {{$t('goal.name_alert')}}
          </b-alert>
      </div>
        <div class="submit-box-2">
          <b-btn v-b-modal.modal-center v-if="goal.id>0" class="action-button" variant="clear-blue"><div class="delete-button"></div><span>{{$t('global.button_delete')}}</span></b-btn>
          <b-button class="action-button" v-on:click="back" variant="clear-blue"><div class="cancel-button"></div><span>{{$t('global.button_cancel')}}</span></b-button>
          <b-button class="action-button" type="submit" variant="clear-blue"><div class="save-button"></div><span>{{$t('global.button_save')}}</span></b-button>
        </div>
        <b-modal id="modal-center" v-model="showDelete" centered :title="$t('goal.delete_title')">
          <p class="my-4">{{$t('goal.delete_text')}}</p>
          <div slot="modal-footer" class="submit-box-2">
            <b-button class="action-button" v-on:click="showDelete=false" variant="clear-blue"><div class="cancel-button"></div><span>{{$t('global.button_cancel')}}</span></b-button>
            <b-button class="action-button" v-on:click="deleteGoal" variant="clear-blue"><div class="delete-button"></div><span>{{$t('global.button_delete')}}</span></b-button>
          </div>
        </b-modal>
        <b-modal  v-model="showGoalUpdate" centered :title="$t('goal.update_text')">
         <span class="form-box-title">{{$t('goal.goal_update')}}</span>
            <div id="goal-update-form-box" class="form-box">
            <span class="form-box-name">{{$t('goal.day_text')}}</span>
            <div id="goal-update-datepicker"><DatePicker :state="(showUpdateAlert && !goalUpdateEdit.day)? false : null" v-model="goalUpdateEdit.day" class="input-vmargins"/></div>
            <span v-if="goal.type!=GOAL_TYPES.DATE_RANGE.VALUE" class="form-box-name">{{$t('goal.value_text')}}</span><b-form-input v-if="goal.type!=GOAL_TYPES.DATE_RANGE.VALUE" :step="goal.type==GOAL_TYPES.DECIMAL.VALUE?  .01: 1" v-model="goalUpdateEdit.value"  type="number" pattern="[^<>]*" :title="$t('global.pattern_title')" class="form-box-input medium-width-input"></b-form-input> 
            <div v-if="showAlert" class="goal-alert">
                <b-alert variant="warning"
                        dismissible
                        :show="showAlert"
                        @dismissed="showAlert=false">
                    {{alertText}}
                </b-alert>
            </div>
            <div v-if="showUpdateAlert" class="goal-alert">
              <b-alert variant="danger"
                      dismissible
                      :show="showUpdateAlert"
                      @dismissed="showUpdateAlert=false">
                  {{$t('global.complete_fields')}}
              </b-alert>
          </div>
            <div id="goal-update-notes">
              <BaseEditor v-model="goalUpdateEdit.notes" />
            </div>
            
          </div>
          
          <div slot="modal-footer" class="submit-box-2">
            <b-btn v-if="goalUpdateEdit.id>0" v-on:click="deleteGoalUpdate(goalUpdateEdit.id)"  class="action-button" variant="clear-blue"><div class="delete-button"></div><span>{{$t('global.button_delete')}}</span></b-btn>
            <b-button class="action-button" v-on:click="showGoalUpdate=false" variant="clear-blue"><div class="cancel-button"></div><span>{{$t('global.button_cancel')}}</span></b-button>
            <b-button class="action-button" v-on:click="updateGoalUpdate(goalUpdateEdit)" variant="clear-blue"><div class="save-button"></div><span>{{$t('global.button_save')}}</span></b-button>
          </div>
        </b-modal>
      </form>
      <div class="div-space"></div>
    </div>
  </div>
</template>
<style scoped>
#goal-updates-wrapper{
  margin: 30px 0px;
}
#goal-disabled{
  grid-column: 2/4
}
#goal-type{
  max-width: 150px;
}
#goal-description{
  margin-bottom: 10px;
}
#goal-update-datepicker{
   grid-column: 2/4;
}
#goal-update-form-box{
  grid-template-columns: 25% 25% 50%;
}
#goal-update-notes{
  grid-column:1/4; margin-top: 30px;
}
.goal-alert{
  margin-top:10px;grid-column:1/4;
}
#goal-form-box{
  grid-template-columns: 25% 25% 50%;
}
 
</style>
<script>
  import DatePicker from "../components/common/DatePicker.vue";
  import router from "../router"
  import { GOALS_VARIABLES } from "../constants/slideConstants";
    export default {
    name: "Goal",
    components: {
      DatePicker
    },
    watch: {
      'goal.type': {
        handler: function (v) {
            if(v == 1){
                this.goal.target = 100;
            }
        },
        deep: true
      },
      'goalUpdateEdit.value': {
        handler: function (v) {
            if(v > this.goal.target){
                this.showAlert = true;
                this.alertText = this.$t('goal.alert_text1');
            }else if(v < 0){
                this.showAlert = true;
                this.alertText = this.$t('goal.alert_text2');
            }else{
                this.showAlert = false;
            }
        },
        deep: true
      },
      '$i18n.locale': {
        handler: function () {
          this.value_options= Object.values(GOALS_VARIABLES.TYPES).map(type => ({ id: type.VALUE, name: this.$t(type.TRANSLATION_KEY) }));
        },
        deep: true
      }
    },
      data() {
        return {
          showNameAlert: false,
          showSaveAlert: false,
          showUpdateAlert: false,
          meet_day_of_week: 0,
          alertText: "",
          showAlert: false,
          showStartingAlert: false,
          showStartingAlert2: false,
          showDelete: false,
          saveVariant : 'blue',
          goal: {id:0, title: "", description: "", type: GOALS_VARIABLES.TYPES.NUMBER.VALUE, target: 0, disabled: 0, starting_date: null, starting_value: 0, start_date: null, end_date: null},
          GOAL_TYPES: GOALS_VARIABLES.TYPES, // holds the goal types
          // creates list of goal types value for options
          // id: value of the goal type, name: i18n translated value of goal type
          value_options: Object.values(GOALS_VARIABLES.TYPES).map(type => ({ id: type.VALUE, name: this.$t(type.TRANSLATION_KEY) })),
          updates: [],
          showGoalUpdate: false,
          goalUpdateEdit: {id:0, goal_id:0, day: new Date(), notes: '', value: 0}
        }
      },
    methods: {
      dateDisabled(ymd, date) {
        const weekday = date.getDay();
        return weekday !== this.meet_day_of_week;
      },
    getMeetDay() {
        this.$http.post("/api/organization/get/meet_day_of_week", {}).then((response) => {
          if (response.data.success === true) {
            this.meet_day_of_week = (response.data.meet_day_of_week + 1) % 7;
          }
        }).catch(() => { });
      },
      getGoal() {
        this.goal.id = this.$route.params.id;
        if (this.goal.id > 0) {
          this.$http.post("/api/goal/get", this.goal).then((response) => {
            if (response.data.success) {
              this.goal = response.data.goal;
            }
          }).catch(() => { });
        }
      },
      updateGoal() {
        this.showNameAlert = false;
        this.showSaveAlert = false;
        this.showStartingAlert=false;
        this.showStartingAlert2=false;
        if(this.goal.title.length==0 || (this.goal.type!=this.GOAL_TYPES.DATE_RANGE.VALUE && (this.goal.id==0 && (this.goal.starting_date== null  || isNaN(this.goal.starting_value))))){
          this.showSaveAlert = true;
          return;
        }
        if(this.goal.type == this.GOAL_TYPES.DATE_RANGE.VALUE && this.goal.id == 0 && (this.goal.start_date == null || this.goal.end_date == null)){
          this.showSaveAlert = true;
          return;
        }
         if((this.goal.type!=this.GOAL_TYPES.DATE_RANGE.VALUE && parseInt(this.goal.starting_value)> parseInt(this.goal.target)) ){
          this.showStartingAlert = true;
          return;
        }
        if((this.goal.type==this.GOAL_TYPES.DATE_RANGE.VALUE && new Date(this.goal.start_date)> new Date(this.goal.end_date)) ){
          this.showStartingAlert2 = true;
          return;
        }
        if(!(this.goal.target)){
          this.goal.target = 0;
        }
        this.$http.post("/api/goal/update", {
          ...this.goal,
          starting_date: this.$formatDate(this.goal.starting_date, 'iso_date'),
          start_date: this.$formatDate(this.goal.start_date, 'iso_date'),
          end_date: this.$formatDate(this.goal.end_date, 'iso_date')
        }).then((response) => {
          if (response.data.success === true) {
            this.back();
          } else if(response.data.error==1){
            this.showNameAlert = true;
          }
        }).catch(() => { this.$bvToast.show('serverError');});
      },
      deleteGoal() {
        this.$http.post("/api/goal/delete", this.goal).then((response) => {
          if (response.data.success === true) {
            this.back();
          }
        }).catch(() => { });
      },
      getGoalUpdates() {
        this.goal.id = this.$route.params.id;
        if (this.goal.id > 0) {
          this.$http.post("/api/goal/updates/get", this.goal).then((response) => {
            if (response.data.success) {
                for(var i=0; i<response.data.updates.length; i++){
                    var t = response.data.updates[i].day.split("-").join("/");
                    var d = new Date(t+" 00:01");
                    response.data.updates[i].display_day = d.setMinutes(d.getMinutes()+d.getTimezoneOffset());
                }
              this.updates = response.data.updates;
            }
          }).catch(() => { });
        }
      },
      updateGoalUpdate(update) {
        this.showUpdateAlert = false;
        if(!update.day){
          this.showUpdateAlert = true;
          return;
        }
        update.day = this.$formatDate(update.day, 'iso_date');
        this.$http.post("/api/goal/updates/update", update).then((response) => {
          if (response.data.success === true) {
            if(update.id == 0){
                //this.updates.push(update);
            }
            var d = new Date(update.day.split("-").join("/")+" 00:01");
            update.display_day = d.setMinutes(d.getMinutes()+d.getTimezoneOffset());
            update.id = response.data.inserted_id;
            this.showGoalUpdate = false;
            this.getGoalUpdates();
          }
        }).catch(() => {this.$bvToast.show('serverError'); });
      },
      deleteGoalUpdate(id) {
        this.$http.post("/api/goal/updates/delete", {id:id}).then(() => {
          this.showGoalUpdate = false;
          this.getGoalUpdates();
        }).catch(() => { this.$bvToast.show('serverError');});
      },
      editUpdate(update){
        this.goalUpdateEdit = update;
        this.showGoalUpdate = true;
      },
      newGoalUpdate(){
        this.showGoalUpdate = true;
        this.goalUpdateEdit = {id:0, goal_id: this.goal.id, day: '', notes: '', value: 0};
      },
      back() {
        window.history.length > 1 ? router.go(-1) : router.push("/admin/goals");
      }
    },
    destroyed() {
      this.removeClass(document.getElementById("menu-goals"), "active-menu");
      this.removeClass(document.getElementById("menu-chapter"), "active-menu");
    },
    mounted() {
      this.addClass(document.getElementById("menu-goals"), "active-menu");
      this.addClass(document.getElementById("menu-chapter"), "active-menu");
      this.getGoal();
      this.getGoalUpdates();
      this.getMeetDay();
      }
    }
</script>
