/**
 * Slide Variable constants
 * 
 * This module defines constants related to slide variables.
 */

// The SLIDE_VARIABLE_TYPES object defines constants for different types of slide variables supported by the system
export const SLIDE_VARIABLE_TYPES = Object.freeze({
    TEXT: 1,
    IMAGE: 2
});


/**
 * Variables for Event slides
 * 
 * The EVENT_VARIABLES object holds variables that are specifically for event-related slides.
 */
export const EVENT_VARIABLES = Object.freeze({
    LINK: {
        ID: 'event_registration_link', // Unique id for the variable. Also used as a translation key in i18n for the variable
        PLACEHOLDER: '{Event_Link_QRCode}' // this gets replaced by actual value during the meeting.
    }
})


/**
 * Variables for Goals
 * 
 * The GOALS_VARIABLES object holds variables that are specifically for goals.
 */
export const GOALS_VARIABLES = Object.freeze({
    TYPES: {
        NUMBER: { // type: Number
            VALUE: 0, // Value used for the type
            TRANSLATION_KEY: 'goal.value_option1' // keys used by i18n to get the translation value
        },
        PERCENTAGE: { // type: Percentage
            VALUE: 1, // Value used for the type
            TRANSLATION_KEY: 'goal.value_option2' // keys used by i18n to get the translation value
        },
        CURRENCY: { // type: Currency
            VALUE: 2, // Value used for the type
            TRANSLATION_KEY: 'goal.value_option3' // keys used by i18n to get the translation value
        },
        DECIMAL: { // type: Decimal
            VALUE: 3, // Value used for the type
            TRANSLATION_KEY: 'goal.value_option4' // keys used by i18n to get the translation value
        },
        DATE_RANGE: { // type: Date Range
            VALUE: 4, // Value used for the type
            TRANSLATION_KEY: 'goal.value_option5' // keys used by i18n to get the translation value
        }
    },
});


/**
 * The default member configuration for a new member slide
 */
export const MEMBER_DESCRIPTION_DEFAULT = Object.freeze({
    id:0, type: -1, member_id: 0, role_id: "0", name_font: "Arial", name_height: 5, name_color: "#000000", name_weight: 600,
    show_company: 1, show_category: 1, selection: 1,circular: 0,
    company_font: "Arial", company_height: 5, company_color: "#000000", company_weight: 300, category_font: "Arial", 
    x_pos: 45, y_pos: 30, width:20,height:20, x_px: 0, y_px: 0,
    category_height: 5, category_color: "#000000", category_weight: 300, name_back_color: "#000000",
    num_rows_x: 1, num_rows_y: 1, speaker_num: 1, company_or_role: 0, first_name: 0,
    next: {x_pos: 40, y_pos: 70,height: 10}, next_color: '#000000', next_font: 'Arial', alignment: 'left',
    graphic: {x_pos: 25, y_pos: 25, height: 50,width:17.3}, graphic_height:50, graphic_width:17.3,alternate_slide_id: 0,
    email_font:'Arial',email_height: 5,email_color: "#000000",email_weight: 300,show_email: 1,website_font: 'Arial',
    website_height: 5,website_color: "#000000",website_weight: 300,show_website: 1,
    phone_font: 'Arial',phone_height: 5,phone_color: "#000000",phone_weight: 300,show_phone: 1,next_number: 1,
    show_renewing_date: 0,best_weekly: 0, show_photo: 1, job_title_font: 'Arial',job_title_height: 5,
    job_title_color: "#000000",job_title_weight: 300,job_title_show: 0,show_accent: 1, multi_vertical: 1,
    show_substitute: 0, substitute_font: 'Arial',substitute_height: 5,substitute_color: "#000000",substitute_weight: 300
})


/**
 * Meeting Attendee Types 
 */
export const ATTENDEE_TYPE = Object.freeze({
    SUBSTITUTE: 1,
    VISITOR: 3,
    OBSERVER: 4,
    REGIONAL_CONTACT: 5
});