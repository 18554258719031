<template>
    <div class="pt-4">
      <b-row class="justify-content-center mx-0">
        <b-col sm="12" md="10" lg="6">
          <b-table class="scrollable-container" sticky-header="80dvh" bordered :items="languages" :fields="fields" />
        </b-col>
      </b-row>
    </div>
</template>
<script>
  export default {
    name: "Language",
    data() {
      return {
        languages: [],
        fields: [
            {
              key: 'name',
              label: this.$t('language.name_text'),
              thStyle: { "background-color":  process.env.VUE_APP_TABLE_HEADER,"color": "#ffffff"},
            },
            { key: 'locale',
              label: this.$t('language.abbrev_text'),
              thStyle: { "background-color":  process.env.VUE_APP_TABLE_HEADER,"color": "#ffffff" }
            }
          ],
      }
    },
    created() {
      // load the languages
      this.getLanguages();
    },
    /**
     * When the component is mounted, this method adds the active-menu class to the language menu element
     * and the admin menu element.
     */
    mounted() {
      this.addClass(document.getElementById("menu-languages"), "active-menu");
      this.addClass(document.getElementById("menu-admin"), "active-menu");
    },
    methods: {
      /**
       * Calls the API to get the list of languages and stores them in languages.
       */
      getLanguages() {
        this.$http.post("/api/superadmin/languages", {}).then((response) => {
          if (response.data.success) {
            this.languages = response.data.languages;
          }
        })
      }
    },
    destroyed() {
      this.removeClass(document.getElementById("menu-languages"), "active-menu");
      this.removeClass(document.getElementById("menu-admin"), "active-menu");
    }
  }
</script>
<style scoped>
.scrollable-container {
  overflow-y: scroll;
}

/* Custom Scrollbar */
.scrollable-container::-webkit-scrollbar {
  width: 8px;
}

.scrollable-container::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.scrollable-container::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 10px;
}

.scrollable-container::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}
</style>