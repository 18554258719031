<template>
  <div>
    <div id="regional-page-wrapper" class="rel-grid-container" >
      <div ></div>
      <form v-on:submit.prevent="updateRegionalSettings" class="form-fix">
        <span class="form-box-title">{{$t('general.general_settings')}}</span>
        <div v-if="permissions.organization.official" class="form-box grid-fitfirst-2column">
          <SetupTip class="setup-tip" :tipNumber="8"/>
          <span class="form-box-name">{{$t('general.default_language')}}</span>
          <b-form-select id="regional-name-input" v-model="regional.default_lang" :options="languageOptions" value-field="id" text-field="name"/>  
          <span class="form-box-name">{{$t('general.time_hr_format')}}</span>
          <div class="time-format-radios-group">
            <b-form-radio v-model="regional.time_format_12_hr" name="time-format-radios" value="0">24 hr</b-form-radio>
            <b-form-radio v-model="regional.time_format_12_hr" name="time-format-radios" value="1">12 hr</b-form-radio>
          </div>
          <span class="form-box-name">{{$t('general.year_end')}}</span>
            <div class="grid-fit-2column months-select" ><b-form-select  v-model="regional.month" :options="monthOptions" /><b-form-select  v-model="regional.day" :options="dayOptions"  /></div>
            <div v-if="!isCorporateConnections" id="transition-box">
                <b-form-checkbox class="auto-margin" v-model="regional.transition_from_show">
                  {{$t('general.show_transition')}}
                </b-form-checkbox>
              <span v-if="regional.transition_from_show" class="form-box-name">{{$t('general.from_text')}}</span>
              <div v-if="regional.transition_from_show" class="grid-fit-2column months-select"><b-form-select  v-model="regional.transition_month" :options="monthOptions" /><b-form-select  v-model="regional.transition_day" :options="fromDayOptions"  /></div>

              <span v-if="regional.transition_from_show"  class="form-box-name">{{$t('general.to_text')}}</span>
              <div v-if="regional.transition_from_show" class="grid-fit-2column months-select"><b-form-select  v-model="regional.transition_to_month" :options="monthOptions" /><b-form-select  v-model="regional.transition_to_day" :options="toDayOptions"  /></div>
            </div>
             <span class="form-box-name">{{$t('general.days_closed')}}</span>
            <multiselect id="weeks-select" v-model="regional.banned_weeks" :options="weeks" :placeholder="$t('general.select_days')" label="text" track-by="text" class="blueselect mb-3" :noOptionsText="$t('global.empty_list')"  mode="tags" valueProp="id" :close-on-select="false" :searchable="true">
            </multiselect>
            
        </div>
        <span class="form-box-title">{{$t('country.timezones_text')}}</span>
        <div class="form-box grid-auto-1column">
            <multiselect v-model="timezones" :style="{border : timezones.length == 0? '1px solid red' : ''}" :options="timezoneOptions" :placeholder="$t('country.choose_timezone')" label="name" track-by="name" class="blueselect country-multiselect" :noOptionsText="$t('global.empty_list')"  mode="tags" valueProp="value" :close-on-select="false" :searchable="true">
          </multiselect>
        </div>
        <span class="form-box-title" v-if="permissions.organization.official">{{$t('general.meeting_settings')}}</span>
        <div v-if="permissions.organization.official" class="form-box grid-fitfirst-2column">
          <span class="form-box-name">{{$t('region.event_link')}}</span><b-form-input  v-model="regional.event_link" type="text" pattern="[^<>]*" :title="$t('global.pattern_title')" ></b-form-input>
            <span v-if="!isCorporateConnections"  class="form-box-name first-column">{{$t('general.report_periods')}}</span>
            <multiselect v-if="!isCorporateConnections" :style="{border : tracking_nums.length == 0? '1px solid red' : ''}" id="tracking-select" class="mb-3 blueselect" v-model="tracking_nums" :options="trackingOptions" :placeholder="$t('general.select_periods')" label="text" track-by="text" :noOptionsText="$t('global.empty_list')"  mode="tags" valueProp="value" :close-on-select="false" :searchable="true">
            </multiselect>
            <span v-if="!isCorporateConnections"  class="form-box-name">{{$t('general.categories_num')}}</span>
            <div v-if="!isCorporateConnections" class="grid-fitfirst-2column">
              <b-form-input v-model="regional.categories_num" type="number" class="regional-small-input" min="0"  required/>
              <div id="categories-note">*{{$t('general.categories_note')}}</div>
            </div>
            <!--<span class="form-box-name">{{$t('general.qr_code')}}</span><b-form-input  v-model="regional.apply_url" type="text" pattern="[^<>]*" :title="$t('global.pattern_title')" class="medium-width-input" ></b-form-input> <span class="form-box-name">{{$t('general.featured_presentation')}}</span>
            <div class="grid-fit-2column">
              <div class="time-box">
                <span class="form-box-name">{{$t('general.min_text')}}</span>
                <b-form-input v-model="regional.regional_presentation_min" type="number" class="regional-small-input" />
              </div>
              <div class="time-box">
                <span class="form-box-name">{{$t('general.sec_text')}}</span>
                <b-form-input v-model="regional.regional_presentation_sec" type="number" class="regional-small-input" />
              </div>
            </div>-->
            <span class="form-box-name" v-if="isCorporateConnections">{{$t('region.assistant_expiry_minutes')}}</span>
            <b-form-input v-if="isCorporateConnections" v-model="regional.assistant_expiry_minutes" type="number" min="0" class="regional-small-input" required></b-form-input>
            <span v-if="!isCorporateConnections"  class="form-box-name" id="meeting-content">{{$t('region.meeting_content')}}</span>
            <div v-if="!isCorporateConnections" >
              <b-form-checkbox v-model="regional.allow_powerteams" :value="1" :unchecked-value="0" class="valign-margin second-column">
                {{$t('general.enable_powerteams')}}
              </b-form-checkbox>
              <b-form-checkbox v-model="regional.allow_top_performing" :value="1" :unchecked-value="0" class="valign-margin second-column">
                {{$t('general.enable_performers')}}
              </b-form-checkbox>
              <b-form-checkbox v-model="regional.use_taglines" :value="1" :unchecked-value="0" class="valign-margin second-column">
                {{$t('general.use_taglines')}}
              </b-form-checkbox>
              <div class="grid-fit-2column">
                <span class="form-box-name" v-if="regional.use_taglines==1">{{$t('region.tagline_chars')}}</span>
                <b-form-input v-if="regional.use_taglines==1" v-model="regional.tagline_chars" type="num" pattern="[^<>]*" :title="$t('global.pattern_title')" class="regional-small-input" ></b-form-input>
              </div>
              <b-form-checkbox v-model="regional.split_referrals" :value="1" :unchecked-value="0" class="valign-margin second-column">
                {{$t('region.split_referrals')}}
              </b-form-checkbox>
              <b-form-checkbox v-if="regional.split_referrals==1" v-model="regional.show_single_referrals" :value="1" :unchecked-value="0" class="valign-margin second-column">
                {{$t('region.show_single_referrals')}}
              </b-form-checkbox>
              <b-form-checkbox v-model="regional.allow_renewing_date" :value="1" :unchecked-value="0" class="valign-margin second-column">
                {{$t('region.allow_renewing_date')}}
              </b-form-checkbox>
              <b-form-checkbox v-model="regional.allow_logos" :value="1" :unchecked-value="0" class="valign-margin second-column">
                {{$t('region.allow_logos')}}
              </b-form-checkbox>
              <b-form-checkbox v-model="regional.show_bni_accent" :value="1" :unchecked-value="0" class="valign-margin second-column">
                {{$t('region.show_bni_accent')}}
              </b-form-checkbox>
              <b-form-checkbox v-model="regional.require_consent" :value="1" :unchecked-value="0" class="valign-margin second-column">
                {{$t('region.require_consent')}}
              </b-form-checkbox>
              <b-form-checkbox v-if="permissions.organization.subscription_status==RegionalStatusTypes.PILOT.VALUE" v-model="regional.allow_visitor_photos" :value="1" :unchecked-value="0" class="valign-margin second-column">
                {{$t('region.allow_visitor_photos')}}
              </b-form-checkbox>
              <b-form-checkbox v-model="regional.send_top_cat" :value="1" :unchecked-value="0" class="valign-margin second-column">
                {{$t('region.send_top_cat')}}
              </b-form-checkbox>
              <b-form-checkbox v-model="regional.enable_planner_confirmation" :value="1" :unchecked-value="0" class="valign-margin second-column">
                {{$t('region.enable_planner_confirmation')}}
              </b-form-checkbox>
            </div>
            <b-form-checkbox v-if="isCorporateConnections" v-model="regional.require_consent" :value="1" :unchecked-value="0" class="valign-margin second-column">
                {{$t('region.require_consent')}}
              </b-form-checkbox>
            <b-form-checkbox v-if="isCorporateConnections" v-model="regional.allow_visitor_photos" :value="1" :unchecked-value="0" class="valign-margin second-column">
                {{$t('region.allow_visitor_photos')}}
              </b-form-checkbox>
        </div>
        <div v-if="!permissions.organization.official" class="form-box grid-fitfirst-2column">
          <span class="form-box-name">{{$t('region.min_applicants')}}</span><b-form-input v-model="regional.min_applicants" type="number" min="0" class="medium-width-input"  required></b-form-input>
          <b-form-checkbox v-model="regional.applicant_photos" :value="1" :unchecked-value="0" class="valign-margin second-column">
              {{$t('region.applicant_photos')}}
            </b-form-checkbox>
          <div class="grid-fitfirst-2column column-span2">
            <span class="form-box-name column-span2 left-text">{{$t('region.weekly_requests')}}</span>
            <span class="form-box-name">{{$t('region.block_title')}}</span><b-form-input id="newsletter-title" v-model="regional.newsletter_ask_title" type="text" pattern="[^<>]*" max="255" :title="$t('global.pattern_title')"></b-form-input>
            <b-form-radio v-model="regional.newsletter_ask_column" :value="0" :unchecked-value="1" >
                {{$t('region.list_city')}}
              </b-form-radio>
              <b-form-radio id="list-company-checkbox" v-model="regional.newsletter_ask_column" :value="1" :unchecked-value="0" >
                {{$t('region.list_company')}}
              </b-form-radio>
            </div>
        </div>

        <div v-if="isCorporateConnections" >
            <span class="form-box-title">{{$t('general.region_mission')}}</span>
            <div class="form-box grid-auto-1column">
                <b-form-textarea v-model="regional.mission_statement" placeholder="" :rows="3"/>
            </div>
          </div>

        <div v-if="permissions.organization.official" >
            <span class="form-box-title">{{$t('general.social_media_links')}}</span>
            <div id="media-form-box" class="form-box">
                <span class="form-box-name">{{$t('general.add_channel')}}</span>
                <multiselect class="blueselect media-select" v-model="media_links" :options="mediaOptions" label="name" :placeholder="$t('global.select_members')" :noOptionsText="$t('global.empty_list')" :object="true"  mode="tags" trackBy="name" valueProp="channel_id" :close-on-select="false" :searchable="true">
                    <template v-slot:tag="{ option, handleTagRemove}">
                      <div class="multiselect-tag is-user">
                        {{ option.name }}
                        <span class="multiselect-tag-remove" @click="handleTagRemove(option, $event)">
                        <span class="multiselect-tag-remove-icon"></span>
                        </span>
                      </div>
                    </template>
                    <template v-slot:option="{ option }">
                      <div><img class="regional-media-image" :src="option.image"> <span>{{ option.name  }}</span></div>
                    </template>
                  </multiselect>
                <span class="form-box-name column-span2 center-text">{{$t('general.selected_media')}}</span>
                <table class="table b-table table-sm table-hover-new column-span2">
                  <thead>
                    <tr>
                      <th >{{$t('general.channel_header')}}</th>
                      <th  class="left-text" >{{$t('general.url_header')}}</th>
                      <th>{{$t('general.remove_header')}}</th>
                    </tr>
                  </thead>
                  <draggable tag="tbody" class="column-span2" v-model="media_links" item-key="id">
                    <template #item="{element}">
                      <tr>
                        <td><img class="regional-media-image" :src="element.image"></td>
                        <td><b-form-input v-model="element.link" type="text" pattern="[^<>]*" :title="$t('global.pattern_title')" class="media-link{" maxlength="255"></b-form-input></td>
                        <td><b-button class="trash-button" variant="blue" v-on:click="deleteLink(element)"></b-button></td>
                      </tr>
                    </template>
                  </draggable>
                </table>
            </div>
          </div>

        <span v-if="permissions.organization.official" class="form-box-title">{{$t('region.admin_logins')}}</span>
        <div v-if="permissions.organization.official" id="region-box" class="form-box">
            <span class="form-box-name left-text">{{$t('region.chapter_meetings')}}</span>
            <div class="child-region-box">
              <b-form-input :disabled="true" :state="admin.chapter.name.length>0? null : false" v-model="admin.chapter.name" :placeholder="this.$t('global.username_text')" type="text" pattern="[^<>]*" :title="$t('global.pattern_title')" class="medium-width-input"  required></b-form-input>
              <b-form-input v-model="admin.chapter.email" :placeholder="this.$t('global.email_text')" type="email" pattern="[^<>]*" :title="$t('global.pattern_title')" class="medium-width-input"  ></b-form-input>
              <b-form-input :state="(admin.chapter.password.length==0 || admin.chapter.password.length>8)? null : false" v-model="admin.chapter.password" :placeholder="this.$t('global.password_text')" type="password" class="medium-width-input" ></b-form-input>
              <b-form-input v-if="admin.chapter.password && admin.chapter.password.length>0" :placeholder="this.$t('global.retype_password')" class="medium-width-input" :state="admin.chapter.password===admin.chapter.password_confirm && admin.chapter.password_confirm.length>0" v-model="admin.chapter.password_confirm" type="password"  ></b-form-input>
            </div>
            <br/>
            <span v-if="admin.has_startup==1" class="form-box-name left-text">{{$t('region.startup_meetings')}}</span>
            <div v-if="admin.has_startup==1" class="child-region-box">
              <b-form-input :disabled="true" :state="admin.startup.name.length>0? null : false" v-model="admin.startup.name" :placeholder="this.$t('global.username_text')" type="text" pattern="[^<>]*" :title="$t('global.pattern_title')" class="medium-width-input"  required></b-form-input>
              <b-form-input v-model="admin.startup.email" :placeholder="this.$t('global.email_text')" type="email" pattern="[^<>]*" :title="$t('global.pattern_title')" class="medium-width-input"  ></b-form-input>
              <b-form-input v-model="admin.startup.password" :state="(admin.startup.password.length==0 || admin.startup.password.length>8)? null : false" :placeholder="this.$t('global.password_text')" type="password" class="medium-width-input"  ></b-form-input>
              <b-form-input v-if="admin.startup.password && admin.startup.password.length>0" :placeholder="this.$t('global.retype_password')"  class="medium-width-input" :state="admin.startup.password===admin.startup.password_confirm && admin.startup.password_confirm.length>0" v-model="admin.startup.password_confirm" type="password" ></b-form-input>
            </div>
            <span v-if="admin.has_rst==1" class="form-box-name left-text">{{$t('region.rst_meetings')}}</span>
            <div v-if="admin.has_rst==1" class="child-region-box">
              <b-form-input :disabled="true" :state="admin.rst.name.length>0? null : false" v-model="admin.rst.name" :placeholder="this.$t('global.username_text')" type="text" pattern="[^<>]*" :title="$t('global.pattern_title')" class="medium-width-input" required></b-form-input>
              <b-form-input v-model="admin.rst.email" :placeholder="this.$t('global.email_text')" type="email" pattern="[^<>]*" :title="$t('global.pattern_title')" class="medium-width-input"  ></b-form-input>
              <b-form-input v-model="admin.rst.password" :state="(admin.rst.password.length==0 || admin.rst.password.length>8)? null : false" :placeholder="this.$t('global.password_text')" type="password" class="medium-width-input"   ></b-form-input>
              <b-form-input v-if="admin.rst.password && admin.rst.password.length>0" :placeholder="this.$t('global.retype_password')" class="medium-width-input"  :state="admin.rst.password===admin.rst.password_confirm && admin.rst.password_confirm.length>0" v-model="admin.rst.password_confirm" type="password"  ></b-form-input>
            </div>
         </div>
            <div v-if="showAlert">
            <b-alert variant="danger" dismissible :show="showAlert" @dismissed="showAlert=false">
              {{alertText}}
            </b-alert>
          </div>
            <div class="submit-box-2">
            <b-button class="action-button" v-on:click="back" variant="clear-blue"><div class="cancel-button"></div><span>{{$t('global.button_cancel')}}</span></b-button>
            <b-button class="action-button" type="submit" variant="clear-blue"><div class="save-button"></div><span>{{$t('global.button_save')}}</span></b-button>
        </div>
      </form>
      <div class="div-space"></div>
    </div>
  </div>
</template>

<style scoped>
.time-format-radios-group {
  display: flex;
  align-items: center;
  gap: 12px;
  padding-inline: 8px;
}
.child-region-box{
  display:grid;grid-gap: 5px;
}
.media-link{
  max-width: 200px;
}
#list-company-checkbox{
  margin-left:30px;
}
#newsletter-title{
  width:300px;
}
#media-form-box{
  grid-template-columns: auto 400px;
}
.regional-media-image{
  height:40px;
}
#meeting-content{
  margin: 0 5px;
}
.regional-small-input{
  width:100px;
}
#transition-box{
  display:grid;grid-column:1/3;grid-template-columns: repeat(5,max-content);
}
#regional-name-input{
  width:300px;
}
.months-select{
  grid-gap: 20px;margin-top: 5px;
}
#regional-page-wrapper{
  min-width: 400px;
  max-width: 600px;
}
#categories-note{
  font-size:14px;color:#aaaaaa;margin:10px; margin-right:0px;text-align:left;
}
#region-box{
  grid-template-columns: repeat(1,1fr);
}
#tracking-select{
  margin-top:5px;
}
#weeks-select{
  margin: 5px 0px;
}
#media-select{
  min-width:400px;
}
  #member-photo {
    max-height: 50px;
    max-width: 100px;
    border: 1px gray solid;
  }
</style>
<script>
    
  import router from "../router"
  import { REGIONAL_STATUS_TYPES } from "../constants/appConstants"
    export default {
    name: "Regional",
      data() {
        return {
          RegionalStatusTypes: REGIONAL_STATUS_TYPES,
          dayOptions: [],
          fromDayOptions: [],
          toDayOptions: [],
          timezones: [],
          timezoneOptions: [],
          languageOptions: [],
          media_links: [],
          mediaOptions: [],
          admin: {has_startup: 1, has_rst: 1, email: '', chapter: {org_id: 0,id: 0, name: '',email: '', password: '', has_pass: false}, startup: {org_id: 0,id: 0, name: '',email: '', password: '', has_pass: false}, rst: {org_id: 0,id: 0, name: '',email: '', password: '', has_pass: false}},
          showDelete: false,
          saveVariant: "blue",
          regional: {tagline_chars: 65, enable_planner_confirmation: 0, timezones: [], default_lang: null, time_format_12_hr: 0, enabled_speaker_planner:0,assistant_expiry_minutes: 30, show_single_referrals: 0, use_taglines:0, transition_from_show: true, send_top_cat: 0, show_bni_accent: 0, allow_visitor_photos: 0, allow_renewing_date: 0, allow_logos: 0, allow_top_performing: 0, official_region: 0,regional_presentation_min: 10, regional_presentation_sec: 0,  min_applicants: 25, categories_num: 0, name: '', event_link: '',banned_weeks: [], year_end: '2020-12-31', discounted_dates: "", name_state:null, month: 12, day: 31, tracking_periods: 0, transition_month: 1, transition_day: 1, transition_to_month: 1, transition_to_day: 1, newsletter_ask_title: 'Weekly Requests and Referrals', newsletter_ask_column: 0},
          weeks: [],
          monthOptions: [{value: 1, text: this.$t('global.january')},{value: 2, text: this.$t('global.february')},{value: 3, text: this.$t('global.march')},
          {value: 4, text: this.$t('global.april')},{value: 5, text: this.$t('global.may')},{value: 6, text: this.$t('global.june')},
          {value: 7, text: this.$t('global.july')},{value: 8, text: this.$t('global.august')},{value: 9, text: this.$t('global.september')},
          {value: 10, text: this.$t('global.october')},{value: 11, text: this.$t('global.november')},{value: 12, text: this.$t('global.december')}],
          trackingOptions: [
          { value: 1, text: this.$t('general.tracking_option_1') },
          { value: 2, text: this.$t('general.tracking_option_2') },
          { value: 8, text: this.$t('general.tracking_option_8') },
          { value: 16, text: this.$t('general.tracking_option_16') },
          { value: 32, text: this.$t('general.tracking_option_32') },
          { value: 64, text: this.$t('general.tracking_option_64') },
        ],
        tracking_nums: [],
        showAlert: false,
        alertText: "",
        showPhotoEdit: false,
        tempPhoto: "",
        imgSrc: '',
        timezoneNames : [
          { name: "(GMT -12:00) Eniwetok, Kwajalein", value: "546" },
          { name: "(GMT -11:00) Midway Island, Samoa", value: "562" },
          { name: "(GMT -10:00) Hawaii", value: "583" },
          { name: "(GMT -9:30) Taiohae", value: "548" },
          { name: "(GMT -9:00) Alaska", value: "577" },
          { name: this.$t('timezone.pacific'), value: "379" },
          { name: this.$t('timezone.mountain'), value: "377" },
          { name: this.$t('timezone.central'), value: "375" },
          { name: this.$t('timezone.eastern'), value: "376" },
          { name: this.$t('timezone.atlantic'), value: "374" },
           { name: this.$t('timezone.eastern_daylight'), value: "213" },
          { name: "Newfoundland Time", value: "378" },
          { name: "(GMT -3:00) Brazil, Buenos Aires, Georgetown", value: "86" },
          { name: "(GMT -2:00) Mid-Atlantic", value: "411" },
          { name: "(GMT -1:00) Azores, Cape Verde Islands", value: "336"},
          { name: "(GMT) Western Europe Time, London, Lisbon, Casablanca", value: "451" },
          { name: "(GMT +1:00) Brussels, Copenhagen, Madrid, Paris", value: "433" },
          { name: "(GMT +2:00) Kaliningrad, South Africa", value: "446" },
          { name: "(GMT +3:00) Baghdad, Riyadh, Moscow, St. Petersburg", value: "458" },
          { name: "(GMT +3:30) Tehran", value: "316" },
          { name: "(GMT +4:00) Abu Dhabi, Muscat, Baku, Tbilisi", value: "245" },
          { name: "(GMT +4:30) Kabul", value: "275" },
          { name: "(GMT +5:00) Ekaterinburg, Islamabad, Karachi, Tashkent", value: "277" },
          { name: "(GMT +5:30) Bombay, Calcutta, Madras, New Delhi", value: "251" },
          { name: "(GMT +5:45) Kathmandu, Pokhara", value: "279" },
          { name: "(GMT +6:00) Almaty, Dhaka, Colombo", value: "259" },
          { name: "(GMT +6:30) Yangon, Mandalay", value: "330" },
          { name: "(GMT +7:00) Bangkok, Hanoi, Jakarta", value: "246" },
          { name: "(GMT +8:00) Beijing, Perth, Singapore, Hong Kong", value: "360" },
          { name: "(GMT +8:45) Eucla", value: "352" },
          { name: "(GMT +9:00) Tokyo, Seoul, Osaka, Sapporo, Yakutsk", value: "309" },
          { name: "(GMT +9:30) Adelaide, Darwin", value: "320" },
          { name: "(GMT +10:00) Eastern Australia, Guam, Vladivostok", value: "328" },
          { name: "(GMT +10:30) Lord Howe Island", value: "356" },
          { name: "(GMT +11:00) Magadan, Solomon Islands, New Caledonia", value: "394" },
          { name: "(GMT +11:30) Norfolk Island", value: "552" },
          { name: "(GMT +12:00) Auckland, Wellington, Fiji, Kamchatka", value: "528" },
          { name: "(GMT +12:45) Chatham Islands", value: "530" },
          { name: "(GMT +13:00) Apia, Nukualofa", value: "527" }
        ],
        }
      },
       watch: {
        'regional.month': {
        handler: function (v) {
          var temp = []
          for(var i = 1;i<=31;i++){
            if((this.regional.month==2 && i==29) || ([4,6,9,11].indexOf(this.regional.month) !== -1 && i==31)){
              break;
            }
            temp.push({value: i, text: i+""});
          }
          if((this.regional.month==2 && this.regional.day>28) || ([4,6,9,11].indexOf(this.regional.month) !== -1 && this.regional.day==31)){
            this.$set(this.regional, 'day', 1);
          }
          this.dayOptions = temp;
        },
        deep: true
      },
      'regional.transition_month': {
        handler: function (v) {
          var temp = []
          for(var i = 1;i<=31;i++){
            if((this.regional.transition_month==2 && i==29) || ([4,6,9,11].indexOf(this.regional.transition_month) !== -1 && i==31)){
              break;
            }
            temp.push({value: i, text: i+""});
          }
          if((this.regional.transition_month==2 && this.regional.transition_day>28) || ([4,6,9,11].indexOf(this.regional.transition_month) !== -1 && this.regional.transition_day==31)){
            this.$set(this.regional, 'transition_day', 1);
          }
          this.fromDayOptions = temp;
        },
        deep: true
      },
      'regional.transition_to_month': {
        handler: function (v) {
          var temp = []
          for(var i = 1;i<=31;i++){
            if((this.regional.transition_to_month==2 && i==29) || ([4,6,9,11].indexOf(this.regional.transition_to_month) !== -1 && i==31)){
              break;
            }
            temp.push({value: i, text: i+""});
          }
          if((this.regional.transition_to_month==2 && this.regional.transition_to_day>28) || ([4,6,9,11].indexOf(this.regional.transition_to_month) !== -1 && this.regional.transition_to_day==31)){
            this.$set(this.regional, 'transition_to_day', 1);
          }
          this.toDayOptions = temp;
        },
        deep: true
      }
      },
        methods: {
      deleteLink(m){
        var index = this.media_links.indexOf(m);
        if (index > -1) {
          this.media_links.splice(index, 1);
        }
      },
          getRegionalSettings() {
            this.$http.post("/api/organization/regional/settings/get", {}).then((response) => {
            if (response.data.success) {
              this.media_links = response.data.regional.media_links;
              response.data.regional.banned_weeks = [];
              this.admin = response.data.admin;
              var date = response.data.regional.year_end? response.data.regional.year_end.split("-") : [];
              response.data.regional.month  = date.length>1? parseInt(date[1]) : 12;
              response.data.regional.day = date.length>2? parseInt(date[2]) : response.data.regional.month === 12 ? 31 : 1;

              this.timezones = response.data.regional.timezones.split(",");
               date = response.data.regional.transition_from? response.data.regional.transition_from.split("-") : [];
              response.data.regional.transition_day = date.length>2? parseInt(date[2]) : 1;
              response.data.regional.transition_month  = date.length>1? parseInt(date[1]) : 1;

              date = response.data.regional.transition_to? response.data.regional.transition_to.split("-") : [];
              response.data.regional.transition_to_day = date.length>2? parseInt(date[2]) : 1;
              response.data.regional.transition_to_month  = date.length>1? parseInt(date[1]) : 1;
              response.data.regional.transition_from_show = response.data.regional.transition_from_show==1;
              this.regional = response.data.regional;
              this.regional.banned_weeks = this.regional.discounted_dates? this.regional.discounted_dates.split(",") : [];
              this.tracking_nums = [];
              for(var i=0; i<this.trackingOptions.length;i++){
                if((this.regional.tracking_periods & this.trackingOptions[i].value) != 0){
                  this.tracking_nums.push(this.trackingOptions[i].value);
                }
              }
                    
            }
            }).catch((e) => {console.log(e);});
          },
          updateRegionalSettings() {
            this.showAlert = false;
            var allgood = true;
            var trans = true;
            if(this.timezones.length == 0){
              allgood = false;
            }
            
            if(this.permissions.organization.official){
              if(this.tracking_nums.length==0){
                allgood = false;
              }
              if(this.regional.name.length == 0){
                allgood = false;
              }
              
              if(this.regional.transition_from_show){
                var transitionmonths = new Set();
                if(this.regional.transition_month<this.regional.transition_to_month){
                  for(var i = this.regional.transition_month; i<=this.regional.transition_to_month ;i++){
                    transitionmonths.add(i);
                  }
                }else{
                  for(var i = this.regional.transition_month; i<=12 ;i++){
                    transitionmonths.add(i);
                  }
                  for(var i = 1; i<=this.regional.transition_to_month ;i++){
                    transitionmonths.add(i);
                  }
                }
                
                if(!transitionmonths.has(this.regional.month)){
                  allgood = false;
                  trans = false;
                }
                if(this.regional.month==this.regional.transition_month && this.regional.day<this.regional.transition_day){
                    allgood = false;
                    trans = false;
                }
                if(this.regional.month==this.regional.transition_to_month && this.regional.day>this.regional.transition_to_day){
                    allgood = false;
                    trans = false;
                }
              }
              
              if((this.admin.chapter.password!==this.admin.chapter.password_confirm && this.admin.chapter.password && this.admin.chapter.password.length>0)){
                  this.states.pass = false;
                  allgood = false;
              }
              if((this.admin.startup.password!==this.admin.startup.password_confirm && this.admin.startup.password && this.admin.startup.password.length>0)){
                  allgood = false;
              }
              if((this.admin.rst.password!==this.admin.rst.password_confirm && this.admin.rst.password && this.admin.rst.password.length>0)){
                  allgood = false;
              }
            }
            if(!allgood){
              this.showAlert = true;
              this.alertText = trans? this.$t("general.alert_text") : this.$t("region.trans_alert");
              return;
            }

            this.regional.tracking_periods = 0;
            for(var i=0; i<this.tracking_nums.length;i++){
              this.regional.tracking_periods |= this.tracking_nums[i];
            }

            this.regional.year_end = "2020-"+this.regional.month+"-"+this.regional.day;
            this.regional.transition_from = "2020-"+this.regional.transition_month+"-"+this.regional.transition_day;
            this.regional.transition_to = "2020-"+this.regional.transition_to_month+"-"+this.regional.transition_to_day;
            this.regional.discounted_dates = this.regional.banned_weeks.join(",");

            if(this.regional.split_referrals==0){
              this.regional.show_single_referrals = 0;
            }
            this.regional.media_links = this.media_links;
            this.regional.timezones = this.timezones.join(",");
            this.$http.post("/api/organization/regional/settings/update", {regional:this.regional, admin: this.admin}).then((response) => {
              if (response.data.success === true) {
                this.saveVariant = 'success';
                this.back();
              } else {
                this.saveVariant = 'danger';
              }
            }).catch(() => {this.$bvToast.show('serverError');});
          },
          back(){
            window.history.length > 1 ? router.go(-1) : router.push("/admin/regiondash");
          },
          buildWeeks(){
              var y_start = new Date("1/1/2020");
              this.weeks = [];
              for(var i=1; i<=366; i++){
                  this.weeks.push({id: i, text: this.$formatDate(y_start, 'day_month_long')});
                  y_start.setDate(y_start.getDate() + 1);
              }
          },
          getMediaOptions() {
        this.$http.post("/api/media/types/get", {}).then((response) => {
          this.mediaOptions = response.data.types;
        }).catch(() => {});
      },
      getLanguages(){
        this.$http.post("/api/organization/languages", {}).then((response) => {
          if (response.data.success === true) {
            this.languageOptions = response.data.languages;
          }
        }).catch((errors) => {
          console.log(errors)
        });
      },
      getTimeZones() {
        this.$http.post("/api/organization/timezones", {}).then((response) => {
          if (response.data.success === true) {
            for(var i=0; i<this.timezoneNames.length; i++){
              if(response.data.timezone_ids_string.indexOf(this.timezoneNames[i].value)>-1){
                this.timezoneOptions.push(this.timezoneNames[i]);
              }
            }
          }
        }).catch((errors) => {
          console.log(errors)
        });
      },
    },
    destroyed() {
      this.removeClass(document.getElementById("menu-regional"), "active-menu");
      this.removeClass(document.getElementById("menu-chapter"), "active-menu");
    },
    mounted() {
      this.addClass(document.getElementById("menu-regional"), "active-menu");
      this.addClass(document.getElementById("menu-chapter"), "active-menu");
      
      if(this.isCorporateConnections){
        this.mediaOptions.push({channel_id: 1, link:""});
      }
      this.getTimeZones();
      this.getLanguages();
      this.buildWeeks();
      this.getRegionalSettings();
      this.getMediaOptions();
    }
    }
</script>
