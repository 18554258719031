<template>
  <div>
    <div id="scripts-page-wrapper" class="rel-grid-container">
      <div ></div>
      <div v-if="scriptsFetched && template_region.id==0">
        <div class=" section-title">{{$t('scripts.meeting_script')}} - {{!permissions.organization.is_parent && next_meeting? $formatDate(new Date("" + next_meeting.split("-").join("/")+ " 00:01")) : totalTime}}</div>
        <div id="total-duration" class="section-title" v-if="!permissions.organization.is_parent">{{$t('scripts.total_duration')}} - {{totalTime}}</div>
            <Tip :tipNumber="8"></Tip>
          <div class="grid-auto-1column">
            <b-form-checkbox id="show-variables-checkbox" v-model="permissions.populateVariables">
                {{$t('scripts.show_variables')}}
              </b-form-checkbox>
          </div>
          <SetupTip v-if="!permissions.organization.is_parent" class="setup-tip" :tipNumber="6"/>
          <SetupTip v-if="permissions.organization.is_parent" class="setup-tip" :tipNumber="13"/>
          <table class="table b-table table-hover-new table-bordered table-sm">
            <thead>
              <tr>
                <th id="time-header" class="tableHeader">{{$t('scripts.time_header')}}</th>
                <th id="section-header" class="tableHeader">{{$t('scripts.section_header')}}</th>
                <th v-if="!permissions.organization.is_parent || organization_id>0" class="tableHeader scripts-header">{{isCorporateConnections? $t('scripts.script_cc') : $t('scripts.script_bni')}}</th>
                <th class="tableHeader scripts-header">{{(!permissions.organization.is_parent || organization_id>0? $t('scripts.notes_header',{organization: org_name}) : $t('scripts.script_header',{organization: org_name}))}}</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="script in scripts" :key="script.id"  :class="{'lastRow-above': script.section_id>0, 'grey-row': script.isParent==1}">
                <td >
                  <div v-if="script.section_id>0"  class="grid-auto-1column center-text">
                    <span>{{script.start_time}}</span>
                    <span>({{getScriptTime(script)}})</span>
                  </div>
                </td>  
                <td>
                  <b>{{script.name && script.name.length>0? script.name : $t('global.no_translation')  }}</b>
                  <div class="slide-names-wrapper" v-if="script.slide_names.length>0">
                    <div v-for="name in script.slide_names" :key="name" class="grid-fit-2column">
                      <span class="slide-bullet">&#9679; </span>
                      <span class="slide-name" v-html="name.name && name.name.length>0? name.name : $t('global.no_translation')"></span>
                    </div> 
                  </div>
                </td>
                <td v-if="!permissions.organization.is_parent || organization_id>0">
                  <pre v-if="script.isParent==1" class="pre-format" v-html="permissions.populateVariables? script.var_script : highlight(script.script)"></pre>
                </td>
                <td v-on:click="editScript(script)" :class="{clickable: organization_id==0}">
                  <pre class="pre-format" v-html="permissions.populateVariables? (script.isParent==1? script.var_notes : script.var_script) : highlight(script.isParent==1? script.notes : script.script)"></pre>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div v-if="scriptsFetched && template_region.id>0" id="managed-by">
          {{ $t('global.managed_by', {region: template_region.name}) }}
        </div>
        <b-modal id="slide-modal" centered v-model="showScriptEdit" :title="$t('scripts.script_title')+$tc('scripts.script_notes', 1)">
          <div id="edit-script-box">
            <div class="column-span2">
              <Tip :tipNumber="9"></Tip>
            </div>
            <div>
              <span class="form-box-title">{{$tc('scripts.section_script', 1)+" "+$tc('scripts.script_notes', currentScript.isParent>0? 0 : 1)}}</span>
              <div class="form-box grid-auto-1column">
                <base-editor ref="script-editor" v-if="currentScript.isParent" v-model="currentScript.notes" :variables="variable_map" :minheight="350"/>
                <base-editor ref="script-editor" v-if="!currentScript.isParent" v-model="currentScript.script" :variables="variable_map" :minheight="350"/>
              </div>
            </div>
            <div id="script-variables" class="second-column">
              <span class="form-box-title">{{$t('scripts.insert_variables')}}</span>
              <div class="form-box grid-auto-1column">
                <div>
                  <b-form-radio-group class="grid-auto-1column" v-model="variableGroup" :options="groupOptions" value-field="value" text-field="name" >
                  </b-form-radio-group>
                </div>
                <div id="variable-table-wrapper">
                  <table class="table b-table table-hover-new table-bordered table-sm">
                    <tbody>
                      <tr class="variable-row" v-for="variable in variable_map.filter(v => v.group == variableGroup)" v-on:click="addTextVariable(variable)" :key="variable.id">
                        <td>{{ variable.name }}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div id="click-entry">{{$t('scripts.click_entry')}}</div>
              </div>
            </div>
          </div>
          <div slot="modal-footer" class="submit-box-2">
            <b-button class="action-button" v-on:click="updateScript();showScriptEdit = false;" variant="clear-blue"><div class="save-button"></div><span>{{$t('global.button_save')}}</span></b-button>
          </div>
        </b-modal>
      <div class="div-space"></div>
    </div>
  </div>
</template>
<style scoped lang="scss">
.slide-bullet{
  margin-right: 5px;
}
#script-variables{
  font-size:12px;
}
#scripts-page-wrapper{
  max-width: 90%;
}
#total-duration{
  font-size:18px;
}
#show-variables-checkbox{
  margin:auto; margin-right:0px;margin-top:50px;
}
.scripts-header{
  width: 40%;
}
#section-header{
  width: 200px;
}
#time-header{
  width: 80px;
}
.slide-name{
  color: #999;
}
.slide-names-wrapper{
  padding-left:30px;
}
#managed-by{
  text-align: center;font-size: 30px;
}
#edit-script-box{
  display: grid; grid-template-columns: auto 250px;
}
#variable-table-wrapper{
  height: 221px; overflow-y: auto; margin-top: 10px;
}
#click-entry{
  font-size: 10px;color: #777;
}
  .lastRow-above {
    border-top: $VUE_APP_COLOUR_MAIN solid 3px;
  }
  
  .section-title {
    text-align: center;
    font-size: 25px;
    margin-bottom: 10px;
  }
</style>
<script>
    export default {
    name: "Scripts",
    watch: {
      'permissions.populateVariables': {
        handler: function (v) {
          
          if(v){
            this.populateVariables()
          }
        },
        deep: true
      },
      '$i18n.locale': {
        handler: function () {
          this.organization_id = this.$route.params.id ? this.$route.params.id : 0;
          this.getScripts();
        }
      }
    },
      data() {
        return {
          scriptsFetched: false,
          template_region: {id: 0, name: ""},
          groupOptions: [{name: this.$t('slide.group_option1'), value: 'chapter'},{name: this.$t('slide.group_option2'), value: 'analytic'},
          {name: this.$t('slide.group_option3'), value: 'regional'},{name: this.$t('slide.group_option4'), value: 'role'},{name: this.$t('slide.group_option7'), value: 'transition'},
          {name: this.$t('slide.group_option5'), value: 'speaker'},{name: this.$t('slide.group_option6'), value: 'other'}],
          variableGroup: 'chapter',
          showScriptEdit: false,
          scripts: [],
          variable_map: [],
          currentScript: {
            section_id: 0,
            slide_id: 0,
            script: "",
            notes: ""
          },
          currentIndex: 0,
          next_meeting: null,
          totalTime: "",
          organization_id: 0,
          org_name : ""
        }
      },
    methods: {
      getScriptTime(script){
        return script.section_id>0? script.min+":"+(script.sec<=9? "0" : "")+script.sec : "";
      },
      populateVariables(){
        for (let script of this.scripts) {
          this.populateScriptVariables(script)
        }
      },
      populateScriptVariables(script){
        var self = this;
        self.$set(script, 'var_script', script.script);
        self.$set(script, 'var_notes', script.notes);
        this.variable_map.forEach(function (item) {
            if(item.id == "{Current_Time}"){
              item.value = self.$formatDate(new Date(), 'time_text') ;
            }else if(item.id == "{Current_Date}"){
              item.value = self.$formatDate(new Date()) ;
            }
            var reg = new RegExp('(<span data-type="mention" class="mention" data-id="'+item.id+'.*?<\/span>)', 'g');
            if(script.var_script){
              script.var_script = script.var_script.replaceAll(reg, ("<b class='script-variable'>"+(item.value && item.value.length>0? item.value.replaceAll("$", "$$$$"): "")+"</b>"));
            }
            if(script.var_notes){
              script.var_notes = script.var_notes.replaceAll(reg, ("<b class='script-variable'>"+(item.value && item.value.length>0? item.value.replaceAll("$", "$$$$"): "")+"</b>"));
            }
        });
      },
      editScript(item) {
        if (this.organization_id == 0 && item != undefined) {
          this.showScriptEdit = true;
          this.currentScript = item;
        }
      },
      focus(index) {
        this.currentIndex = index;
      },
      addTextVariable(variable) {
        //this.$refs['script-editor'].insertText(variable.id);
        this.$refs['script-editor'].insertVariable(variable);
      },
      getOrgName(){
        if(this.organization_id>0){
          this.$http.post("/api/organization/get/name", {id : this.organization_id}).then((response) => {
          if (response.data.success) {
           this.org_name = response.data.name;
          } 
        }).catch((errors) => {
          console.log(errors);
        });
        }else{
          this.org_name = this.permissions.organization.name;
        }
      },
      getScripts() {
        this.$http.post("/api/meeting/scripts", {id : this.organization_id,slide_scripts: false, language: this.$i18n.locale}).then((response) => {
          if (response.data.success) {
            if(response.data.template_region){
                  this.template_region = response.data.template_region;
            }else{
              this.totalTime = response.data.totalTime;
              this.next_meeting = response.data.next_meeting;
              this.scripts = response.data.scripts;
              var t = 0;
              this.start = response.data.start;
              
              for (var i = 0; i < this.scripts.length; i++) {
                if (!this.scripts[i].disabled && this.scripts[i].section_id>0) {
                  var datetime = new Date(new Date().toDateString() + " " + this.start + " (EST)");
                  datetime.setSeconds(datetime.getSeconds() + t);
                  this.$set(this.scripts[i], 'start_time', this.$formatDate(datetime, 'time_text'));
                  t += this.scripts[i].target_duration;
                }
              }
              function compareFn(a, b) {
                if (a.name.toLowerCase()<b.name.toLowerCase()) {
                  return -1;
                } else if (a.name.toLowerCase()>b.name.toLowerCase()) {
                  return 1;
                }
                return 0;
              }
              response.data.variable_map =response.data.variable_map.sort(compareFn);
              this.variable_map = response.data.variable_map;
              if(this.permissions.populateVariables){
                this.populateVariables();
              }
            }
          } else {
            this.scripts = [];
          }
          this.scriptsFetched = true;
        }).catch((errors) => {
          this.scripts = [];
          console.log(errors);
        });

      },
      highlight(script) {
        var self = this;
        /*this.variable_map.forEach(function (item) {
          if(item.id == "{Current_Time}"){
            item.value = self.$formatDate(new Date(), 'time_text') ;
          }else if(item.id == "{Current_Date}"){
            item.value = self.$formatDate(new Date()) ;
          }
          if (script && script.indexOf(item.id) >= 0) {
            script = script.split(item.id).join("<b class='script-variable'>" + item.id + "</b>");
          }
        });*/
        return script;
      },
      updateScript() {
        if(this.permissions.populateVariables){
          this.populateScriptVariables(this.currentScript);
        }
         if (this.currentScript != null) {
           this.currentScript.language = this.$i18n.locale;
           this.currentScript.update_text = this.currentScript.isParent==1? this.currentScript.notes : this.currentScript.script;
          this.$http.post("/api/meeting/scripts/update", this.currentScript).then(() => {
          }).catch(() => {
            this.$bvToast.show('serverError');
          });
        }
      },
    },
   destroyed() {
      this.removeClass(document.getElementById("menu-scripts"), "active-menu");
      this.removeClass(document.getElementById("menu-meeting"), "active-menu");
    },
    mounted() {
      this.addClass(document.getElementById("menu-scripts"), "active-menu");
      this.addClass(document.getElementById("menu-meeting"), "active-menu");
      this.organization_id = this.$route.params.id ? this.$route.params.id : 0;
      this.getScripts();
      this.getOrgName();
    }
  }
</script>
