<template>
  <div>
    <div class="rel-grid-container top-spacing">
      <div ></div>
      <div>
        <div id="import-form-box-wrapper">
          <span class="form-box-title">{{$t(permissions.organization.official? 'import.import_options' : 'import.applicant_options')}}</span>
          <div id="import-forn-box" class="form-box">
            <b-button variant="blue" v-on:click="openDialog" id="select-file-button">{{$t('import.select_file')}}</b-button>
            <b-form-file id='fileid' accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" @change="onFileChange($event)" plain hidden></b-form-file>
            <div class="auto-margin">
              {{file.name}}
            </div>
            <b-form-checkbox v-if="allowPhotos" id="email-members" v-model="sendEmails" value="1" unchecked-value="0">
              {{$t(permissions.organization.official? 'import.email_members' : 'import.email_applicants')}}
            </b-form-checkbox>
            <div id="import-note">
              *{{$t(permissions.organization.official? 'import.note_message' : 'import.note_applicant')}}
            </div>
          </div>
        </div>
      </div>
      <b-modal centered id="slide-modal-4" v-model="showPreviewImport" :title="$t('import.import_title')" :no-close-on-backdrop="importing" :no-close-on-esc="importing" :hide-header-close="importing">
        <div id="preview-members">
          <b-table class="table-hover-new" small bordered :items="previewMembers" :fields="isCorporateConnections? ccfields : fields">
            <template v-slot:cell(roles)="data">
              {{data.item.roles.join(",")}}
            </template>
          </b-table>
        </div>
        <div slot="modal-footer" class="submit-box-2">
          <b-button class="action-button" :disabled="importing" v-on:click="cancelImport" variant="clear-blue"><div class="cancel-button"></div><span>{{$t('global.button_cancel')}}</span></b-button>
          <b-button v-if="!onboard" :disabled="importing" class="action-button" v-on:click="importChanges" variant="clear-blue"><div class="import-button"></div><span>{{$t('global.button_import')}}</span></b-button>
          <b-button v-if="onboard" :disabled="importing" class="action-button" v-on:click="confirmImport" variant="clear-blue"><div class="save-button"></div><span>{{$t('global.button_confirm')}}</span></b-button>
        </div>
      </b-modal>
      <div v-if="showAlert">
        <b-alert variant="danger"
                 dismissible
                 :show="showAlert"
                 @dismissed="showAlert=false">
          {{alertText}}
        </b-alert>
      </div>
      <div v-if="showSuccess">
        <b-alert variant="success"
                 dismissible
                 :show="showSuccess"
                 @dismissed="showSuccess=false">
          {{$t('import.successful_import')}}
        </b-alert>
      </div>
      <div v-if="showUpload">
        <b-alert id="import-alert" variant="warning" :show="showUpload">
          {{$t('import.importing_message')}}
          <b-spinner id="import-alert-spinner" label="Uploading" variant="warning"></b-spinner>
        </b-alert>
      </div>
      <div class="submit-box-2" v-if="!onboard">
        <b-button class="action-button" v-on:click="back()" variant="clear-blue"><div class="cancel-button"></div><span>{{$t('global.button_cancel')}}</span></b-button>
      </div>
      <div class="div-space"></div>
    </div>
  </div>
</template>
<style scoped>
#import-alert-spinner{
  float:right;
}
#import-alert{
  line-height:30px;
}
#import-note{
  grid-column: 1/3; margin-top: 20px;font-size:12px;
}
#email-members{
  grid-column: 1/3; margin-top: 20px;
}
#select-file-button{
  width:200px;
}
#import-form-box-wrapper{
  max-width: 600px;margin: auto;
}
#import-forn-box{
  grid-template-columns: 50% 50%;
}
 #preview-members{
   max-height:500px; overflow: auto;
 }
</style>
<script>
  
  import router from "../router"
  export default {
    name: "Import",
    props:{
      onboard: Boolean,
    },
    data() {
      return {
        importing: false,
        allowPhotos: false,
        showUpload: false,
        showAlert: false,
        saveVariant: "",
        alertText: "",
        showSuccess: false,
        file: { name: "" },
        sendEmails: false,
        showPreviewImport: false,
        previewMembers: [],
        fields: [
          { key: 'name',
            label: this.$t('import.name_header'),
            sortable: true,
            thStyle: { "background-color": process.env.VUE_APP_TABLE_HEADER, "color": "#ffffff" }
          },
           { key: 'category',
            label: this.$t('import.category_header'),
            sortable: true,
            thStyle: { "background-color": process.env.VUE_APP_TABLE_HEADER, "color": "#ffffff" }
          },
           { key: 'company',
            label: this.$t('import.company_header'),
            sortable: true,
            thStyle: { "background-color": process.env.VUE_APP_TABLE_HEADER, "color": "#ffffff" }
          },
           { key: 'email',
            label: this.$t('import.email_header'),
            sortable: true,
            thStyle: { "background-color": process.env.VUE_APP_TABLE_HEADER, "color": "#ffffff" }
          },
           { key: 'phone',
            label: this.$t('import.phone_header'),
            sortable: true,
            thStyle: { "background-color": process.env.VUE_APP_TABLE_HEADER, "color": "#ffffff" }
          },
            { key: 'website',
            label: this.$t('import.website_header'),
            sortable: true,
            thStyle: { "background-color": process.env.VUE_APP_TABLE_HEADER, "color": "#ffffff" }
          },
          { key: 'renewal',
            label: this.$t('import.renewal_header'),
            sortable: true,
            thStyle: { "background-color": process.env.VUE_APP_TABLE_HEADER, "color": "#ffffff" }
          },
           { key: 'roles',
            label: this.$t('import.role_header'),
            sortable: true,
            thStyle: { "background-color": process.env.VUE_APP_TABLE_HEADER, "color": "#ffffff" }
          },
        ],
        ccfields: [
          { key: 'name',
            label: this.$t('import.name_header'),
            sortable: true,
            thStyle: { "background-color": process.env.VUE_APP_TABLE_HEADER, "color": "#ffffff" }
          },
           { key: 'category',
            label: this.$t('import.category_header'),
            sortable: true,
            thStyle: { "background-color": process.env.VUE_APP_TABLE_HEADER, "color": "#ffffff" }
          },
           { key: 'company',
            label: this.$t('import.company_header'),
            sortable: true,
            thStyle: { "background-color": process.env.VUE_APP_TABLE_HEADER, "color": "#ffffff" }
          },
           { key: 'email',
            label: this.$t('import.email_header'),
            sortable: true,
            thStyle: { "background-color": process.env.VUE_APP_TABLE_HEADER, "color": "#ffffff" }
          },
           { key: 'phone',
            label: this.$t('import.phone_header'),
            sortable: true,
            thStyle: { "background-color": process.env.VUE_APP_TABLE_HEADER, "color": "#ffffff" }
          },
            { key: 'website',
            label: this.$t('import.website_header'),
            sortable: true,
            thStyle: { "background-color": process.env.VUE_APP_TABLE_HEADER, "color": "#ffffff" }
          },
          { key: 'renewal',
            label: this.$t('import.renewal_header'),
            sortable: true,
            thStyle: { "background-color": process.env.VUE_APP_TABLE_HEADER, "color": "#ffffff" }
          },
           { key: 'roles',
            label: this.$t('import.role_header'),
            sortable: true,
            thStyle: { "background-color": process.env.VUE_APP_TABLE_HEADER, "color": "#ffffff" }
          },
           { key: 'job_title',
            label: this.$t('import.job_title'),
            sortable: true,
            thStyle: { "background-color": process.env.VUE_APP_TABLE_HEADER, "color": "#ffffff" }
          },
           { key: 'linkedin_url',
            label: this.$t('import.linkedin_url'),
            sortable: true,
            thStyle: { "background-color": process.env.VUE_APP_TABLE_HEADER, "color": "#ffffff" }
          },
           { key: 'pref_lang',
            label: this.$t('import.preferred_language'),
            sortable: true,
            thStyle: { "background-color": process.env.VUE_APP_TABLE_HEADER, "color": "#ffffff" }
          }
        ],
      }
    },
    methods: {
      
      onFileChange(e) {
        var files = e.target.files || e.dataTransfer.files;
        if (!files.length || !this.checkFileSize(files[0]))
          return;
        this.file = files[0];

        this.importMembers();
      },
      importMembers() {
        this.showUpload = true;
        this.showAlert = false;
        this.alertText = this.$t('import.alert_text');
        this.saveVariant = "";

        let formData = new FormData();
        formData.append("file", this.file);
        this.$http.post("/api/organization/members/import/excel", formData, {
          headers: {
            "Content-Type": "multipart/form-data"
          }
        }).then((response) => {
          this.showUpload = false;
          this.saveVariant = "blue";
          if (response.data.success == true) {
            //this.showSuccess = true;
            //this.back();
            this.previewMembers = response.data.members;
            this.showPreviewImport = true;
          } else {
            this.showAlert = true;
            this.alertText = response.data.error;
          }
        }).catch((errors) => {
          this.showUpload = false;
          this.saveVariant = "blue";
          this.showAlert = true;
          this.alertText = JSON.stringify(errors);
        });
      },
      cancelImport() {
        this.previewMembers = [];
        this.showPreviewImport = false;
        this.file = { name: "" };
      },
      importChanges() {
        this.importing = true;
          this.$http.post("/api/organization/members/import/all", { members: this.previewMembers, sendEmails: this.sendEmails }).then((response) => {
            this.importing = false;
            this.showPreviewImport = false;
            if (response.data.success === true) {
                if(!this.onboard){
                  this.back();
                }
            }
          }).catch(() => {this.importing = false; this.showPreviewImport = false;});
      },
      getAllowPhotos() {
            this.$http.post("/api/organization/get/allowphotos", {}).then((response) => {
              if (response.data.success) {
                this.allowPhotos = response.data.allowPhotos;
              }
            }).catch(() => {});
          },
      confirmImport(){
        this.showPreviewImport = false;
      },
      openDialog() {
        document.getElementById('fileid').click();
      },
      back() {
        window.history.length > 1 ? router.go(-1) : router.push("/admin/members");
      },
    },
    destroyed() {
    },
    mounted() {
      this.getAllowPhotos();
    }
  }
</script>
