<template>
  <div  id="visitor-background">
    <div id="visitor-main-box" v-if="!showExpired && doneLoading">
      <div></div>
      <div v-if="isCorporateConnections" id="cc-header-banner-wrapper">
        <img id="cc-header-banner" :src="banner_image==null? '../assets/public-cc-banner.png': banner_image" />
      </div>
        <div id="meeting-info-box" class="fit-width;">
          <div id="org-name">{{organization_name}}</div>
          <div id="meeting-info-date"><i>{{$formatDate(new Date(next_meeting))}}</i></div>
          <div ><b>{{$t('visitor.online_registration')}}</b></div>
        </div>
            <div v-if="stage == 0" id="stage0-box" >
              <div id="stage0-header" class="v-header" v-html="$t('visitor.step0_header1')"></div>
                <div id="stage0-buttons-wrapper">
                  <b-button variant="light" v-on:click="registerVisitor" class="register-button">
                    <b >{{$t('visitor.visitor_registration_button')}}</b><br/>
                    <span >{{$t('visitor.visitor_registration_subtext')}}</span>
                </b-button>
                <b-button variant="light" v-on:click="registerSub" class="register-button">
                    <b >{{$t('visitor.sub_registration_button')}}</b><br/>
                    <span >{{$t('visitor.sub_registration_subtext')}}</span>
                </b-button>
                </div>
            </div>
            <div v-if="stage == 1">
              <div class="v-header" v-html="$t('visitor.step1_header1')">
                </div>
              <div id="stage1-buttons-wrapper">
                  <b-button v-for="r in registered" :key="r.id" variant="light" v-on:click="fillRegistered(r)" class="registered-button" >
                    <b>{{r.name}}</b><span>{{(r.company.length>0? " > " : "") +r.company}}</span><span id="status-present" v-if="r.present==1">{{$t('attendance.status_present').toLocaleLowerCase()}}</span>
                </b-button>
                  </div>
                <b-button id="not-in-list" variant="light"  class="registered-button fit-width" v-on:click="continueRegistration">
                    <b >{{$t('visitor.not_in_list')}}</b><br/>
                    <span>{{$t('visitor.continue_registration')}}</span>
                </b-button>
            </div>
            <div v-if="stage == 2" class="visitor-box-size auto-margin">
              <div class="v-header">
                {{currentVisitor.id>0? $t('visitor.step2_header1') : $t('visitor.step2_header2')}}
                </div>
                <div id="step2-form-box" class="form-box">
                    <span class="form-box-name">{{$t('visitor.your_name')}}</span><b-form-input v-model="currentVisitor.name" :state="currentVisitor.name_state" type="text" pattern="[^<>]*" :title="$t('global.pattern_title')"  required></b-form-input><span class="red-star">*</span>
                    <span class="form-box-name">{{$t('visitor.company_text')}}</span><b-form-input v-model="currentVisitor.company" :state="currentVisitor.company_state"  type="text" pattern="[^<>]*" :title="$t('global.pattern_title')"  ></b-form-input><span class="red-star">*</span>
                   
                    <span id="invited-member" class="form-box-name" v-html="currentVisitor.type == 3? $t('visitor.member_invite') : $t('visitor.member_sub')"></span><b-form-select id="invited-member-select" v-model="currentVisitor.member_id" :state="currentVisitor.member_state" :options="members2" value-field="id" text-field="name"/><span v-if="currentVisitor.type==1"  class="red-star">*</span><span v-if="currentVisitor.type==3"></span>
                    <span v-if="!event_chapter" class="form-box-name">{{$t('visitor.phone_number')}}</span>
                    <b-form-input v-if="!event_chapter" v-model="currentVisitor.phone" type="tel" ></b-form-input><span v-if="!event_chapter" ></span>
                    <span class="form-box-name" v-if="isCorporateConnections">{{$t('controller.mobile_phone')}}</span>
                    <b-form-input v-if="isCorporateConnections" v-model="currentVisitor.mobile_phone" :state="currentVisitor.phone_state" type="tel"></b-form-input><span  v-if="isCorporateConnections" class="red-star">*</span>
                    <span class="form-box-name">{{$t('visitor.email_text')}}</span><b-form-input v-model="currentVisitor.email" :state="currentVisitor.email_state" type="email" ></b-form-input><span  v-if="isCorporateConnections" class="red-star">*</span><span  v-if="!isCorporateConnections"></span>
                    <span class="form-box-name">{{$t('controller.website_text')}}</span><b-form-input :disabled="currentVisitor.type==0" v-model="currentVisitor.website" type="text"  ></b-form-input><span ></span>
                </div>
                <b-form-checkbox v-if="require_consent==1" v-model="info_consent" value="1" unchecked-value="0" class="consent-checkbox">
                    <i>{{$t('visitor.info_consent')}}</i>
                </b-form-checkbox>
            </div>
            <div v-if="stage == 3" class="visitor-box-size auto-margin">
              <div class="v-header">
                {{$t(event_chapter?'visitor.step3_header1_event': 'visitor.step3_header1')}}
              </div>
                 <div id="step3-header2" class="v-header">
                  {{isCorporateConnections? $t(event_chapter?'visitor.step3_header2_event': 'visitor.step3_header2r') : $t('visitor.step3_header2')}}
                </div>
                 <div class="v-header">
                {{$t(event_chapter?'visitor.step3_header3_event': 'visitor.step3_header3')}}
                </div>
            </div>
            <div v-if="stage == 4" class="visitor-box-size auto-margin">
              <div class="v-header" v-html="$t('visitor.step4_header1')">
                </div>
                <div id="stage4-box">
                  <div id="stage4-content1" v-html="isCorporateConnections? $t(event_chapter? 'visitor.step4_content1_event' : 'visitor.step4_content1r') : $t('visitor.step4_content1')">
                </div>
                <img id="stage4-image" :src="getImgUrl(isCorporateConnections? 'VisitorDefaultSlideCC.png' : 'VisitorDefaultSlide.png')" />
                </div>
              <br/>
            </div>
            <div v-if="stage == 5" class="visitor-box-size auto-margin">
              <div class="v-header" v-html="$t('visitor.step5_header1')">
                </div>
                <ImageSelector ref="imageSelector" :imageObject="currentVisitor" :ratio="isCorporateConnections? 1 : 0.6157" :maxWidth="665" :maxHeight="1080" :embedded="true" :circular="true"/>
              <hr/>
              <b-form-checkbox v-if="currentVisitor.photo && currentVisitor.photo.length>0" v-model="legal_consent" value="1" unchecked-value="0" class="consent-checkbox">
                    {{$t('visitor.legal_consent')}}
                </b-form-checkbox>
            </div>
            <div class="visitor-box-size auto-margin full-width">
                <b-alert variant="danger"
                            dismissible
                            :show="showAlert"
                            @dismissed="showAlert=false" class="full-width">
                    {{$t('visitor.error_type1')}}
                </b-alert>
            </div>
        <div  v-if="stage>0" id="final-submit">
            <b-button v-if="stage != 3 && (!isCorporateConnections || stage != 1)"  v-on:click="back" class="action-button"  variant="clear-blue">
                <div class="back-button"></div><span>{{$t('global.button_back')}}</span>
            </b-button>
            <b-button v-if="stage == 2" :disabled="require_consent==1 && info_consent==0" v-on:click="submitVisitor" class="action-button"  variant="clear-blue">
                <div class="next-button"></div><span>{{$t('global.button_continue')}}</span>
            </b-button>
            <b-button v-if="stage == 4" v-on:click="stage=5;" class="action-button"  variant="clear-blue">
                <div class="import-button"></div><span>{{$t('visitor.submit_photo')}}</span>
            </b-button>
            <b-button v-if="stage == 4" v-on:click="skipPhoto" class="action-button"  variant="clear-blue">
                <div class="next-button"></div><span>{{$t('visitor.skip_photo')}}</span>
            </b-button>
            <b-button v-if="stage == 5" :disabled="legal_consent==0 || !(currentVisitor.photo && currentVisitor.photo.length>0) || !(canSubmitPhoto || $refs.imageSelector.getHasUploaded())" v-on:click="captureImage" class="action-button"  variant="clear-blue">
                <div class="next-button"></div><span>{{$t('global.button_complete')}}</span>
            </b-button>
        </div>
        <div>
          
        </div>
        <div></div>
        <b-modal centered v-model="showDuplicateMember"  >
        <div v-html="$t('visitor.member_error'+official)">
        </div>
        <div slot="modal-footer" class="submit-box-2">
          <b-button class="action-button" v-on:click="restart" variant="clear-blue"><div class="save-button"></div><span>{{$t('visitor.restart_registration')}}</span></b-button>
          <b-button class="action-button"  v-on:click="showDuplicateMember=false;" variant="clear-blue"><div class="save-button"></div><span>{{$t('global.button_ok')}}</span></b-button>
        </div>
      </b-modal>
    </div>
    <div v-if="showExpired && doneLoading" class="valign-grid full-height">
      <div></div>
    <div class="visitor-box-size auto-margin full-width center-text">
        <b-alert variant="warning" :show="showExpired" class="full-width">
            {{$t('visitor.error_expired')}}
        </b-alert>
    </div>
    <div></div>
    </div>
  </div>
</template>
<style lang="scss" scoped>
#bottom-logo{
  margin: 20px auto;
}
#stage4-image{
  width:200px; margin: auto;
}
#stage4-content1{
  text-align: left;font-size: 18px;
}
#stage4-box{
  display:grid; grid-template-columns: auto auto;margin: 50px 0;
}
#invited-member-select{
  margin: auto 0px;
}
#invited-member{
  margin:40px 5px;
}
.consent-checkbox{
  margin:auto 0;
}
#step3-header2{
  text-align:center; font-size:24px;margin-bottom:30px;
}
#stage1-buttons-wrapper{
  max-width:800px; margin: auto;
}
#stage0-buttons-wrapper{
  display: grid; grid-template-columns: 50% 50%;
}
#stage0-header{
  margin-bottom:0px;
}
#stage0-box{
  max-width: 1000px;margin: auto;width: 800px;
}
#meeting-info-date{
  font-size: 26px;
}
#meeting-info-box{
  font-size: 30px;margin:auto;text-align: center;margin-bottom: 50px;
}
#cc-header-banner-wrapper{
  margin:20px 0px
}
#cc-header-banner{
  margin:auto;display:block;max-height:500px;max-width:1080px;
}
#step2-form-box{
  grid-template-columns: 30% 70% 30px;border:none;
}
#status-present{
  float:right;
}
.visitor-box-size{
  max-width: 600px;
}
#org-name{
  color:$VUE_APP_COLOUR_MAIN;
  font-size: 34px;
}
.red-star{
  font-size: 30px;
  color:red;
  margin: auto;
}
.v-header{
  font-weight:bold; font-size:20px;text-align: center;margin-bottom: 30px;
  color:$VUE_APP_COLOUR_MAIN;
}

.registered-button{
      font-size: 24px;
    margin: 5px;
    width: 100%;
    text-align: left;
}
.register-button{
  background-color: #ddd;
}
.register-button > b{
  color:rgb(255, 0, 0);
}
.register-button > span{
  font-size: 24px;color:#333;
}

@if $VUE_APP_IS_CORPORATE==0 {
  .registered-button{
    background-color: white;
    border: 1px solid #ccc;
}
.registered-button > b{
  color:rgb(255, 0, 0);
}
.registered-button > span{
  font-size: 20px;color:#333;
}
#not-in-list{
  background-color: #ddd;
  margin: 20px auto;font-size:24px;display:block;
}
}
@if $VUE_APP_IS_CORPORATE==1 {
  .v-header{
    color:#98989A;
  }
  .registered-button{
    background-color:#98989A;
    border: 1px solid #424143;
}
.registered-button > b{
  color:white;
}
.register-button > span{
  font-size: 20px;
  color:#424143;
}
}

#preparer-logo-box{
  display: grid;grid-template-columns: max-content max-content max-content;grid-gap: 20px;margin: auto;    margin-bottom: 20px;
}
#visitor-main-box{
  display: grid;
    grid-template-rows: auto max-content max-content  max-content max-content max-content auto max-content;
    height: 100%;
}
#visitor-payment-options{
  grid-template-columns: 100%;grid-gap: 20px;text-align: center;font-size: 24px;
}
#final-submit{
  width: fit-content; margin: auto;display: grid; grid-template-columns: auto auto auto
}
.register-button{
  font-size: 30px; margin: 10px;/* padding 40px 10px;*/
}
#visitos-main-box{
  display: grid;max-width: 800px;margin: auto;grid-template-rows: auto min-content min-content auto;height: 100%;
}
#visitor-background{
    height: 100%;
    width: 100%;
}

</style>
<script>
    import tempVisitorImage from "../assets/VisitorDefaultPic.png";
    export default {
      name: "Visitor",
      watch: {
      'currentVisitor.member_id': {
        handler: function (v) {
          for(var i in this.members2){
              if(this.members2[i].id == v){
                  this.currentVisitor.member_name = this.members2[i].name;
              }
          }
        },
        deep: true
      },
       '$i18n.locale': {
        handler: function () {
           document.title = this.permissions.organization.name+ " - "+this.$t('global.meeting_registration');
        },
        deep: true
      },
        },
      data() {
          return {
            banner_image: null,
            event_chapter: false,
            showDuplicateMember: false,
            official: 1,
            info_consent:0,
            legal_consent: 0,
            doneLoading: false,
            showExpired: false,
              trans: 'lefttoright',
              stage : 0,
              currentVisitor: {id: 0,name: "", company: "", member_id: 0, phone: "", email: "", website: "", profession: "", subscribed: 0, payment: 0, mobile_phone: "",
              deleted: 0, type: 0, name_state: null, member_state: null, company_state: null, email_state: null,email_state:null, present: 1, photo: '', photo_small: ""},
              members2: [],
              next_meeting: "",
              showAlert: false,
              alertText: "",
              id_string: "",
              client: {},
              org: 0,
              registered: [],
              hash: '',
              canSubmitPhoto: true
          }
      },
      methods: {
        restart(){
          this.legal_consent = 0;
          this.info_consent = 0;
          this.currentVisitor= {id: 0,name: "", company: "", member_id: 0, phone: "", email: "", website: "", profession: "", subscribed: 0, payment: 0, mobile_phone: "",
              deleted: 0, type: 0, name_state: null, member_state: null, company_state: null, email_state: null,email_state: null, present: 1, photo: '', photo_small: ""},
          this.showDuplicateMember=false;
          this.stage = 0;
          if(this.isCorporateConnections){
            this.stage = 1;
            this.registerVisitor();
          }
        },
        skipPhoto(){
          this.stage = 3;
            this.$socket.emit("request_attendance_refresh",{ id_string: this.id_string });
        },
        captureImage(){
          this.$refs.imageSelector.cropImage(this.setVisitorPhoto);
        },
        continueRegistration(){
          this.currentVisitor.id = 0;
          this.currentVisitor.name = "";
          this.currentVisitor.company = "";
          this.currentVisitor.member_id = 0;
          this.currentVisitor.phone = "";
          this.currentVisitor.email = "";
          this.currentVisitor.website = "";
          this.stage = 2;
        },
        registerSub(){
            this.currentVisitor.type = 1;
            this.getRegistered();
        },
        registerVisitor(){
            this.currentVisitor.type = 3;
            this.getRegistered();
        },
        fillRegistered(r){
          this.currentVisitor.id = r.id;
          this.currentVisitor.name = r.name;
          this.currentVisitor.company = r.company;
          this.currentVisitor.member_id = r.member_id;
          this.currentVisitor.phone = r.phone;
          this.currentVisitor.email = r.email;
          this.currentVisitor.website = r.website;
          this.currentVisitor.mobile_phone = r.mobile_phone;
          this.stage = 2;
        },
        getVisitorPhoto(){
          if(this.currentVisitor.type!=3){
            this.stage = 3;
            this.$socket.emit("request_attendance_refresh",{ id_string: this.id_string });
            return;
          }
            this.$http.post("/api/visitor/get/photo/byhash", {id: this.currentVisitor.id, hash: this.hash}).then((response) => {
                if (response.data.success === true) {
                  if(response.data.allow_visitor_photos==0){
                    this.stage = 3;
                    this.$socket.emit("request_attendance_refresh",{ id_string: this.id_string });
                    return;
                  }
                  if(response.data.photo != null && response.data.photo.length>0){
                    this.currentVisitor.photo = response.data.photo;
                    this.currentVisitor.photo_small = response.data.photo_small;
                    this.canSubmitPhoto = true;
                  }else{
                    this.currentVisitor.photo = tempVisitorImage;
                    this.currentVisitor.photo_small = response.data.photo_small;
                    this.canSubmitPhoto = false;
                  }
                    
                    this.stage = 4;
                }
            }).catch(() => { this.$bvToast.show('serverError');});
        },
         setVisitorPhoto(){
            this.$http.post("/api/visitor/update/photo", {id: this.currentVisitor.id, hash: this.hash, photo: this.currentVisitor.photo, photo_small: this.currentVisitor.photo_small}).then((response) => {
                if (response.data.success === true) {
                    this.stage = 3;
                    this.$socket.emit("request_attendance_refresh",{ id_string: this.id_string });
                }
            }).catch(() => { this.$bvToast.show('serverError');});
        },
        getVisitorSettings(){
            this.$http.post("/api/visitor/get/settings/byvisitorhash", {hash: this.hash}).then((response) => {
                if (response.data.success === true) {
                    this.require_consent = response.data.require_consent;
                }
            }).catch(() => { this.$bvToast.show('serverError');});
        },
        submitVisitor(){
            this.showAlert = false;
            var input_error = false;

            if(this.currentVisitor.name.length == 0){
                this.currentVisitor.name_state = false;
                input_error = true;
            }else{
              this.currentVisitor.name_state = null;
            }
            if(this.currentVisitor.company.length == 0){
                this.currentVisitor.company_state = false;
                input_error = true;
            }else{
              this.currentVisitor.company_state = null;
            }
            if(this.currentVisitor.type == 1 && this.currentVisitor.member_id==0){
               this.currentVisitor.member_state = false;
                input_error = true;
            }else{
              this.currentVisitor.member_state = null;
            }

            if(this.isCorporateConnections && this.currentVisitor.email.length == 0){
                this.currentVisitor.email_state = false;
                input_error = true;
            }else{
              this.currentVisitor.email_state = null;
            }
             if(this.isCorporateConnections && this.currentVisitor.mobile_phone.length == 0){
                this.currentVisitor.phone_state = false;
                input_error = true;
            }else{
              this.currentVisitor.phone_state = null;
            }

            if(input_error){
                this.showAlert = true;
                return;
            }
            this.currentVisitor.update_time =  this.next_meeting;
            this.currentVisitor.org = this.org;
            this.currentVisitor.hash = this.hash;
            this.$http.post("/api/organization/attendance/add/subvisitor", this.currentVisitor).then((response) => {
                if (response.data.success === true) {
                    this.currentVisitor.id = response.data.id;
                    this.getVisitorPhoto();   
                }else if(response.data.error==1){
                  this.showDuplicateMember = true;
                  this.official = response.data.official;
                }
            }).catch(() => { this.$bvToast.show('serverError');});
            
        },
         getRegistered() {
          this.$http.post("/api/attendance/registered/noauth", {org:this.org}).then((response) => {
            this.registered = [];
              if(response.data.success===true){
                this.registered = response.data.registered;
                this.stage = 1;
              }else{
                  this.stage = 2;
                }
          }).catch((errors) => {console.log("error");console.log(errors);this.stage=2;});
        },
        getContacts() {
        this.$http.post("/api/organization/contacts/assigned/noauth", {org:this.org}).then((response) => {
          for(var i=0; i<response.data.contacts.length;i++){
            response.data.contacts[i].name = response.data.contacts[i].name+ (response.data.contacts[i].contact_name.length>0? " ("+response.data.contacts[i].contact_name+")" : "");
          }
            this.members2.push.apply(this.members2,response.data.contacts);
            
            this.members2.sort(function(a, b){
              var nameA=a.name.toLowerCase(), nameB=b.name.toLowerCase();
              if (nameA < nameB) //sort string ascending
                return -1;
              if (nameA > nameB)
                return 1;
              return 0; //default return value (no sorting)
              });
            this.members2.unshift({ id: 0, name: this.$t('global.select_member') });
        }).catch((errors) => {console.log(errors);});
      },
        getMembers() {
          if(this.org>0){
             this.$http.post("/api/organization/members/noauth", {org:this.org, all: false}).then((response) => {
            this.members2 = response.data.members.slice();
            this.getContacts();
            }).catch(() => { });
          }
           
        },
        getDates() {
          if(this.org>0){
            this.$http.post("/api/organization/dates/noauth", {org: this.org}).then((response) => {
              if (response.data.success === true) {
                this.next_meeting = response.data.next_formatted;
              }
            }).catch(() => { });
          }
        },
        back(){
          this.showAlert = false;
          if(this.stage==4){
            this.stage = 2;
            return;
          }
          this.stage -= 1;
          if(this.stage == 1 && this.registered.length==0){
            this.stage-=1;
          }
        },
        auth() {
          var self = this;
          this.authenticate(function(){
            self.doneLoading = true;
            self.org = this.permissions.organization.id;
             self.getDates();
              self.getMembers();
              if(self.isCorporateConnections){
                self.stage = 1;
                self.registerVisitor();
              }
              document.title = self.permissions.organization.name+ " - "+self.$t('global.meeting_registration');
              self.$http.post("/api/client/get", {controller: true}).then((response) => {
                if (response.data.id_string) {
                    //self.client.socket = self.$io({transports: ['websocket'], upgrade: false});
                    self.id_string = response.data.id_string;
                    self.$socket.emit("register_attendance",{ id_string : self.id_string});
                }
              }).catch((errors) => {
                console.log(errors);
              });
          });
      },
      getOrgId(){
        
        this.$http.post("/api/organization/get/byvisitorhash", {hash:this.hash}).then((response) => {
            if (response.data.success === true) {
                this.organization_name = response.data.name;
                this.banner_image = response.data.banner;
                this.event_chapter = response.data.event_chapter;
                document.title = this.organization_name + " - "+this.$t('global.meeting_registration');
                this.org = response.data.id;
                this.id_string = response.data.id_string;
                this.$socket.emit("register_attendance",{ id_string: this.id_string });
                this.getDates();
                this.getMembers();
                if(this.isCorporateConnections){
                  this.stage = 1;
                  this.registerVisitor();
                }
            }else{
              this.showExpired= true;
            }
            this.doneLoading = true;
          }).catch((errors) => {
                  console.log(errors);
                });
      }
      },
      mounted() {
        this.hash = this.$route.params.hash;
        this.setLanguageIfAvailable();
        this.getVisitorSettings();
        if(this.$route.params.hash){
          this.getOrgId();
        }else{
          this.auth();
        }
      }
    }
</script>
