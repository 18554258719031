<template>
  <div>
    <Header v-if="member.name.length>0" :useruid="this.$route.params.useruid" :title="$t('memberphoto.manage_profile')" :showDate="false"></Header>
    <div id="memberinfo-main-view-wrapper">
      <div ></div>
      <div id="memberinfo-main-view">
        <div v-if="memberReturned && member && member.name" id="memberinfo-descriptor-header">
          <div class="fit-width grid-fit-2column auto-margin">
            <span class="form-box-name memberinfo-descriptor">{{member.isContact==0? $t('memberphoto.member_name') : $t('memberphoto.contact_name')}}:</span>
            <b>{{member.name}}</b>
            <span v-if="member.category" class="form-box-name memberinfo-descriptor">{{member.isContact==0? $t('memberphoto.member_category') : $t('memberphoto.contact_role')}}:</span>
            <b v-if="member.category">{{member.isContact==0? member.category : (member.contact_types? member.contact_types.join(", ") : "")}}</b>
          </div>
        </div>
        <div v-if="memberReturned && needPin">
          <div id="verification-sent" v-html="$t('memberphoto.verification_sent', {email: member.verification_email})"></div>
          <div id="pin-submit" class="fit-width grid-fit-2column">
            <b-form-input v-model="pin" class="medium-width-input"></b-form-input>
            <b-button class="action-button" variant="blue" v-on:click="getMemberPhoto(false)"><b>{{$t('global.button_submit')}}</b></b-button>
          </div>
          <b-alert variant="danger" class="fit-width auto-margin" dismissible :show="showVerificationAlert" @dismissed="showVerificationAlert=false">
                {{verificationAlertText}}
          </b-alert>
        </div>
        <form v-if="memberReturned && !needPin" v-on:submit.prevent="updateMember">
            <div id="memberinfo-instructions">{{member.isContact==0? $t('memberphoto.instructions1') : $t('memberphoto.instructions1c')}}</div>
            <div id="memberinfo-wrapper">
              <div>
              <span class="form-box-title">{{$t('member.contact_info')}}</span>
              <div id="memberinfo-form-box" class="form-box">
                <span v-if="use_taglines && member.isContact==0" class="form-box-name">{{$t('memberphoto.tag_line')}}</span>
                <div v-if="use_taglines && member.isContact==0" class="grid-auto-1column second-column">
                    <b-form-textarea id="request-box" v-model="member.tagline" :rows="2" :maxlength="tagline_chars"  />
                    <div class="page-note" :style="{'color': cc==tagline_chars? 'red' : ''}">{{tag_count}}</div>
                </div>
                <span class="form-box-name first-column">{{$t('member.email_text')}}</span><b-form-input v-model="member.email" type="email"  name="whateveremail" autocomplete="new-email"></b-form-input>
                <span class="form-box-name first-column">{{$t('member.phone_text')}}</span><b-form-input v-model="member.phone" class="medium-width-input"  type="tel"></b-form-input>
                <span class="form-box-name" v-if="isCorporateConnections">{{$t('member.mobile_phone')}}</span><b-form-input v-if="isCorporateConnections" v-model="member.mobile_phone" type="tel" class="medium-width-input"></b-form-input>
                <span class="form-box-name first-column">{{$t('member.category_text')}}</span><b-form-input v-model="member.category" type="text"></b-form-input>
                <span class="form-box-name first-column">{{$t('member.company_text')}}</span><b-form-input v-model="member.company" type="text"></b-form-input>
                <span class="form-box-name">{{$t('member.preferred_language')}}</span>
                <b-form-select v-model="member.pref_lang" :options="languageOptions" value-field="id" text-field="name"/>
                <!--<span class="form-box-name" v-if="isCorporateConnections">{{$t('member.preferred_contact')}}</span>
                <b-form-select v-if="isCorporateConnections" class="medium-width-input"  v-model="member.preferred_contact" :options="contactMethods"/>-->
                <span class="form-box-name first-column">{{$t('member.website_text')}}</span><b-form-input v-model="member.website" type="text" ></b-form-input>
                <span v-if="isCorporateConnections" class="form-box-name  first-column">{{$t('member.member_introduction')}}</span>
                <b-form-textarea v-if="isCorporateConnections" v-model="member.introduction" :rows="2" maxlength="255"/>
              </div>
              </div>
              <div>
                <span class="form-box-title">{{$t('memberphoto.email_subscriptions')}}</span>
                <div id="subscriptions-form-box" class="form-box">
                  <div v-for="row in rows" :key="row.service" class="grid-fitfirst-2column small-grid-gap">
                    <span :class="{'dash-checkmark': row.subscribed,'dash-redx': !row.subscribed}"></span><span>{{$t('memberphoto.subscribe_email'+row.service)}}</span>
                  </div>
                  <b-button id="subscriptions-manage-button" class="action-button" v-on:click="showUnsubscribe=true;" variant="blue"><span>{{$t('memberphoto.button_manage')}}</span></b-button>
                </div>
              </div>
              <b-modal id="slide-modal" centered  v-model="showUnsubscribe" :title="$t('memberphoto.manage_subscription')">
                <div >
                  <div class="submit-box-2">
                  <b-button class="action-button" v-on:click="unsubscribed = unsubValue;recalculateSubscriptions();" variant="clear-blue"><div class="cancel-button"></div><span>{{$t('global.button_none')}}</span></b-button>
                  <b-button class="action-button" v-on:click="unsubscribed = 0;recalculateSubscriptions();" variant="clear-blue"><div class="save-button"></div><span>{{$t('global.button_all')}}</span></b-button>
                </div>
                <b-table id="subscription-table" :items="rows" :fields="fields" small >
                    <template v-slot:cell(service)="data">
                        {{$t('memberphoto.subscribe_email'+data.item.service)}}
                    </template>
                    <template v-slot:cell(subscribed)="data">
                        <b-form-checkbox v-model="data.item.subscribed" class="center-text">
                        </b-form-checkbox>
                    </template>
                </b-table>
              </div>
                <div slot="modal-footer" class="submit-box-2" >
                  <b-button class="action-button" v-on:click="showUnsubscribe=false;" variant="clear-blue"><div class="cancel-button"></div><span>{{$t('global.button_cancel')}}</span></b-button>
                  <b-button class="action-button" v-on:click="showUnsubscribe=false;setUnsubscribed();" variant="clear-blue"><div class="save-button"></div><span>{{$t('global.button_save')}}</span></b-button>
                </div>
          </b-modal>
          <b-modal id="modal-center" v-model="showDelete" centered :title="$t('member.delete_title')">
            <div>
              <p class="my-4">{{$t('memberphoto.delete_text')}}</p>
              <div id="delete-pin-box" class="fit-width">
                <b-form-input v-model="deletePin1" type="text" maxlength="1" class="center-text"></b-form-input>
                <b-form-input v-model="deletePin2" type="text" maxlength="1" class="center-text"></b-form-input>
                <b-form-input v-model="deletePin3" type="text" maxlength="1" class="center-text"></b-form-input>
                <b-form-input v-model="deletePin4" type="text" maxlength="1" class="center-text"></b-form-input>
              </div>
              <b-alert variant="danger" id="verification-alert" class="fit-width" dismissible :show="showDeleteVerificationAlert" @dismissed="showDeleteVerificationAlert=false">
                {{verificationAlertText}}
              </b-alert>
            </div>
            <div slot="modal-footer" class="submit-box-2">
              <b-button class="action-button" v-on:click="showDelete=false" :disabled="deleting" variant="clear-blue"><div class="cancel-button"></div><span>{{$t('global.button_cancel')}}</span></b-button>
              <b-button class="action-button" v-on:click="deleteMember" :disabled="deleting" variant="clear-blue"><div class="delete-button"></div><span>{{$t('global.button_confirm')}}</span></b-button>
            </div>
          </b-modal>
            </div>
            <div v-if="(member.applicant_photos==1 || member.isContact==1)" :style="{'display': 'grid', 'grid-template-columns': ($mq!=='mobile' && member.isContact == 0? '50% 50%' : '')}">
              <div class="memberinfo-photo-box">
                <span class="form-box-title">{{$t('memberphoto.photo_text')}}</span>
                <div class="form-box grid-auto-1column">
                  <ImageSelector class="memberinfo-photo-select" :imageObject="member" :ratio="isCorporateConnections? 1 : 0.6157" :maxWidth="665" :maxHeight="1080" :clearButton="true" :minText="true" :circular="true"/>
                </div>
              </div>
              <div v-if="member.isContact==0 && allowLogos" class="memberinfo-photo-box">
                <span class="form-box-title">{{$t('memberphoto.logo_text')}}</span>
                <div class="form-box grid-auto-1column">
                  <ImageSelector class="memberinfo-photo-select" :imageObject="member" :imageField="'logo'" :maxWidth="500" :maxHeight="500" :smallImageField="'logo'" :clearButton="true" :minText="true"/>
                </div>
              </div>
            </div>
            <div v-if="member.isContact==0 && member.official==1">
              <span class="form-box-title">{{$t('memberphoto.weekly_ask')}}</span>
                <div class="form-box grid-auto-1column">
                    <b-form-textarea id="request-box" v-model="member.ask" :rows="2" maxlength="120" :placeholder="$t('memberphoto.request_placeholder')" />
                    <div class="page-note" :style="{'color': cc==120? 'red' : ''}">{{char_count}}</div>
                    <div id="memberinfo-ask-note"><i>{{$t('memberphoto.ask_help')}}</i></div>
                </div>
            </div>
            <div>
              <b-alert variant="danger" class="fit-width auto-margin" dismissible :show="showRoleAlert" @dismissed="showRoleAlert=false">
                {{alertText}}
              </b-alert>
            </div>
            <div v-if="showUpload">
              <b-alert class="alert-line-height" variant="warning" :show="showUpload">
                {{$t('memberphoto.uploading_text')}}
                <b-spinner class="alert-spinner" label="Uploading" variant="warning"></b-spinner>
              </b-alert>
            </div>

            <div id="memberinfo-actions-box" class="fit-width">
              <b-button v-if="!showUpload" class="action-button" type="submit" variant="clear-blue"><div class="save-button"></div><b>{{$t('global.button_save')}}</b></b-button>
              <a id="member-file" :download="memberFile.filename" :href="memberFile.content" hidden></a>
              <b-button v-if="!showUpload" class="action-button" v-on:click="downloadMemberInfo" variant="clear-blue"><div class="import-button"></div><b id="download-info">{{$t('member.download_info')}}</b></b-button>
              <b-button v-if="!showUpload" class="action-button" v-on:click="requestDelete" variant="clear-blue"><div class="delete-button"></div><b>{{$t('global.button_delete')}}</b></b-button>
              <div id="memberinfo-save-note" class="page-note">{{$t('memberphoto.save_note')}}</div>
            </div>
          
        </form>
      </div>
      <div class="div-space"></div>
    </div>
    <div v-if="memberReturned && needPin" class="footer footer-text-colour center">
      © 2025 BNI Global, LLC <br/>
      {{$t('login.use_subject')}} <a v-on:click="goTerms()" class="clickable footer-link" target="_blank"><u>{{$t('login.terms_conditions')}}</u></a> {{$t('login.and_text')}} <a class="clickable footer-link" v-on:click="goPrivacy()" target="_blank"><u>{{$t('login.privacy_policy')}}</u></a>.
    </div>
    <Footer v-if="memberReturned && !needPin && member.isContact==0 && member.official==1" :useruid="this.$route.params.useruid"></Footer>
  </div>
</template>
<style scoped>
#memberinfo-descriptor-header{
  background-color: #eeeeee;padding: 10px; margin: 20px 0px;
}
#memberinfo-main-view{
  grid-template-columns: auto;max-width:900px;margin: auto;
}
#memberinfo-main-view-wrapper{
  top: 40px;position: relative;margin: auto;
}
.memberinfo-descriptor{
  margin: 0px 5px;
}
#subscription-table{
  max-width: 600px;margin: auto;
}
#verification-alert{
  margin:auto;margin-top: 10px;
}
.memberinfo-photo-box{
  padding: 0px 20px;
}
.small-grid-gap{
  grid-gap: 10px;
}
#subscriptions-manage-button{
  margin: auto;margin-top:10px;
}
#subscriptions-form-box{
  grid-template-columns: auto;height: calc(100% - 50px);grid-template-rows: repeat(6,max-content);
}
#memberinfo-form-box{
  grid-template-columns: max-content auto;height: calc(100% - 50px);grid-template-rows: repeat(4,max-content);
}
#memberinfo-wrapper{
  display:grid; grid-template-columns: 60% 40%;
}
#memberinfo-instructions{
  text-align:center;color: rgb(0, 174, 239);
}
#pin-submit{
  margin:10px auto;
}
#verification-sent{
  text-align: center;color:#00aeef;
}
#delete-pin-box{
  display:grid;grid-template-columns: repeat(4,40px);margin:auto;grid-gap:10px
}
#download-info{
  margin-left: 5px;
}
#memberinfo-save-note{
  grid-column: 1/4;margin-top: 10px;
}
#memberinfo-actions-box{
  margin:auto;margin-top:30px;display:grid;grid-template-columns: repeat(auto,3);
}
#memberinfo-ask-note{
  color:#999;width:100%;text-align:right;
}
.memberinfo-photo-select{
  margin-left: 10px;
}

.dash-checkmark{
    width: 14px !important;
    height: 12px !important;
    background-size: contain !important;
    background: url("../assets/checkmark-green-HOME.png") transparent no-repeat;
    margin: auto;
  }
  .dash-redx{
   width: 14px !important;
    height: 12px !important;
    background-size: contain !important;
    background: url("../assets/cross-red-HOME.png") transparent no-repeat;
    margin: auto;
  }
request-box::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color:#aaaaaa;
  opacity: 1; /* Firefox */
}

request-box:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: #aaaaaa;
}

request-box::-ms-input-placeholder { /* Microsoft Edge */
  color: #aaaaaa;
}
.page-note{
  
  color: #aaaaaa;
  font-size: 14px;
  text-align: center;
  
}
#set-photo-preview{
  height:300px; margin: auto; border: 1px solid gray;
}
#photoset-title{
  display: grid;grid-gap: 20px;margin: auto;
}
</style>
<script>
  import VueCropper from 'vue-cropperjs';
  import router from "../router"
    import 'cropperjs/dist/cropper.css';
import { DEFAULT_LOCALE_ID } from '../constants/appConstants';
    export default {
    name: "MemberPhoto",
    components: {
      VueCropper,
    },
      data() {
        return {
          deleting: false,
          hash: "",
          allowPhotos: false,
          allowLogos: false,
          deletePin1: "",
          deletePin2: "",
          deletePin3: "",
          deletePin4: "",
          showDelete: false,
          memberFile: {filename: "", content: ""},
          contactMethods: [{value:0, text: this.$t("member.sms_mobile")},{value:1, text: this.$t("member.email_link")},{value:2, text: this.$t("member.sms_email")}],
          showVerificationAlert: false,
          verificationAlertText: "",
          fields: [
            { key: 'service',
              label: this.$t('memberphoto.service_header'),
              sortable: true,
              thStyle: { "background-color":  process.env.VUE_APP_TABLE_HEADER,"color": "#ffffff"}
            },
             { key: 'subscribed',
              label: this.$t('memberphoto.subscribed_header'),
              sortable: false,
              thStyle: { "background-color": process.env.VUE_APP_TABLE_HEADER, "color": "#ffffff", "text-align": "center"},
            }
          ],
          member: {name: "", photo: null, logo: null,photo_small: null, ask: "", tagline: "", email: "", phone: "", mobile_phone: "", website: "", contact_types : [], introduction:"", pref_lang: null},
          showRoleAlert: false,
          alertText: "",
          succeeded: false,
          showUpload: false,
          showUnsubscribe: false,
          rows: [],
          unsubscribed: 0,
          unsubValue: 0,
          email_actions : [8,11,16,14,27],
          use_taglines: false,
          tagline_chars: 65,
          typeOptions: [],
          pin: "",
          needPin: false,
          memberReturned: false,
          showDeleteVerificationAlert: false,
          languageOptions: []
        }
      },
    computed: {
      cc: function () {
        var n = this.member.ask? this.member.ask.length : 0;
        return n;
      },
      char_count: function () {
        var n = this.member.ask? this.member.ask.length : 0;
        return (n < 120? n+"/120 "+this.$t('memberphoto.char_count_1') : this.$t('memberphoto.char_count_2'));
      },
      tag_count: function () {
        var n = this.member.tagline? this.member.tagline.length : 0;
        return (n < this.tagline_chars? n+"/"+this.tagline_chars+" "+this.$t('memberphoto.char_count_1') : this.$t('memberphoto.char_count_2'));
      }
    },
    methods: {
      goTerms(){
          if(this.isCorporateConnections){
           window.open("https://www.corporateconnections.com/terms-of-service", "_blank");
          }else{
            window.open("https://bnitos.com", "_blank");
          }
       },
       goPrivacy(){
         if(this.isCorporateConnections){
           window.open("https://www.corporateconnections.com/privacy-policy", "_blank");
         }else{
           window.open("https://bnitos.com/privacy", "_blank");
         }
       },
      downloadMemberInfo(){
        this.memberFile.filename = this.member.name+".json";
        var storageObj = {name: this.member.name, email: this.member.email, phone:this.member.phone,category: this.member.category, company: this.member.company, website: this.member.website};
        if(this.isCorporateConnections){
          storageObj['mobile_phone']= this.member.mobile_phone;
        }
        storageObj['photo'] = this.member.photo;
        storageObj['logo'] = this.member.logo;

        this.memberFile.content = "data:text/json;charset=utf-8," + encodeURIComponent(JSON.stringify(storageObj));
        this.$nextTick(function(){
          document.getElementById("member-file").click();
        });
      },
      getContactTypes(){
        this.$http.post("/api/contact/types/get", {}).then((response) => {
          this.typeOptions = response.data.types;

          var ids = this.member.contact_type.split(",");
            this.member.contact_types = [];
            for(var i=0; i<this.typeOptions.length;i++){
              if(ids.indexOf(this.typeOptions[i].id+"")>=0){
                this.member.contact_types.push(this.typeOptions[i].name);
              }
            }
        }).catch(() => {
        });
      },
      getUseTaglines(){
          this.$http.post("/api/region/get/usetaglines/byhash", {hash: this.hash }).then((response) => {
            if (response.data.success === true) {
              this.use_taglines = response.data.use_taglines;
              this.tagline_chars = response.data.tagline_chars;
            }
          }).catch(() => {});
        },
      getMemberPhoto(initial) {
          if (this.hash.length > 0 && this.pin.length>0) {
            this.pin = this.pin? this.pin.trim() : "";
            this.$http.post("/api/member/get/info/byhash", { hash: this.hash, pin: this.pin }).then((response) => {
              if (response.data.success) {
                this.memberReturned = true;
                this.needPin = false;
                this.pin = response.data.member.pin;
                this.unsubscribed = response.data.member.unsubscribed;
                this.recalculateSubscriptions();
                response.data.member.contact_types = [];
                this.member = { ...response.data.member, pref_lang: response.data.member.pref_lang || this.member.pref_lang };
                if(this.member.isContact==1){
                  this.getContactTypes();
                }
              } else if(response.data.wrong_pin){
                if(this.pin.length>0){
                  this.pin = "";
                  this.needPin = true;
                  
                  if(!initial){
                    this.showVerificationAlert = true;
                    this.verificationAlertText = this.$t('memberphoto.wrong_pin');
                  }else{
                    this.memberReturned = true; 
                  //   this.$http.post("/api/member/get/pin/info", {hash: this.hash }).then((response) => {
                  //   if (response.data.success === true) {
                  //     this.member = response.data.member;
                  //   }
                  // }).catch(() => {});
                  }
                }else{
                  this.pin = "";
                  this.showVerificationAlert = true;
                  this.verificationAlertText = this.$t('memberphoto.wrong_pin');
                }
              } else if(response.data.no_tries){
                this.pin = "";
                this.needPin = true;
                if(!initial){
                  this.showVerificationAlert = true;
                  this.verificationAlertText = this.$t('memberphoto.no_tries');
                }
                  this.$http.post("/api/member/get/pin/info", {hash: this.hash }).then((response) => {
                  if (response.data.success === true) {
                    this.member = response.data.member;
                    this.memberReturned = true; 
                  }
                }).catch(() => {});
              } else {
                this.succeeded = true;
                this.showRoleAlert = true;
                this.alertText = this.$t('memberphoto.link_expired');
              }
            }).catch(() => {
              if(this.pin.length==0){
                this.needPin = true;
                this.memberReturned = true; 
                this.$http.post("/api/member/get/pin/info", {hash: this.hash }).then((response) => {
                  if (response.data.success === true) {
                    this.member = response.data.member;
                    
                  }
                }).catch(() => {});
              }
            });
          }
      },
      requestDelete(){
        this.deletePin1 = "";
        this.deletePin2 = "";
        this.deletePin3 = "";
        this.deletePin4 = "";
        this.showDeleteVerificationAlert = false;
        this.showDelete = true;
        this.member.hash = this.hash;
        this.member.pin = this.pin;
        this.$http.post("/api/member/delete/request/byhash", this.member).then((response) => {
            if (response.data.success) {
              this.$bvToast.toast(this.$t('memberphoto.delete_pin_sent')+response.data.member.email, {
                variant: 'success',
                solid: true
              });
            }
          }).catch(() => { this.showUpload = false; this.$bvToast.show('serverError');});
      },
      deleteMember(){
        this.showDeleteVerificationAlert = false;
        this.member.hash = this.hash;
        this.member.pin = this.pin;
        this.deleting = true;
        this.member.delete_pin = this.deletePin1+this.deletePin2+this.deletePin3+this.deletePin4;
        this.$http.post("/api/member/delete/byhash", this.member).then((response) => {
          this.deleting = false;
            if (response.data.success) {
              this.showDelete = false;
              router.push("/login");
            }else{
              this.verificationAlertText = this.$t('memberphoto.wrong_pin');
              this.showDeleteVerificationAlert = true;
            }
          }).catch(() => { this.deleting = false;this.$bvToast.show('serverError');});
      },
      updateMember() {
        this.showUpload = true;
        if (this.hash.length > 0) {
          this.member.hash = this.hash;
          this.member.tagline = this.member.tagline? this.member.tagline.substring(0,this.tagline_chars) : "";
          this.member.pin = this.pin;
          this.$http.post("/api/member/update/photo/byhash", this.member).then((response) => {
            this.showUpload = false;
            if(response.data.error){
              if(response.data.error.errno == 1062){
                  this.showRoleAlert = true;
                  this.alertText = this.$t('memberphoto.email_error');
                }
            }else if (response.data.success) {
              this.succeeded = true;
              this.$bvToast.toast(this.$t('memberphoto.photo_updated'), {
                title: this.$t('memberphoto.title_text'),
                variant: 'success',
                solid: true
              });
            }
          }).catch(() => { this.showUpload = false; this.$bvToast.show('serverError');});
        }
      },
      recalculateSubscriptions(){
        this.rows = [];
        this.unsubValue = 0;
        for(var i=0; i<this.email_actions.length; i++){
            this.unsubValue+=Math.pow(2,this.email_actions[i]);
            this.rows.push({service: this.email_actions[i], subscribed: (Math.pow(2,this.email_actions[i]) & this.unsubscribed)==0? true : false});
        }
      },
      setUnsubscribed() {
          this.showSuccess = false;
          this.unsubscribed = 0;
          for(var i=0; i<this.rows.length; i++){
              if(!this.rows[i].subscribed){
                  this.unsubscribed+=Math.pow(2,this.rows[i].service);
              }
          }
          this.$http.post("/api/member/set/unsubscribed/byhash", { hash: this.hash, unsubscribed: this.unsubscribed, pin: this.pin }).then((response) => {
            if (response.data.success) {
                this.showSuccess = true;
            }
            
          }).catch(() => {});
      },
      getAllowPhotos() {
          this.$http.post("/api/organization/get/allowphotos/byhash", {hash: this.hash}).then((response) => {
            if (response.data.success) {
              this.allowPhotos = response.data.allowPhotos;
              this.allowLogos = response.data.allowLogos;
            }
          }).catch(() => {});
        },
      getLanguages(){
          this.$http.post("/api/organization/languages/byhash", {hash: this.hash, isMember: true}).then((response) => {
            if (response.data.success === true) {
                this.languageOptions = response.data.languages;
            }
            this.$http.post("/api/organization/get/language/byhash", {hash: this.hash}).then((response) => {
              if (response.data.success === true) {
                const default_lang_idx = this.languageOptions.findIndex(function(language){
                  return language.id == response.data.default_lang;
                });
                if (default_lang_idx > -1) {
                  const langOption = this.languageOptions[default_lang_idx];
                  this.languageOptions.splice(default_lang_idx, 1);
                  langOption.name = this.$t('member.default_language')+" ("+langOption.name+")";
                  this.languageOptions.unshift(langOption);
                }
                this.member.pref_lang = this.member.pref_lang || response.data.default_lang || DEFAULT_LOCALE_ID;
              }
            }).catch((errors) => {
              console.log(errors)
            });
          }).catch((errors) => {
          console.log(errors)
          });
      },
    },
    mounted() {
      this.hash = this.$route.params.useruid;
      if(this.$route.params.pin){
        this.pin = this.$route.params.pin;
      }else{
        this.pin = "-1";
      }
      
      document.title = this.$t('memberphoto.member_photo');
      this.getLanguages();
      this.getUseTaglines();
      this.getMemberPhoto(true);
      this.getAllowPhotos();
    }
    }
</script>
