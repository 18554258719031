<template>
    <Datepicker :auto-apply="true" :disabled="disabled" :disabled-dates="disabledDates" :format="$formatDate" :locale="$i18n.locale" :hide-navigation="hideNavigation" :state="state" :model-value="modelValue" @update:model-value="updateDate" />
</template>

<script>
import Datepicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';

export default {
    name: 'DatePicker',
    components: {
        Datepicker,
    },
    model: {
        prop: 'modelValue',
        event: 'update:modelValue'
    },
    props: {
        disabled: {
            type: Boolean,
            default: false
        },
        disabledDates: {
            type: Object
        },
        hideNavigation: {
            type: Array,
            default: () => ['time']
        },
        modelValue: {
            type: Date
        },
        state: {
            type: [Boolean, null],
            default: null
        }
    },
    data: () => ({
        date: new Date()
    }),
    methods: {
        updateDate(newDate) {
            this.$emit('update:modelValue', newDate);
        }
    },
};
</script>