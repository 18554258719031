<template>
  <div id="dash">
    <Header :byId="true" :showDate="false" xlogout="true"></Header>
    <div id="meeting-box">
       <div id="meeting-description" class="fit-width">
          <div id="organization-name">{{permissions.organization.name}}</div>
          <div id="date" ><b>{{$formatDate(new Date())}}</b></div>
        </div>
      <div >
          <br/>
          <div id="assigned-chapters">{{$t('dashboard.chapters_assigned')}}</div>
          <br/>
        <b-table id="chapters-table" class="table-hover-new" small bordered :items="organizations" :fields="fields">
              <template v-slot:cell(name)="data">
                {{data.item.name}}
                <img v-if="data.item.cron_speakers==1" class="notification-icon" v-b-popover.hover.top="$t('organizations.debug_tooltip')"  src="../assets/icon-NoNotification.png" />
              </template>
            <template v-slot:cell(actions)="data">
                  <b-button id="chapter-login-button" size="sm" :disabled="data.item.disabled==1" @click.stop="loginAsChapter(data.item)" variant="light-blue-outline">
                {{$t('organizations.login_as')}}
                </b-button>
             </template>
      </b-table>
      </div>
    </div>
  </div>
</template>
<style scoped>
.chapter-login-button{
  position:relative;font-size:16px;
}
.notification-icon{
  height: 16px;margin: 7px 5px;
}
#chapters-table{
  max-width:1000px; margin: auto;
}
#assigned-chapters{
  font-size:26px; text-align:center; margin:auto;
}
#date{
  color:#333;font-size:16px;
}
#organization-name{
  color:#ff0000;margin: 0 50px;font-size:26px;
}
#dash {
    height: calc(100% - 50px);
    position: relative;
    bottom: 0px;
    top: 0px;
    right: 0px;
    left: 0px;
    display: block;
  }
  #meeting-box {
    height: 100%;
  }
  #meeting-description {
    background-color: #fafafa;
    min-width: 200px;
    grid-template-columns: 1fr;
    text-align: center;
    grid-template-rows: repeat(4, max-content);
    height: fit-content;
    height: -moz-fit-content;
    height: -webkit-fit-content;
    margin-top: 5%;
    border: 1px #f2f2f2 solid;
    border-radius: 2px;
    max-width: 1000px;margin: 30px auto; padding: 10px 75px;margin-bottom: 15px;
  }
</style>
  <script>
  import router from "../router"
export default {
  name: "RegionDash",
  watch: {
    '$i18n.locale': { 
      handler: function () {
        this.fields[0].label = this.$t('organizations.chapter_header');
        this.fields[1].label = this.$t('organizations.next_meeting');
        this.fields[2].label = this.$t('organizations.lastpresent_header');
        this.fields[3].label = this.$t('organizations.content_header');
      }
    }
  },
  data() {
    return {
    organizations: [],
      fields: [
            { key: 'name',
              label: this.$t('organizations.chapter_header'),
              sortable: true,
              thStyle: { "background-color":  process.env.VUE_APP_TABLE_HEADER,"color": "#ffffff"}
            },
             
             { key: 'next_meeting',
              label: this.$t('organizations.next_meeting'),
              sortable: true,
              thStyle: { "background-color":  process.env.VUE_APP_TABLE_HEADER,"color": "#ffffff" }
            },
             { key: 'last_present',
              label: this.$t('organizations.lastpresent_header'),
              sortable: true,
              thStyle: { "background-color":  process.env.VUE_APP_TABLE_HEADER,"color": "#ffffff" }
            },
            { key: 'actions',
              label: this.$t('organizations.content_header'),
              sortable: false,
              thStyle: { "background-color": process.env.VUE_APP_TABLE_HEADER, "color": "#ffffff", "width": "250px", "text-align": "center" },
              tdClass: "actions-centre"
            }
          ],
    };
  },
  methods: {
    loginAsChapter(org){
        this.$http.post("/api/login/contact/tochild", {id: org.id}).then((response) => {
            if(response.data.success){
                this.permissions.access = response.data.permissions.access;
                this.permissions.admin = response.data.permissions.admin;
                this.permissions.access = response.data.permissions.access;
                this.permissions.access = response.data.permissions.access;
                this.permissions.access = response.data.permissions.access;
                this.permissions.organization = response.data.permissions.organization;
                this.permissions.user = response.data.permissions.user;
                document.title = this.$t('global.document_title_admin') +" "+ this.permissions.organization.name;
                router.push("/admin/dashboard");
                this.$root.$emit('userChange', "");
            }
        }).catch((errors) => {console.log(errors);});
    },
    getOrganizations() {
        this.$http.post("/api/organizations/get/contact", {}).then((response) => {
            for(var i=0; i<response.data.organizations.length;i++){
            var d = response.data.organizations[i];
            if((d.meet_day_of_week+1)>0 && d.meet_start != null){
              d.next_meeting = this.$t('general.day_option_'+(d.meet_day_of_week+1))+" @ "+d.meet_start;
            }
          }
            this.organizations = response.data.organizations;
        }).catch(() => {
        });
    },
    },
    destroyed() {
        this.removeClass(document.getElementById("menu-home"), "active-menu");
    },
    mounted() {
        this.genericAuthenticate("/api/auth/userdash","/login");
        this.addClass(document.getElementById("menu-home"), "active-menu");
        this.getOrganizations();
    }
    };
</script>
