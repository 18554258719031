<template>
  <div>
    <div class="rel-grid-container" :style="{'max-width': chapter_id>0? '100%': '75%'}">
      <div ></div>
      <div id="dates-wrapper">
        <b-form-checkbox v-on:change="previousDatesChange" id="previous-dates" v-model="permissions.previousDates">
            {{$t('dates.previous_dates')}}
          </b-form-checkbox>
        <b-table  class="table-hover-new" small bordered :items="dates" :fields="fields" v-on:row-clicked="editDate" :tbody-tr-class="rowClass">
          <template v-slot:cell(delete)="data" >
               <b-form-checkbox v-if="data.item.attendance==0" class="fit-width auto-margin" v-model="data.item.delete">
              </b-form-checkbox>
           </template>
           <template v-slot:cell(type)="data" >
               {{$t('dates.date_type'+data.item.type)}}
           </template>
           <template v-slot:cell(meeting_mode)="data" >
               {{data.item.meeting_mode==0?  $t('general.hybrid_text') : (data.item.meeting_mode==1? $t('general.online_text') : $t('general.inperson_text'))}}
           </template>
           <template v-slot:cell(note)="data" >
               <span v-html="data.item.note"></span>
           </template>
           <template v-slot:cell(day)="data" >
               {{$formatDate(new Date(data.item.day.split("-").join("/") + " 00:01"))}}
           </template>
           <template v-slot:cell(speakers)="data" >
            <div class="date-speakers">
               <div v-for="speaker in data.item.speakers" :key="speaker.name" >
                  <div>{{speaker.name}}</div>
                  <div>{{speaker.company}}</div>
               </div>
               </div>
           </template>
          </b-table>
      </div>
     <b-alert variant="success" dismissible :show="showSuccess"  @dismissed="showSuccess=false">
        {{$t('attendance.export_success')}}
        </b-alert>
        <b-alert variant="warning" dismissible :show="showExportAlert"  @dismissed="showExportAlert=false">
        {{$t('attendance.export_failure')}}
        </b-alert>
      <div class="submit-box-2">
        <b-button v-if="dates.filter(v=>v.delete).length>0" class="action-button" v-on:click="showDatesDelete=true;" variant="clear-blue"><div class="delete-button"></div><span>{{$t('global.button_delete')}}</span></b-button>
        <b-button v-if="permissions.access" class="action-button" v-on:click="newDate" variant="clear-blue"><div class="new-button"></div><span>{{$t('global.button_new')}}</span></b-button>
        <div class="fit-width grid-fit-2column auto-margin">
            <b-button class="action-button auto-margin" v-if="!showSuccess" v-on:click="getScheduleExport" variant="clear-blue"><div class="import-button"></div><span>{{$t('global.button_export')}}</span></b-button>
            <div v-if="exportFile.filename.length>0 && showSuccess" class="fit-width auto-margin"> 
                <b-button class="action-button" v-on:click="downloadScheduleExport" variant="clear-blue" ><div class="import-button"></div><span>{{$t('attendance.download_export')}}</span></b-button>
                <a id="export-file" :download="exportFile.filename" :href="exportFile.content" hidden></a>
            </div>
        </div>
      </div>
      <div class="div-space"></div>
    </div>
    <b-modal id="slide-modal" :no-close-on-backdrop="true" :no-close-on-esc="true" :hide-header-close="true"  centered :title="$t(dateEdit.id>0? 'dates.edit_date' : 'dates.new_date')" v-model="showDateEdit">
        <div>
            <span class="form-box-title">{{$t('dates.meeting_date_and_time')}}</span>
            <div class="form-box" id="meeting-date-form-box">
                <span v-if="isCorporateConnections" class="form-box-name">{{$t('dates.type_text')}}</span>
                <b-form-select v-if="isCorporateConnections" v-model="dateEdit.type" :options="typeOptions" class="mb-3 form-box-input" required />
                <span v-if="isCorporateConnections"  class="form-box-name">{{$t('general.meeting_mode')}}</span>
                <b-form-radio-group v-if="isCorporateConnections" class="form-box-input" id="meeting-modes" v-model="dateEdit.meeting_mode" :options="meetingModes">
                </b-form-radio-group>
                <span class="form-box-name">{{$t('announcement.date_text')}}</span>
                <DatePicker class="form-box-input input-vmargins" :disabled-dates="dateDisabled" :state="dateEdit.day != null" v-model="dateEdit.day" />
                <span class="form-box-name">{{$t('general.meeting_start_networking')}}</span>
                <b-form-timepicker :hour12="!!permissions.organization.time_format_12_hr" minutes-step="5" :label-no-time-selected="$t('global.no_time')"  class="form-box-input time-picker" :state="dateEdit.meet_pre_start != null" v-model="dateEdit.meet_pre_start" :locale="$i18n.locale" required></b-form-timepicker>
                <span class="form-box-name">{{$t('general.meeting_start_time')}}</span>
                <b-form-timepicker :hour12="!!permissions.organization.time_format_12_hr" minutes-step="5" :label-no-time-selected="$t('global.no_time')" class="form-box-input time-picker" @input="setEndTime" :state="dateEdit.meet_start != null" v-model="dateEdit.meet_start" :locale="$i18n.locale" required></b-form-timepicker>
                <span class="form-box-name">{{$t('general.end_time')}}</span>
                <b-form-timepicker :hour12="!!permissions.organization.time_format_12_hr" minutes-step="5" :label-no-time-selected="$t('global.no_time')" class="form-box-input time-picker" :state="dateEdit.meet_end != null"  v-model="dateEdit.meet_end" :locale="$i18n.locale"></b-form-timepicker>
                <span v-if="!isCorporateConnections || (dateEdit.meeting_mode!=2)" class="form-box-name">{{$t('dates.meeting_link')}}</span>
                <b-form-input v-if="!isCorporateConnections || (dateEdit.meeting_mode!=2)" v-model="dateEdit.meeting_link" type="text" pattern="[^<>]*" :title="$t('global.pattern_title')" max="255" class="form-box-input"></b-form-input>
                <span v-if="!isCorporateConnections || (dateEdit.meeting_mode!=1)" class="form-box-name">{{$t('dates.location_text')}}</span>
                <b-form-textarea v-if="!isCorporateConnections || (dateEdit.meeting_mode!=1)" v-model="dateEdit.address" :rows="3" id="meeting-location" class="form-box-input" :placeholder="$t('dates.location_placeholder')"/>
                <span v-if="!isCorporateConnections || (dateEdit.meeting_mode!=1)" id="address-note">*{{$t('dates.address_note')}}</span>
                <span class="form-box-name">{{$t('dates.note_text')}}</span>
                <BaseEditor class="form-box-input" v-model="dateEdit.note" :smallFormat="true" />
            </div>
            <div v-if="event_chapter" >
              <span class="form-box-title">{{$t('dates.event_info')}}</span>
              <div class="form-box" id="image-form-box">
                <span class="form-box-name">{{$t('dates.title_text')}}</span>
                <b-form-input v-model="dateEdit.title" type="text" pattern="[^<>]*" :title="$t('global.pattern_title')" max="255" class="form-box-input"></b-form-input>
                  <ImageSelector class="meeting-image-selector" ref="imageSelector" :imageObject="dateEdit" :circular="false" :clearButton="true"/>
              </div>
            </div>
             <b-alert variant="danger" dismissible :show="showAlert" @dismissed="showAlert=false" class="fit-width auto-margin">
              {{alertText}}
              </b-alert>
            </div>
        <div slot="modal-footer" class="submit-box-2">
            <b-button class="action-button" v-on:click="showDateEdit=false" variant="clear-blue"><div class="cancel-button"></div><span>{{$t('global.button_cancel')}}</span></b-button>
            <b-button class="action-button" v-on:click="updateDate" variant="clear-blue"><div class="save-button"></div><span>{{$t('global.button_save')}}</span></b-button>
        </div>
    </b-modal>
    <b-modal id="slide-modal" :no-close-on-backdrop="true" :no-close-on-esc="true" :hide-header-close="true"  centered :title="$t('dates.new_date')" v-model="showDateNew">
        <div>
          <div v-if="isCorporateConnections && permissions.organization.is_parent && chapter_id==0" class="grid-auto-2column">
            <span  class="form-box-name">{{$t('dates.chapter_text')}}</span>
            <b-form-select v-model="dateEdit.org" :options="chapterOptions" class="mb-3 form-box-input" value-field="id" text-field="name" required @change="orgChange"/>
          </div>
          <div v-if="!permissions.organization.is_parent || dateEdit.org>0 || chapter_id>0">
          <span class="form-box-title">{{$t('dates.meeting_dates')}}</span>
            <div class="form-box" id="dates-form-box">
              <div v-for="day in dateEdit.days" :key="day" class="grid-fit-2column">
                <DatePicker class="input-vmargins" :disabled-dates="dateDisabled" :state="day.day != null" v-model="day.day" />
                  <b-button v-if="dateEdit.days.length>1" class="action-button valign-margin" v-on:click="removeDate(day)" variant="clear-blue"><div class="close-button"></div></b-button>
                </div>
                <b-button class="action-button" v-on:click="addDay" variant="clear-blue"><div class="plus-button"></div></b-button>
            </div>
            <span class="form-box-title">{{$t('general.meeting_time')}}</span>
            <div id="meeting-time-form-box" class="form-box">
               <span v-if="isCorporateConnections" class="form-box-name">{{$t('dates.type_text')}}</span>
                <b-form-select v-if="isCorporateConnections" v-model="dateEdit.type" :options="typeOptions" class="mb-3 form-box-input" required />
                <span v-if="isCorporateConnections"  class="form-box-name">{{$t('general.meeting_mode')}}</span>
                <b-form-radio-group v-if="isCorporateConnections" class="form-box-input valign-margin left-text" v-model="dateEdit.meeting_mode" :options="meetingModes">
                </b-form-radio-group>
                <span class="form-box-name">{{$t('general.meeting_start_networking')}}</span>
                <b-form-timepicker :hour12="!!permissions.organization.time_format_12_hr" minutes-step="5" :label-no-time-selected="$t('global.no_time')"  class="form-box-input time-picker" :state="dateEdit.meet_pre_start != null" v-model="dateEdit.meet_pre_start" :locale="$i18n.locale" required></b-form-timepicker>
                <span class="form-box-name">{{$t('general.meeting_start_time')}}</span>
                <b-form-timepicker :hour12="!!permissions.organization.time_format_12_hr" minutes-step="5" :label-no-time-selected="$t('global.no_time')" class="form-box-input time-picker" @input="setEndTime" :state="dateEdit.meet_start != null" v-model="dateEdit.meet_start" :locale="$i18n.locale" required></b-form-timepicker>
                <span class="form-box-name">{{$t('general.end_time')}}</span>
                <b-form-timepicker :hour12="!!permissions.organization.time_format_12_hr" minutes-step="5" :label-no-time-selected="$t('global.no_time')" class="form-box-input time-picker" :state="dateEdit.meet_end != null"  v-model="dateEdit.meet_end" :locale="$i18n.locale"></b-form-timepicker>
                <span class="form-box-name">{{$t('dates.meeting_link')}}</span>
                <b-form-input v-model="dateEdit.meeting_link" type="text" pattern="[^<>]*" :title="$t('global.pattern_title')" max="255" class="form-box-input"></b-form-input>
                <span class="form-box-name">{{$t('dates.location_text')}}</span>
                <b-form-textarea v-model="dateEdit.address" :rows="3" id="address-textarea" class="form-box-input" :placeholder="$t('dates.location_placeholder')"/>
                <span id="address-note">*{{$t('dates.address_note')}}</span>
                <span class="form-box-name">{{$t('dates.note_text')}}</span>
                <BaseEditor class="form-box-input" v-model="dateEdit.note" :smallFormat="true" />
            </div>

            <div v-if="event_chapter" >
              <span class="form-box-title">{{$t('dates.event_info')}}</span>
              <div id="meeting-event-form-box" class="form-box">
                <span class="form-box-name">{{$t('dates.title_text')}}</span>
                <b-form-input v-model="dateEdit.title" type="text" pattern="[^<>]*" :title="$t('global.pattern_title')" max="255" class="form-box-input"></b-form-input>
                  <ImageSelector class="meeting-image-selector" ref="imageSelector" :imageObject="dateEdit" :circular="false" :clearButton="true"/>
              </div>
            </div>
             <b-alert variant="danger" dismissible :show="showAlert" @dismissed="showAlert=false" class="fit-width auto-margin">
              {{alertText}}
              </b-alert>
            </div>
            </div>
        <div slot="modal-footer" class="submit-box-2">
            <b-button class="action-button" v-on:click="showDateNew=false" variant="clear-blue"><div class="cancel-button"></div><span>{{$t('global.button_cancel')}}</span></b-button>
            <b-button class="action-button" v-show="!permissions.organization.is_parent || dateEdit.org>0 || chapter_id>0" v-on:click="createDates" variant="clear-blue"><div class="save-button"></div><span>{{$t('global.button_save')}}</span></b-button>
        </div>
    </b-modal>
    <b-modal centered :title="$t('dates.delete_title')" v-model="showDatesDelete">
        <div>
          {{$t('dates.delete_text', {dates: dates.filter(v=>v.delete==1).length})}}
           <br/>
           <br/>
          <div v-if="dates.filter(v=>v.delete && v.speakers.length>0).length>0">{{$t('dates.delete_speakers')}}:</div>
          <div v-for="date in dates.filter(v=>v.delete && v.speakers.length>0)" :key="date.id">{{$formatDate(new Date(date.day.split("-").join("/") + " 00:01"))}}</div>
        </div>
        <div slot="modal-footer" class="submit-box-2">
            <b-button class="action-button" v-on:click="showDatesDelete=false" variant="clear-blue"><div class="cancel-button"></div><span>{{$t('global.button_cancel')}}</span></b-button>
            <b-button class="action-button" v-on:click="deleteDate" variant="clear-blue"><div class="save-button"></div><span>{{$t('global.button_confirm')}}</span></b-button>
        </div>
    </b-modal>
  </div>
</template>
<style scoped>
#meeting-event-form-box{
  grid-template-columns:auto;position:relative;
}
#address-note{
  grid-column:2/4;text-align:right;font-size:12px;
}
#address-textarea{
  min-width:300px;border-radius:15px;
}
#meeting-time-form-box{
  grid-template-columns: max-content auto auto;
}
#dates-form-box{
  grid-template-columns: repeat(1,max-content);
}
.meeting-image-selector{
  grid-column:1/4;
}
#image-form-box{
  grid-template-columns:auto;position:relative;
}
#address-note{
  grid-column:2/4;text-align:right;font-size:12px;
}
#meeting-location{
  min-width:300px;border-radius:15px;
}
#meeting-modes{
  margin: auto 0;text-align:left;
}
.date-speakers{
  display:grid;grid-gap:20px;
}
#previous-dates{
  float: right;
}
#dates-wrapper{
  margin: 50px auto;
}
#meeting-date-form-box{
  grid-template-columns:max-content auto auto;
}
 
</style>
<script>

import DatePicker from './common/DatePicker.vue';

    
    export default {
      name: "Dates",
      components: {
        DatePicker
      },
      data() {
        return {
          event_chapter: false,
          showExportAlert: false,
          showSuccess: false,
          exportFile: {filename: '', content: ''},
          chapterOptions: [],
          typeOptions: [{value: 0, text: this.$t('dates.date_type0')},{value: 1, text: this.$t('dates.date_type1')},{value: 2, text: this.$t('dates.date_type2')},{value: 3, text: this.$t('dates.date_type3')}],
          dates: [],
          fields: [
            {
              key: 'delete',
              label: this.$t('dates.select_header'),
              thStyle: { "background-color":  process.env.VUE_APP_TABLE_HEADER,"color": "#ffffff"}
            },
            {
              key: 'day',
              label: this.$t('dates.date_header'),
              thStyle: { "background-color":  process.env.VUE_APP_TABLE_HEADER,"color": "#ffffff"}
            },
            {
              key: 'meet_start_text',
              label: this.$t('dates.start_header'),
              thStyle: { "background-color":  process.env.VUE_APP_TABLE_HEADER,"color": "#ffffff"}
            }
          ],
          dateEdit: {org:0,id: 0, day: null, meet_pre_start: null, meet_start: null, meet_end: null, days: [], type: 0, note: '', text:'', update: '',meeting_mode: 1, meeting_link:"", address: "", title: "", photo: null},
          showDateEdit: false,
          showAlert: false,
          alertText: '',
          showDatesDelete: false,
          next_meeting: '',
          showDateNew: false,
          meeting_days: [],
          meetingModes: [{value: 1, text: this.$t('general.online_text')},{value: 2, text: this.$t('general.inperson_text')},{value: 0, text: this.$t('general.hybrid_text')}],
        }
      },
      watch: {
      '$i18n.locale': {
        handler: function () {
          this.getDates();
        }
      }
    },
    
      props:{
        chapter_id: {type: Number, default: 0}
      },
    methods: {
      setEndTime(new_value){
        if(new_value){
          var d = new Date("2020-01-01 "+new_value);
          d.setMinutes(d.getMinutes()+90);
          this.dateEdit.meet_end = d.getHours()+":"+d.getMinutes();
        }
        
      },
      orgChange(){
        this.meeting_days = this.dates.filter(v => v.org == this.dateEdit.org || v.org == 0).map(v => v.day);
      },
       dateDisabled(date) {
         
        if(this.meeting_days.length>0){
          return this.meeting_days.includes(date.toISOString().slice(0, 10));
        }
        return false;
      },
      addDay(){
        this.dateEdit.days.push({day: null});
      },
      removeDate(day){
        this.dateEdit.days = this.dateEdit.days.filter(v => v.day != day.day);
      },
      rowClass(item) {
        if(this.chapter_id==0 && this.permissions.organization.is_parent){
          return;
        }
        if (!item) return
        if (item.day == this.next_meeting) return 'blue-row'
      },
      
      previousDatesChange() {
        this.getDates();
      },
      getDates() {
        this.$http.post("/api/organization/startup/dates"+(this.chapter_id>0? "/aschild" : ""), {getprevious: this.permissions.previousDates, org: this.chapter_id}).then((response) => {
          if (response.data.success) {
            this.event_chapter = response.data.event_chapter==1;
            this.meeting_days = [];
            for(var i=0; i<response.data.dates.length; i++){
              response.data.dates[i].delete = false;
              this.meeting_days.push(response.data.dates[i].day);
            }
            this.dates = response.data.dates;
          }
        }).catch(() => {
        });
      },
      deleteDate(){
          this.$http.post("/api/organization/startup/dates/delete", {dates: this.dates.filter(v=>v.delete)}).then((response) => {
          if (response.data.success) {
            this.dates = this.dates.filter(v=>!v.delete);
            this.meeting_days = [];
            for(var i=0; i<this.dates.length; i++){
              thisdates[i].delete = false;
              this.meeting_days.push(this.dates[i].day);
            }
          }
          this.showDatesDelete = false;
        }).catch(() => {
            this.showDatesDelete = false;
        });
      },
      createDates(){
        this.showAlert = false;
        var isDate = false;
        //this.dateEdit.note = this.dateEdit.text;
        for(var i=0; i<this.dateEdit.days.length; i++){
          if(this.dateEdit.days[i].day != null){
            isDate = true;
          }
        }
          if(!isDate || this.dateEdit.meet_pre_start == null || this.dateEdit.meet_start == null || this.dateEdit.meet_end == null){
              this.showAlert = true;
              this.alertText = this.$t("global.complete_fields");
              return;
          }
          this.dateEdit.org= this.chapter_id>0? this.chapter_id: this.dateEdit.org;
          this.$http.post("/api/organization/startup/dates/create"+(this.dateEdit.org>0? "/aschild" : ""),  this.dateEdit).then((response) => {
            if (response.data.success) {
              this.getDates();
              this.showDateNew = false;
            }else{
                this.showAlert = true;
                this.alertText = this.$t("dates.date_in_use");
                return;
            }
          }).catch(() => {
              this.showDateNew = false;
          });
      },
      updateDate(){
          this.showAlert = false;
          //this.dateEdit.note = this.dateEdit.text;
          console.log(this.dateEdit.note)
          if(this.dateEdit.day == null || this.dateEdit.meet_pre_start == null || this.dateEdit.meet_start == null || this.dateEdit.meet_end == null){
              this.showAlert = true;
              this.alertText = this.$t("global.complete_fields");
              return;
          }
          this.dateEdit.org= this.chapter_id>0? this.chapter_id: this.dateEdit.org;
          this.$http.post("/api/organization/startup/dates/update"+(this.dateEdit.org>0? "/aschild" : ""),  this.dateEdit).then((response) => {
          if (response.data.success) {
            this.getDates();
            this.showDateEdit = false;
          }else{
              this.showAlert = true;
              this.alertText = this.$t("dates.date_in_use");
              return;
          }
        }).catch(() => {
             this.showDateEdit = false;
        });
      },
      getNextMeetingDate() {
        this.$http.post(this.chapter_id==0? "/api/organization/dates" : "/api/organization/dates/noauth", {org: this.chapter_id}).then((response) => {
          if (response.data.success === true) {
            this.next_meeting = response.data.next_formatted;
          }
        }).catch(() => {});
      },
      newDate() {
        this.showAlert = false;
        this.dateEdit = {org: 0,id: 0, type: 0, day: null, meet_pre_start: null, meet_start: null, meet_end: null, meeting_mode:1, meeting_link:"", days: [{day: null}], title: "", photo: null};
        if(this.dates.length>0){
          this.dateEdit.meet_pre_start = this.dates[0].meet_pre_start;
          this.dateEdit.meet_start = this.dates[0].meet_start;
          this.dateEdit.meet_end = this.dates[0].meet_end;
        }
        this.showDateNew = true;
      },
      editDate(item) {
        this.showAlert = false;
        this.dateEdit = Object.assign({}, item);
        //this.dateEdit.update = this.dateEdit.text = this.dateEdit.note;
         this.showDateEdit = true;
      },
      getChapters(){
        this.$http.post("/api/organization/get/children/select", {}).then((response) => {
          if (response.data.success) {
            this.chapterOptions = response.data.children;
            this.chapterOptions.unshift({id: 0, name: this.$t('region.select_chapter')});
          }
        }).catch(() => {
        });
      },
      getScheduleExport(){
        this.$http.post("/api/organization/startup/export"+(this.chapter_id>0? "/aschild" : ""), {getprevious: this.permissions.previousDates, org: this.chapter_id}).then((response) => {
          if (response.data.success === true) {
            this.exportFile = response.data.file;
            this.showSuccess = true;
          }else{
            this.showExportAlert = true;
          }
        }).catch(() => { });
      },
      downloadScheduleExport(){
        document.getElementById("export-file").click();
        this.showSuccess = false;
        this.showExportAlert = false;
      },
    },
    destroyed() {
      this.removeClass(document.getElementById("menu-dates"), "active-menu");
     this.removeClass(document.getElementById("menu-chapter"), "active-menu");
    },
    mounted() {
      if(this.$route.params.id){
        this.chapter_id = this.$route.params.id;
      }
      this.fields =  [
            {
              key: 'delete',
              label: this.$t('dates.select_header'),
              thStyle: { "background-color":  process.env.VUE_APP_TABLE_HEADER,"color": "#ffffff"}
            },
            {
              key: 'day',
              label: this.$t('dates.date_header'),
              thStyle: { "background-color":  process.env.VUE_APP_TABLE_HEADER,"color": "#ffffff"}
            },
            {
              key: 'meet_start_text',
              label: this.$t('dates.start_header'),
              thStyle: { "background-color":  process.env.VUE_APP_TABLE_HEADER,"color": "#ffffff"}
            }
          ];
      if(this.isCorporateConnections){
        this.fields = [
        {
          key: 'delete',
          label: this.$t('dates.select_header'),
          thStyle: { "background-color":  process.env.VUE_APP_TABLE_HEADER,"color": "#ffffff"}
        },
        {
          key: 'day',
          label: this.$t('dates.date_header'),
          thStyle: { "background-color":  process.env.VUE_APP_TABLE_HEADER,"color": "#ffffff"}
        },
        {
          key: 'type',
          label: this.$t('dates.type_text'),
          thStyle: { "background-color":  process.env.VUE_APP_TABLE_HEADER,"color": "#ffffff"}
        },
        {
          key: 'meeting_mode',
          label: this.$t('general.meeting_mode'),
          thStyle: { "background-color":  process.env.VUE_APP_TABLE_HEADER,"color": "#ffffff"}
        },
        {
          key: 'note',
          label: this.$t('dates.note_text'),
          thStyle: { "background-color":  process.env.VUE_APP_TABLE_HEADER,"color": "#ffffff"}
        },
        {
          key: 'meet_start_text',
          label: this.$t('dates.start_header'),
          thStyle: { "background-color":  process.env.VUE_APP_TABLE_HEADER,"color": "#ffffff"}
        },
        {
          key: 'speakers',
          label: this.$t('dates.speakers_header'),
          thStyle: { "background-color":  process.env.VUE_APP_TABLE_HEADER,"color": "#ffffff"}
        }
      ]
      
      }
      if(this.permissions.organization.is_parent){
          this.fields.unshift( {
            key: 'chapter_name',
            label: this.$t('dates.chapter_text'),
            thStyle: { "background-color":  process.env.VUE_APP_TABLE_HEADER,"color": "#ffffff"}
          });
      }
      this.addClass(document.getElementById("menu-dates"), "active-menu");
      this.addClass(document.getElementById("menu-chapter"), "active-menu");
      this.getNextMeetingDate();
      this.getDates();
      this.getChapters();
    }
    }
</script>
